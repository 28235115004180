import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  MagnifyingGlassIcon,
  XMarkIcon
} from '@heroicons/react/20/solid';

import { CustomCheckbox } from './CustomCheckbox';

export const Accordion = ({
  title,
  options = [],
  hasSearch = false,
  onChange,
  selectedItems,
  content = null,
  isCollapsedAccordion = true
}) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapsedAccordion);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedOptions, setSelectedOptions] = useState(new Set(selectedItems));

  useEffect(() => {
    setSelectedOptions(new Set(selectedItems));
  }, [selectedItems]);

  const filteredOptions = useMemo(() => {
    if (searchQuery) {
      const searchLowerNoSpaces = searchQuery.toLowerCase().replace(/\s+/g, '');
      return options.filter((option) =>
        option
          .toString()
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(searchLowerNoSpaces)
      );
    }
    return options;
  }, [searchQuery, options]);

  const handleCheckboxChange = (option, isChecked) => {
    const newSelectedOptions = new Set(selectedOptions);
    if (isChecked) {
      newSelectedOptions.add(option);
    } else {
      newSelectedOptions.delete(option);
    }
    setSelectedOptions(newSelectedOptions);
    onChange(option, isChecked);
  };

  const handleSearchChange = useCallback((evt) => {
    setSearchQuery(evt.target.value);
  }, []);

  const handleClearSearch = useCallback(() => {
    setSearchQuery('');
  }, []);

  return (
    <div className='text-white py-4 border-b border-accordion-border last:border-b-0'>
      <button className="flex justify-between items-center w-full text-left"
              onClick={() => setIsCollapsed(!isCollapsed)}
      >
        <span className="p6">
          {title}
        </span>
        {isCollapsed ?
          <ChevronDownIcon className="h-5 w-5" />
        :
          <ChevronUpIcon className="h-5 w-5" />
        }
      </button>
      {!isCollapsed &&
        <div className="mt-4"
             onClick={(evt) => {
               evt.stopPropagation();
               evt.nativeEvent.stopImmediatePropagation();
             }}
        >
          {hasSearch &&
            <div className="relative mb-5">
              <input className="w-full py-3 pl-10 pr-4 rounded-lg border-none outline-none bg-not-tonight-200 p7 peer text-child-of-light-1000 placeholder:text-not-tonight-500 focus:placeholder-transparent focus:text-white"
                     id={`${title}_accordion_search_input`}
                     type="search"
                     placeholder="Search"
                     name="search"
                     value={searchQuery}
                     onChange={(e) => handleSearchChange(e)}
                     autoComplete="off"
              />
              <MagnifyingGlassIcon className="absolute left-0 top-1/2 transform -translate-y-1/2 ml-3 h-5 w-5 text-not-tonight-500"
                                   aria-hidden="true"
              />
              {searchQuery &&
                <XMarkIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-not-tonight-500 cursor-pointer"
                           onClick={handleClearSearch}
                           aria-hidden="true"
                />
              }
            </div>
          }
            <div className={`flex flex-col gap-4 ${options.length > 11 ? 'relative max-h-96 overflow-y-auto' : ''}`}>
              {options.length && filteredOptions.length ?
                filteredOptions.map((option) =>
                  <div className="flex items-center"
                      key={option}
                      onClick={(evt) => { evt.stopPropagation(); }}
                  >
                    <CustomCheckbox id={`filter-${title}-${option}`}
                                    label={option}
                                    checked={selectedOptions.has(option)}
                                    onChange={(e) => handleCheckboxChange(option, e.target.checked)}
                    />
                  </div>
                )
              :
                options.length && !filteredOptions.length ?
                  <div className="text-not-tonight-500 p6">
                    We couldn't find any {title.toLowerCase()} that match your search.
                    Try adjusting your search filter.
                  </div>
                :
                  null
              }
              {content && !options.length &&
                <>{content}</>
              }
            </div>
        </div>
      }
    </div>
  );
};
