export const PMIModalContendData = {
  pokemonMarketIndex500: (
    <>
      <h3 className="p5 mb-2">The Selection and Computation Process for the PMI 500 index</h3>
      <p className="mb-2">a. Algorithmic Selection: Contrary to other indices in the collectible market, our card selection is purely algorithm-driven.</p>
      <p className="mb-2">b. Tenure on Market: A card must have been available in the market for a minimum of two years. For instance, even if a 2023 Pikachu promo card garners significant popularity, it will not be considered for inclusion in the index due to its potential high volatility.</p>
      <p className="mb-2">c. Market Capitalization Criterion: A card must possess a minimum market capitalization of $35k.</p>
      <p className="mb-2">d. Public Float Criterion: A card should have a graded population of at least 200, indicative of the number of possible cards in circulation.</p>
      <p className="mb-2">e. Liquidity Criterion:</p>
      <ul className="list-disc list-inside mb-2 ml-4">
        <li className='flex justify-between mb-1'>1. An English language card must demonstrate an average sales frequency of at least 0.15 sales per day (equivalent to 1 sale per week). For example, while the PSA 10 Base Set First Edition Charizard is arguably the most iconic card, it is excluded due to its low liquidity. However, the more liquid (PSA/CGC 8/7/6) version might be incorporated based on algorithmic selection.</li>
        <li className='flex justify-between'>2. Japanese cards require a frequency of 0.05 sales per day. Japanese cards have slightly lower liquidity requirements than English cards. It’s important to note that our sales data originates from US market platforms. While a substantial number of Japanese cards are favored in the Asian market, their lack in US sales can lead to exclusions based on liquidity prerequisites (For instance, the pre-2020 index). This is also notably evident among the high-end modern cards featuring Trainer Characters like Lillie and Marnie. However, as the appreciation of Japanese cards grows in the US, its total sales volume has increased significantly, contributing to a more accurate Japanese card market index.</li>
      </ul>
      <p className="mb-2">f. Priority Selection: If more than 500 cards match the criteria, we will select the top 500 cards based on the liquidity measurements.</p>
      <p className="mb-2">g. Selection of Card Grade: Upon the selection of a card, the grade with the highest liquidity will be chosen to represent the card. For example, in the case of the Unlimited Base Set Charizard card, the PSA 8 grade is identified as the most liquid. Once the PSA 8 grade is selected, other grades will not be considered for selection. Every card within the index will be exclusively represented by its most liquid grade, which also needs to fulfill the Liquidity Criterion(s) outlined above.</p>
      <p className="mb-2">h. Data Utilization: This index uses PSA cards sales data. We also introduced an identical index that uses CGC card prices (CGC Pokémon Market Index 500).</p>
      <h3 className="p6 mb-2">Additional Notes:</h3>
      <p className="mb-2">In alignment with the principles of the S&P 500, the PMI 500 index is inherently dynamic, with a periodic inclusion and exclusion of cards based on specific criteria. This periodic adjustment is referred to as the “rebalancing process.” Within this process, cards gaining prominence are incorporated, while those diminishing in popularity are phased out. The PMI 500 index undergoes this rebalancing biannually, specifically on January 31st and July 31st.</p>
      <p className="mb-2">In contrast to the stock market, where leadership positions among industry leaders are relatively stable, the card composition within the PMI index may experience more considerable shifts. Nevertheless, the rebalancing process adheres to the following stringent guidelines:</p>
      <p className="mb-2">a. Card Limitation for Rebalancing: During each rebalancing phase, there is a cap of 25%, implying a maximum alteration of 125 cards within the PMI 500 Index.</p>
      <p className="p6 mb-2">Historical Rebalancing Data:</p>
      <ul className="list-disc list-inside mb-2 ml-4">
        <li className='flex justify-between mb-1'>- 2020: 15 cards were adjusted.</li>
        <li className='flex justify-between mb-1'>- 2021: 121 cards were adjusted. (Index self-adjusted to include more modern cards.)</li>
        <li className='flex justify-between mb-1'>- 2022: 81 cards were adjusted.</li>
        <li className='flex justify-between'>- 2023: 66 cards were adjusted.</li>
      </ul>
      <p className="mb-2">b. Continuity and the 'Base Divider': To ensure the consistent representation of the index, each rebalancing activity necessitates an adjustment to the 'Base Divider'. For the PMI 500, this divider has an upper limit of 2.5 (even though the value usually sits within &le;1.5). It’s crucial to understand that this value is specific to our index and may vary for others.</p>
      <p className="mb-2">c. Weights Allocation: There are 2 ways to calculate the weights.</p>
      <ul className="list-disc list-inside mb-2 ml-4">
        <li className='flex justify-between mb-1'>1 -By Card Market Price: Contrary to the S&P 500 which employs market capitalization to determine portfolio weights, the PMI 500 can use the card's price for this weight calculation, in general, the higher market value of the card, the more important of the card, which provides a higher weight.</li>
        <li className='flex justify-between mb-1'>2 -By total sales value: To illustrate, consider a PSA 8 card with a current market price of $10 and a total sales volume of 50 units, leading to a total sales value of $500. Proceeding with this methodology, we aggregate the sales values across all cards and determine the respective weights accordingly. The benefit of using total sales value (similar to market cap in stocks) is that, despite its lack of direct linkage to liquidity measurements, the total sales value ranking exhibits a substantial correlation with the liquidity ranking, in other words, higher total sales value rank tends to have higher liquidity rank (both in Pearson Correlation and Spearman Correlation), In contrast with the previous method (1), this method provides a more comprehensive view by incorporating liquidity considerations.</li>
        <li className='flex justify-between'>3 -We believe both (1) (2) are good, but (2) more robust.</li>
      </ul>
      <p className="mb-2">d. Maximum Weights Allocation: Individual card is subject to a weight ceiling of 5%. Should a card's weight exceed this threshold, it will be capped at 5%, and the excess weight will be proportionally redistributed across the remaining portfolio.</p>
    </>
  ),
  cgcPokemonMarketIndex500:  (
    <>
      <h3 className="p5 mb-2">The CGC Market Index and its Relationship with PMI 500 Index</h3>
      <p className="mb-2">The CGC Market Index is a comprehensive tool tailored to measure the market fluctuations based on CGC card market prices. While it mirrors the objective of the PMI 500 Index, which monitors market trends, the distinction lies in the sales prices extracted from different grading companies.</p>
      <p className="mb-2">Consider this analogy: In the stock market, both TSLA and APPL are constituents of the S&P 500 Index and are exclusively traded on NASDAQ. Drawing a parallel to the collectible card industry, imagine if APPL and TSLA, representing graded cards, could also be traded on an alternative exchange, termed NASDAQ_v2. Given that prices might differ between these exchanges, our aim is to compute the same index based on the stock prices from this alternative exchange.</p>
      <p className="mb-2">These indices (PMI 500 and the CGC Market Index) not only provide insights into the market's directional movement but also highlight the distinctions. By showing the two indices together, it prompts the question: Is the PSA market more dynamic, or does the CGC market exhibit greater stability? From an investment standpoint, these are great questions.</p>
      <h3 className="6 mb-2">Clarifications on Key Components:</h3>
      <p className="mb-2">1. Portfolio Selection: The CGC Market Index uses identical cards to the PMI 500 Index. For instance, if the latter encompasses a PSA 8 Unlimited Base Set Charizard card, it is swapped with its CGC 8 counterpart. Similarly, a PSA 10 card is substituted with either CGC Gem Mint 10 or CGC Pristine 10, in line with the two versions of the CGC index, the CGC Market Index (Gem Mint 10 Version/Pristine 10 Version).</p>
      <p className="mb-2">2. Weight Allocation: Portfolio weight allocation is consistent with the original PMI 500, derived from PSA market prices. Given PSA's market share of roughly 80%, it is more precise to adhere to the PMI 500 portfolio weights.</p>
      <p className="mb-2">3. Base Divider and Continuity: The 'Base Divider' in the CGC Market Index varies from the PMI 500 Index, ensuring uniformity throughout every rebalancing phase.</p>
      <p className="mb-2">4. Gem Mint 10, Pristine 10, and Perfect 10:</p>
      <ul className="list-disc list-inside mb-2 ml-4">
        <li className='flex justify-between mb-1'>1. CGC Perfect 10 data points are excluded from all computations. Due to their limited availability and elevated value, these cards do not genuinely represent the Graded 10 market value. (A Perfect 10 can reach $100 while a Pristine 10 is $25-$30.)</li>
        <li className='flex justify-between mb-1'>2. The new grading standards equate old CGC Gem Mint 9.5 with the new CGC Gem Mint 10. This allows for the availability of CGC Gem Mint 10 prices as of November 1, 2020.</li>
        <li className='flex justify-between'>3. Both the Old CGC Pristine 10 and the new CGC Pristine 10 fall under the same grading category, and they are treated equivalently. There are no modifications in this regard.</li>
      </ul>
      <h3 className="p6 mb-2">Additional notes on CGC Market Index's Inception Date of January 1, 2021:</h3>
      <p className="mb-2">- Reason for Choosing this Date: Although CGC initiated card grading in June 2020, the preliminary months lacked ample sales data to derive meaningful conclusions. To ensure the accuracy of market movement representation, we established a six-month buffering period (2020-June to 2020-Dec), which resulted in the selection of January 1, 2021, as the commencement date.</p>
      <p className="mb-2">- Option to align the CGC Index with PMI 500 Index's Starting Point: For a more coherent comparison of index trajectories, the CGC index can be scaled such that its initial value is the same as the concurrent value of the PSA index. This adjustment, akin to data normalization, provides a clear view of how each index evolves over time. This allows us to conclude which index portfolio outperforms the other, identify the more volatile one, and determine which remains more stable.</p>
    </>
  ),
  englishJapanesePokemonMarketIndex500:  (
    <>
      <h3 className="p5 mb-2">English and Japanese Market Indices</h3>
      <p className="mb-2">The English Market Index is specifically curated from cards available in the English language, while its Japanese Market Index focuses on Japanese cards. Both indices use the same selection and calculation standard as the PMI 500 Index.</p>
      <p className="mb-2">The language constraints mean that each index will feature a distinct set of cards compared to the main PMI 500 index.</p>
      <h3 className="p6 mb-2">Additional Note:</h3>
      <p className="mb-2">The Japanese Market Index currently contains 500 cards. However, prior to January 1, 2023, the index encapsulates less than 500 cards. However, for naming uniformity, we'll continue to use the “Japanese market index 500”.</p>
      <h3 className="p6 mb-2">- Historical Japanese Market Index Card Numbers:</h3>
      <ul className="list-disc list-inside mb-2 ml-4">
        <li className='flex justify-between mb-1'>- 2020: 182 cards included.</li>
        <li className='flex justify-between mb-1'>- 2021: 329 cards included.</li>
        <li className='flex justify-between mb-1'>- 2022: 393 cards included.</li>
        <li className='flex justify-between'>- 2023: 500 cards included.</li>
      </ul>
      <p className="mb-2">It's important to note that our sales data originates from US market platforms. While a substantial number of Japanese cards are favored in the Asian market, their lack in US sales can lead to exclusions based on liquidity prerequisites (For instance, the pre-2020 Index. This is also notably evident among the high-end modern cards featuring Trainer Characters like Lillie and Marnie). However, as the appreciation of Japanese cards grows in the US, its total sales volume has increased significantly, contributing to a more accurate Japanese card market index.</p>
      <h3 className="p6 mb-2">Option to align the English/Japanese Index with PMI 500 Index's Starting Point:</h3>
      <p className="mb-2">For a more coherent comparison of index trajectories, both English/Japanese index can be scaled such that its initial value is the same as the concurrent value of the PMI index. This adjustment, akin to data normalization, provides a clear view of how each index evolves over time. This allows us to conclude which index portfolio outperforms the other, identify the more volatile one, and determine which remains more stable.</p>
    </>
  ),
};