import React from 'react';

export const LoadingSmallSpinner = ({ className }) => {
  return (
    <div className={`w-16 h-16 flex justify-center items-center ${className}`}>
      <div className={`preloader-small`}>
        <div className="preloader-small">
          <div className="preloader-small">
            <div className="preloader-small">
              <div className="preloader-small"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
