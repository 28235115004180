import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid';

import empty from '../assets/images/empty.png';
import TCGFishApi from '../lib/TCGFishApi';
import { IconButtonBigFilled } from './IconButtonBigFilled';
import { formatNumber } from '../utils/formatNumber';

export const HomePopularCards = () => {
  const [error, setError] = React.useState(false);
  const [list, setList] = useState(null);
  const [rightVisible, setRightVisible] = useState(false);
  const [leftVisible, setLeftVisible] = useState(true);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    const firstCard = document.getElementById('featured_cards_first');
    const lastCard = document.getElementById('featured_cards_last');
    firstCard && setLeftVisible(firstCard.getBoundingClientRect().x >= 0);
    lastCard && setRightVisible(lastCard.getBoundingClientRect().x <= 1440);
  }, [refresh]);

  useEffect(() => {
    TCGFishApi
      .get(
        'cards/featured',
        {
          params: { page: 0 }
        }
      )
      .then((res) => {
          setList(res.data.data);
      })
      .catch((err) => {
        setError(true);
        console.error('Failed to get cards/featuredCards', err);
      });
  }, []);

  const FItems = () => {
    if (error) return <div className="">Error loading data, please try again</div>;

    if (!list) return <div className="">Loading...</div>;

    if (!list.length) return <div className="">No Featured Cards Found</div>;

    return list.map((card, index) => (
      <Link
        className="w-56 h-72 flex flex-col flex-shrink-0 rounded-lg border-min border-purple-300 relative justify-end items-center group"
        key={card._id}
        id={`${index === 0 ? 'featured_cards_first' : index === list.length - 1 ? 'featured_cards_last' : ''}`}
        to={`/product?id=${card._id}&cardNumber=${card.sub_cat[0].card_id}`}
      >
        <img
          className="w-48 h-54 flex-shrink-0 rounded-lg home_popular_cards_section-card-box-shadow absolute transition-all duration-500 ease-in-out top-2 group-hover:top-0"
          width="160"
          height="180"
          src={card.sub_cat[0].image}
          alt="IMG"
          onError={(e) => ((e.target.src = empty))}
        />
        <div className="w-52 h-24 flex flex-col justify-between px-2 pt-2 pb-2 flex-shrink-0 rounded-1.5 bg-purple-350 bg-opacity-40 backdrop-blur-2.5 absolute group-hover:bg-not-tonight-250">
          <p className="p3 text-child-of-light-1000 mb-2">
            {card.name} {!isNaN(card.card_number) ? <span>#{card.card_number}</span> : null}
          </p>
          <div className="inline-flex items-start w-full justify-between gap-2">
            <div className="flex flex-col items-start gap-1">
              <p className="p8 text-child-of-light-500">Average price</p>
              <p className="p5 text-not-tonight-1000">
                {isNaN(card.averageSoldPrice) ? '---' : `$${formatNumber(card.averageSoldPrice)}`}
              </p>
            </div>

            <div className="w-min self-stretch bg-not-tonight-700"></div>

            <div className="flex flex-col items-start gap-1">
              <p className="p8 text-child-of-light-500">Transactions</p>
              <p className="p5 text-not-tonight-1000">
                {isNaN(card.totalTransactions) ? '---' : card.totalTransactions}
              </p>
            </div>
          </div>
        </div>
      </Link>
    ));
  };

  const ref = useRef(null);
  const scrollTo = (direction) => {
    ref.current.scrollLeft += (direction === 'left' ? -1 : 1) * (window.innerWidth / 2) * 1.5;
  };

  return (
    <div className="w-full h-96 mb-26 flex flex-col flex-shrink-0 items-center">
      {/*<h2 className="w-182.25 h-12 flex-shrink-0 h3 text-not-tonight-1000 text-center mb-10">*/}
      {/*  Popular Cards*/}
      {/*</h2>*/}
      <div
        className="flex items-start w-full gap-1.5 overflow-x-scroll overflow-y-hidden no-scrollbar"
        ref={ref}
        onScroll={() => {
          const timeOutId = setTimeout(() => {
            setRefresh(!refresh);
          }, 250);
          return () => clearTimeout(timeOutId);
        }}
      >
        {!leftVisible && (
          <div className="w-0 h-0 p-0 m-0 sticky z-10 top-28 left-8">
            <IconButtonBigFilled
              className="home_popular_cards_section-button-box-shadow"
              onClick={() => {
                scrollTo('left');
                setRefresh(!refresh);
              }}
              Icon={ChevronLeftIcon}
            />
          </div>
        )}
        <FItems />
        {!rightVisible && (
          <div className="w-0 h-0 p-0 m-0 sticky z-10 top-28 right-14">
            <IconButtonBigFilled
              className="home_popular_cards_section-button-box-shadow"
              onClick={() => {
                scrollTo('right');
                setRefresh(!refresh);
              }}
              Icon={ChevronRightIcon}
            />
          </div>
        )}
      </div>
    </div>
  );
};
