import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import { blueGrey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
  marginLeft: 3,
  marginRight: 3,
  color: theme.palette.getContrastText(blueGrey[500]),
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: blueGrey[700],
  },
}));

export default function Pagination({ cardsPerPage, totalcards, paginate, currentPage }) {
  const [TempPage, setTempPage] = useState(currentPage);
  const PageNumberLimit = 5;
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(Math.floor((currentPage - 1) / PageNumberLimit) * PageNumberLimit);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(Math.ceil((currentPage) / PageNumberLimit) * PageNumberLimit);
  const pageNumbers = [];

  useEffect(() => {
    setMinPageNumberLimit(Math.floor((currentPage - 1) / PageNumberLimit) * PageNumberLimit);
    setMaxPageNumberLimit(Math.ceil((currentPage) / PageNumberLimit) * PageNumberLimit);
  }, [currentPage])

  for (let i = 1; i <= Math.ceil(totalcards / cardsPerPage); i++) {
    pageNumbers.push(i);
  }
  const handlePrevbtn = (number) => {
    callPaginate(number - 1);
    if ((number - 1) % PageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - PageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit - PageNumberLimit);
    }
  };
  const handleNextbtn = (number) => {
    callPaginate(number + 1);
    if (number + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + PageNumberLimit);
      setMinPageNumberLimit(minPageNumberLimit + PageNumberLimit);
    }
  };
  const handleIncrease = () => {
    setMaxPageNumberLimit(maxPageNumberLimit + PageNumberLimit);
    setMinPageNumberLimit(minPageNumberLimit + PageNumberLimit);
    setTempPage(TempPage + 5);
  };
  const handleDecrease = () => {
    setMaxPageNumberLimit(maxPageNumberLimit - PageNumberLimit);
    setMinPageNumberLimit(minPageNumberLimit - PageNumberLimit);
    setTempPage(TempPage - 5);
  };
  const handleFirstJump = (number) => {
    setMaxPageNumberLimit(number + 4);
    setMinPageNumberLimit(number - 1);
    callPaginate(number);
  };
  const handleLastJump = (number) => {
    setMaxPageNumberLimit(number);
    setMinPageNumberLimit(number - (pageNumbers.length % PageNumberLimit));
    callPaginate(number);
  };

  const callPaginate = (number) => {
    setTempPage(number);
    paginate(number);
  };
  let firstButton = null;
  if (TempPage >= 6) {
    firstButton = (
      <ColorButton
        color="primary"
        variant="contained"
        onClick={() => handleFirstJump(pageNumbers[0])}
      >
        {pageNumbers[0]}
      </ColorButton>
    );
  }

  let lastButton = null;
  if (TempPage < (pageNumbers.length - (pageNumbers.length % PageNumberLimit))) {
    lastButton = (
      <ColorButton
        color="primary"
        variant="contained"
        onClick={() => handleLastJump(pageNumbers[pageNumbers.length - 1])}
      >
        {pageNumbers[pageNumbers.length - 1]}
      </ColorButton>
    );
  }

  let pageIncrementBtn = null;
  if (pageNumbers.length - 1 > maxPageNumberLimit) {
    pageIncrementBtn = (
      <ColorButton
        disabled={ TempPage === pageNumbers[pageNumbers.length - 1] }
        color="primary"
        variant="contained"
        onClick={() => handleIncrease()}
      >
        &hellip;
      </ColorButton>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = (
      <ColorButton
        color="primary"
        variant="contained"
        onClick={() => handleDecrease()}
      >
        &hellip;
      </ColorButton>
    );
  }
  const RenderPag = pageNumbers.map((number, index) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <ColorButton
          key={index}
          color="primary"
          variant={currentPage === number ? "outlined" : "contained"}
          onClick={() => callPaginate(number)}
        >
          {number}
        </ColorButton>
      );
    } else {
      return null;
    }
  });
  if ((totalcards !== 0))
    return (
      <div>
        <div className="flex justify-center items-center text-white">
          <ColorButton
            disabled={ currentPage === pageNumbers[0] }
            color="primary"
            variant="contained"
            onClick={() => handlePrevbtn(currentPage)}
          >
            Previous
          </ColorButton>
          {firstButton}
          {pageDecrementBtn}
          {RenderPag}
          {pageIncrementBtn}
          {lastButton}
          <ColorButton
            disabled={ currentPage === pageNumbers[pageNumbers.length - 1] }
            color="primary"
            variant="contained"
            onClick={() => handleNextbtn(currentPage)}
          >
            Next
          </ColorButton>
        </div>
      </div>
    );
};
