import React from 'react';
import './Market.css';
import AverageSalesPrice from '../../components/Market/MarketRecap/AverageSalesPrice/AverageSalesPrice';
import SalesVolumeBreakdown from '../../components/Market/MarketRecap/SalesVolumeBreakdown/SalesVolumeBreakdown';
import SalesValueBreakdown from '../../components/Market/MarketRecap/SalesValueBreakdown/SalesValueBreakdown';
import PopularCards from '../../components/Market/MarketMovers/PopularCards/PopularCards';
import PopularSets from '../../components/Market/MarketMovers/PopularSets/PopularSets';
import PopularCharacters from '../../components/Market/MarketMovers/PopularCharacters/PopularCharacters';
import RecordHigh from '../../components/Market/RecordSales/RecordHigh/RecordHigh';
import RecordLow from '../../components/Market/RecordSales/RecordLow/RecordLow';
import PremiumSales from '../../components/Market/RecordSales/PremiumSales/PremiumSales';
import SalesVolumeByGC from '../../components/Market/GradingCompanies/SalesVolumByGC/SalesVolumeByGC';
import MarketCheckBox from '../../components/MarketCheckBox/MarketCheckBox';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb'
// import Maintenance from '../Maintenance/Maintenance';

export default function Market({ windowWidth }) {
  return (
    <>
      {/* <Maintenance /> */}
      <div className="market-body"></div>

      <div style={{ width: '85%', margin: 'auto' }}>
        <BreadCrumb />
      </div>
      <div className="market">
        {windowWidth > 960 &&
          <div className="all market__container">
            <div className="filter-main">
              <MarketCheckBox windowWidth={windowWidth} />
            </div>
            <br />
          </div>
        }
        <div className="market__main">
          {windowWidth <= 960 &&
            <div className="market__right__side__heading__mobile">
              Market Research Tool
            </div>
          }
          <div className="market__right__side">
            <div className="firstComponent"
                 id="00"
            >
              <AverageSalesPrice windowWidth={windowWidth} />
            </div>
            <div id="01">
              <SalesVolumeBreakdown />
            </div>
            <div id="02">
              <SalesValueBreakdown />
            </div>
            <div id="10">
              <PopularCards windowWidth={windowWidth} />
            </div>
            <div id="11">
              <PopularSets />
            </div>
            <div id="12">
              <PopularCharacters windowWidth={windowWidth} />
            </div>
            <div id="20">
              <RecordHigh />
            </div>
            <div id="21">
              <RecordLow />
            </div>
            <div id="22">
              <PremiumSales windowWidth={windowWidth} />
            </div>
            <div id="30">
              <SalesVolumeByGC windowWidth={windowWidth} />
            </div>
            <div className="market__hider"></div>
          </div>
        </div>
      </div>
    </>
  );
}
