import React, { useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { FlagIcon } from '@heroicons/react/20/solid';

import TCGFishApi from '../lib/TCGFishApi';

export const SalesTable = ({ cardID, company, grades }) => {
  const [sales, setSales] = useState({});

  useEffect(() => {
    if (!cardID || !company || !grades || !grades.length) {
      return;
    }
    TCGFishApi
      .get(
        'sales/last90DaysCardSales',
        {
          params: {
            card_id: cardID,
            company: company,
            grades: grades
          }
        }
      )
      .then((res) => {
        setSales(res.data);
      })
      .catch((err) => {
        console.error('Failed to get sales/last90DaysCardSales', err);
      });
  }, [cardID, company, grades]);

  return (
    <div className="inline-flex w-full max-h-127.5 mb-16 mt-10 rounded-lg items-center justify-center bg-not-tonight-100">
      <div className="flex flex-col w-full items-start gap-5">
        <p className="p3 text-not-tonight-1000">
          Past 90 DAYS Public Sales Information
        </p>
        {(!sales || !sales[company.toLowerCase()]?.length) ?
          <div>
            <p className="text-white p2">
              No Data Available
            </p>
          </div>
        :
          <table className={`flex flex-col max-h-92 w-full items-start ${sales[company.toLowerCase()].length > 5 ? 'overflow-y-scroll' : ''}`}>
            <thead>
              <tr className="flex border-b-min border-b-not-tonight-300 bg-not-tonight-100 text-child-of-light-400 p8">
                <th className="flex py-3 px-4 items-center gap-2.5 self-stretch w-66 p8">
                  GRADE
                </th>
                <th className="flex py-3 px-4 items-center gap-2.5 self-stretch w-66 p8">
                  SOLD DATE
                </th>
                <th className="flex py-3 px-4 items-center gap-2.5 self-stretch w-66 p8">
                  PLATFORM
                </th>
                <th className="flex py-3 px-4 items-center gap-2.5 self-stretch w-66 p8">
                  SOLD PRICE (USD)
                </th>
                <th className="flex py-3 px-4 items-center gap-2.5 self-stretch w-54 p8">
                  ACTIONS
                </th>
              </tr>
            </thead>
            <tbody>
              {sales[company.toLowerCase()].map((sale, index) =>
                <tr className={`flex h-15 items-center self-stretch border-b-min border-not-tonight-300 text-child-of-light-1000 p6 hover:cursor-pointer ${index % 2 === 0 ? 'bg-not-tonight-100' : 'bg-not-tonight-200'}`}
                    key={`sales-table-sale-${index}`}
                >
                  <td className="py-5 px-4 w-66">
                    <a className=""
                       href={sale.product_link === 'No Url' ? '' : sale.product_link}
                       target="_blank"
                       rel="noreferrer"
                    >
                      {`${company} ${sale.grade}`}
                    </a>
                  </td>
                  <td className="py-5 px-4 w-66">
                    <a className=""
                       href={sale.product_link === 'No Url' ? '' : sale.product_link}
                       target="_blank"
                       rel="noreferrer"
                    >
                      {sale.date.slice(0, 10).split('-').reverse().join('-')}
                    </a>
                  </td>
                  <td className="py-5 px-4 w-66">
                    <a className=""
                       href={sale.product_link === 'No Url' ? '' : sale.product_link}
                       target="_blank"
                       rel="noreferrer"
                    >
                      <div className="gap-0.5 flex items-end self-stretch">
                        <LaunchIcon fontSize="small" />
                        <span className="uppercase">
                          {sale.platform}
                        </span>
                      </div>
                    </a>
                  </td>
                  <td className="py-5 px-4 w-66">
                    <a className=""
                       href={sale.product_link === 'No Url' ? '' : sale.product_link}
                       target="_blank"
                       rel="noreferrer"
                    >
                      ${sale.sold.toFixed(2)}
                    </a>
                  </td>
                  <td className="py-5 px-4 w-54">
                    <FlagIcon className="w-8 h-8 flex py-1 px-1 items-center rounded-lg border-min border-not-tonight-300 flex-shrink-0 text-white"
                              onClick={() => alert("Soon you'll be able to report sales")}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        }
      </div>
    </div>
  );
};
