import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './MasterSetValue.css';
import MasterSetValueChart from "../../components/MasterSetValueChart/MasterSetValueChart";
import CheckBoxSetMSV from "../../components/CheckBox/CheckBoxSetMSV";
import MarketSetValueTopCards from "../../components/MarketSetValueTopCards/MarketSetValueTopCards";
import BreadCrumb from "../../components/BreadCrumb/BreadCrumb";
import MetadataTags from '../../components/MetadataTags/MetadataTags';
// import Maintenance from "../Maintenance/Maintenance";
import TCGFishApi from '../../lib/TCGFishApi';
import {
    DEFAULT_PAGE_TITLE,
    DEFAULT_PAGE_DESCRIPTION,
    DEFAULT_PAGE_KEYWORDS
} from '../../lib/constants';

export default function MasterSetValue({ windowWidth }) {
    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search;
    const parameterSetName = new URLSearchParams(search).get('setname');

    const [error, setError] = React.useState(false);
    const [setFilters, setSetFilters] = React.useState(parameterSetName ? parameterSetName : null);
    const [allSets, setAllSets] = React.useState(null);
    const [showSets, setShowSets] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [forceClose, setForceClose] = React.useState(false);

    const pageTitle = `Complete PSA Graded Master Set Value - ${DEFAULT_PAGE_TITLE}`;
    const pageDescription = `Complete PSA Graded Master Set Value - ${DEFAULT_PAGE_DESCRIPTION}`;
    const pageKeywords = `Complete Master Set Value, PSA Graded, ${DEFAULT_PAGE_KEYWORDS}`;

    React.useEffect(() => {
        setSetFilters(parameterSetName ? parameterSetName : null);
    }, [parameterSetName]);

    React.useEffect(() => {
        setError(false);

        TCGFishApi.get('msv/getSets').then((res) => {
            setAllSets(res.data.data);
        }
        ).catch((err) => {
            console.error('Failed to get msv/getSets', err);
            setError(true);
        });
    }, []);

    React.useEffect(() => {
        if (!allSets?.length) { return; }

        if (!setFilters) {
          return navigate(`/setvalue?setname=${allSets[0]}`);
        }

        if (allSets.indexOf(setFilters) === -1) {
            return setError(true);
        }
    }, [allSets, setFilters]);

    React.useEffect(() => {
        setShowSets(false);
    }, [forceClose]);

    function changeSets(item) {
        navigate(`/setvalue?setname=${item}`);
        setRefresh(!refresh);
        setForceClose(!forceClose);
    }

    if (error) return (
        <div className="MasterSetValue">
            <MetadataTags title={ pageTitle }
                          description={ pageDescription }
                          keywords={ pageKeywords }
            />

            <div className="MasterSetValue_NavSpace"></div>
            <div className="MasterSetValue_Container">
                <div className="MasterSetValue_Error_Message">
                    Failed to load Sets data<br /><br />
                    Please refresh the page and try again
                </div>
            </div>
        </div >
    )
    return (
        <div className="MasterSetValue">
            <MetadataTags title={ pageTitle }
                          description={ pageDescription }
                          keywords={ pageKeywords }
            />

            {/* <Maintenance /> */}
            <div className="MasterSetValue_Container">
                <BreadCrumb />
                <div className="MasterSetValue_Head">
                    Complete PSA Graded Master Set Value
                </div>
                <div className="MasterSetValue_Head_Text">
                    The complete set includes all holos, rares, commons,
                    uncommons, reverse foils and secret rare cards in
                    the set.
                    <br />
                    It does not include any graded sealed packs, promos,
                    pre-release cards. Popular error variations (inverted
                    back, miscut, off-centered, stained) are not included.
                    <br />
                    If no sales history for certain cards in the set, the
                    card will not also be included in the chart. If certain
                    cards does not have any recent sales info, we use the
                    last sold price.
                    <br />
                    <br />
                    <div style={{ fontStyle: "italic" }}>Note : Chart starts from $0 on 2019-01, most sets will
                        have a useable total value starting from 2019-05, do
                        not refer to any information in between, use with caution.
                    </div>
                </div>
                <div className="MasterSetValue_Set">
                    <div className="MasterSetValue_Set_Head" onClick={() => { setShowSets(true); }}>
                        {(setFilters) ? `${setFilters}` : "Fetching Set Data..."}
                        {showSets ? (
                            <>
                                <div className="MasterSetValue_Set_List_Container">
                                    <div className="MasterSetValue_Set_List">
                                        <CheckBoxSetMSV
                                            hideSets={() => {
                                                setShowSets(false);
                                            }}
                                            setFilters={setFilters}
                                            allSets={allSets}
                                            changeSets={(item) => {
                                                changeSets(item);
                                            }}
                                            refresh={refresh}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div>
                        <i
                            style={{ fontSize: "20px", color: "white", margin: "5px 0 0 7.5px ", cursor: "pointer" }}
                            onClick={() => { setShowSets(true); }}
                            className="fa fa-angle-down fa-1x">
                        </i>
                    </div>
                </div>
                <div className="MasterSetValue_Set_Chart">
                    <MasterSetValueChart setFilters={setFilters} windowWidth={windowWidth} />
                </div>
                <div className="MasterSetValue_Top_Cards_Section">
                    <MarketSetValueTopCards setFilters={setFilters} />
                </div>
            </div>
        </div >
    )
}
