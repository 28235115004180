import React, { useEffect, useState } from 'react';
import './RecordLow.css';

import Listing from '../../../Listing/Listing';
import TCGFishApi from '../../../../lib/TCGFishApi';
import { normalizeGrade } from '../../../../lib/helpers';

export default function RecordLow() {
  const [show, setShow] = useState(false);
  const [recordSalesLow, setRecordSalesLow] = useState([]);

  useEffect(() => {
    TCGFishApi
      .get('market/getms6low')
      .then((res) => {
        const temp = res.data.count;
        for (let j = 0; j < temp.length; j++) {
          temp[j].date = temp[j].date
            .slice(0, 10)
            .split('-')
            .reverse()
            .join('-');
          if (temp[j].grade) {
            temp[j].grade = temp[j].grade.replace('.0', '');
          }
        }
        setRecordSalesLow(temp);
      })
      .catch((err) => {
        console.error('Failed to get market/getms6low', err);
      });
  }, []);

  return (
    <div className="record__low">
      <Listing
        data={recordSalesLow}
        show={show}
        setShow={(b) => { setShow(b) }}
        order={[
          { name: 'name', displayName: 'Card Name' },
          { name: 'set_name', displayName: 'Set Name' },
          { name: 'date', displayName: 'Sold Date' },
          { name: 'grade', displayName: 'Grade' },
          { name: 'sold', displayName: 'Sold Price' },
          { name: 'Language', displayName: 'Language' }
        ]}
      />
      <div className="relo__box">
        <div className="relo__heading__area">
          <div className="relo__heading">Record Sales / Low</div>
          <span className="relo__description">
            <p>Discover cards with rocket speed movement.</p>
            <p>Cards reaching 52W low (past 90 days) will be listed.</p>
          </span>
        </div>
        <div className="relo__info__box">
          <div className="relo__info__box__header">
            <p className="relo__cn">Card Name</p>
            <p className="relo__sn">Set Name</p>
            <p className="relo__sd">Sold Date</p>
            <p className="relo__g">Grade</p>
            <p className="relo__sp">Sold Price</p>
            <p className="relo__l">Language</p>
          </div>
          <div className="relo__data">
            {recordSalesLow.slice(0, 5).map((item) =>
              <div className="relo__data__row"
                   key={item._id}
              >
                <p className="relo__data__cn">{item.name}</p>
                <p className="relo__data__sn">{item.set_name}</p>
                <p className="relo__data__sd">{item.date}</p>
                <p className="relo__data__g">{normalizeGrade(item.grade)}</p>
                <p className="relo__data__sp">{item.sold}</p>
                <p className="relo__data__l">{item.Language}</p>
              </div>
            )}
          </div>
        </div>
        <div className="relo__fl">
          <div className="relo__fl__link"
               onClick={() => { setShow(true) }}
          >
            Full List >>
          </div>
        </div>
      </div>
    </div>
  );
}
