import React, { useState } from 'react';
import { IconButtonBig } from './IconButtonBig';
import { XMarkIcon } from '@heroicons/react/24/solid';

import logo from '../assets/images/modal_logo.svg';
import TCGFishApi from '../lib/TCGFishApi';
import { PrimaryLargeButton } from './PrimaryLargeButton';
import { UNEXPECTED_ERROR_MESSAGE } from '../lib/constants';

export const ForgotPasswordModal = ({ close, showLogInModal }) => {
  const [formValues, setFormValues] = useState({ email: '' });
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccessful, setIsSuccessful] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessage('');

    if (formValues.email === '') {
      return setErrorMessage('Please enter valid email.');
    }

    if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      return setErrorMessage('Please enter valid email.');
    }

    TCGFishApi
      .post(
        'user/request-reset-password',
        {
          email: formValues.email
        }
      )
      .then((_res) => {
        // console.log(res.data.message);
        setIsSuccessful(true);
      })
      .catch((err) => {
        console.error('Failed to request user/request-reset-password:', err);

        if (err.response?.data?.errors?.length) {
          const msg = err.response.data.errors?.map(obj => obj.message).join(', ');
          setErrorMessage(msg);
        } else {
          setErrorMessage(UNEXPECTED_ERROR_MESSAGE);
        }
      });
  };

  return (
    <div className="flex items-center fixed top-0 left-0 w-full h-screen overflow-x-hidden overflow-y-auto z-50 bg-modal-shadow bg-opacity-81 backdrop-blur"
         onClick={close}
    >
      <div className="flex flex-col flex-shrink-0 relative w-133 mx-auto rounded-lg border-min border-not-tonight-200 bg-purple-100 overflow-hidden"
           onClick={(e) => e.stopPropagation()}
      >
        <svg className="absolute top-0 left-0"
             width="231" height="468" viewBox="0 0 231 468" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M-98.3383 374.099L218.169 110.5L-98.3383 -153.099L-98.3383 374.099Z" stroke="#341956" strokeWidth="22.8228"/>
            <path d="M-125.338 374.099L191.169 110.5L-125.338 -153.099L-125.338 374.099Z" stroke="#221139" strokeWidth="22.8228"/>
            <path d="M170.261 118.611C175.328 114.391 175.329 106.609 170.261 102.389L-148.439 -163.036C-155.315 -168.763 -165.75 -163.873 -165.75 -154.926L-165.75 375.925C-165.75 384.873 -155.315 389.763 -148.439 384.036L170.261 118.611Z"
                  fill="#11081D"/>
          </g>
        </svg>
        <svg className="absolute top-0 right-0"
             width="520" height="526" viewBox="0 0 520 526" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M510.167 613.101L98.8366 281.5L510.167 -50.1009L510.167 613.101Z" stroke="#341956" strokeWidth="28.6654"/>
            <path d="M546.167 613.101L134.837 281.5L546.167 -50.1009L546.167 613.101Z" stroke="#221139" strokeWidth="28.6654"/>
            <path d="M167.012 291.869C160.351 286.561 160.351 276.439 167.012 271.131L586.23 -62.9178C594.915 -69.8382 607.75 -63.6541 607.75 -52.5493L607.75 615.549C607.75 626.654 594.915 632.838 586.23 625.918L167.012 291.869Z"
                  fill="#0C0415"/>
          </g>
        </svg>
        <div className="self-end mt-3.5 mr-5.5 z-10">
          <IconButtonBig Icon={XMarkIcon}
                         onClick={close}
          />
        </div>
        <img className="w-20.75 h-12.5 flex-shrink-0 mx-auto -mt-5.5 z-10"
             src={logo}
             alt="TCGFish logo"
        />
        {isSuccessful ?
          <>
            <h2 className="mx-auto mt-8 mb-3 h6 font-semibold text-not-tonight-1000 z-10">
              Email sent
            </h2>
            <p className="mx-auto mb-8 p4 text-child-of-light-400 z-10">
              Check you email for recovery link
            </p>
            <PrimaryLargeButton className="w-105 mx-auto mb-6 z-10"
                                text="Back to the home page"
                                onClick={showLogInModal}
            />
            <div className="w-full flex flex-row justify-center items-center mb-8 gap-2 z-10">
              <p className="p4 text-white">
                Didn’t receive a link?
              </p>
              <div className="text-link-purple p3 cursor-pointer"
                   onClick={handleSubmit}
                   role="button"
              >
                Resend
              </div>
            </div>
          </>
          :
          <>
            <h3 className="text-not-tonight-1000 h8 mx-auto mt-8 mb-7 z-10">
              Forgot Password
            </h3>
            <form className="flex flex-col items-center w-105 mx-auto gap-5 z-10"
                  onSubmit={handleSubmit}
            >
              <p className="w-83.75 text-center p4 text-child-of-light-400 z-10">
                Enter your email adress to receive a password recovery link
              </p>
              <input className="flex flex-shrink-0 w-full items-center h-12 py-2.75 px-4 mb-4 rounded-lg bg-not-tonight-200 outline-none text-white hover:border-min hover:border-not-tonight-300 focus:bg-purple-100 focus:placeholder:text-purple-100"
                     type="text"
                     placeholder="Email"
                     name="email"
                     value={formValues.email}
                     onChange={(e) => setFormValues({...formValues, email: e.target.value})}
              />
              {errorMessage &&
                <p className="p7 text-dusk-orange-700">
                  {errorMessage}
                </p>
              }
              <PrimaryLargeButton className="flex w-105 h-12"
                                  text="Send recovery link"
                                  onClick={handleSubmit}
              />
              <div className="inline-flex justify-center items-start gap-2 mt-6 mb-8">
                <span className="text-white p4">
                  Remembered you password?
                </span>
                <div className="text-link-purple p3 cursor-pointer"
                     onClick={showLogInModal}
                     role="button"
                >
                  Back to login
                </div>
              </div>
            </form>
          </>
        }
      </div>
    </div>
  );
};
