import { useState, useEffect, useRef } from 'react';
import { ArrowsUpDownIcon, ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

export const SortDropdown = ({ options, selectedOption, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  // Find the current label for the selected sort option
  const selectedLabel = options.find((option) => option.value === selectedOption)?.label;
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
  return (
    <div className="min-w-fit relative inline-block text-left" ref={ref}>
      <button className="inline-flex justify-center items-center w-full px-4 text-sm font-medium bg-transparent rounded-md hover:transparent focus:outline-none"
              onClick={() => setIsOpen(!isOpen)}
              id="options-menu"
              aria-haspopup="true"
              aria-expanded={isOpen}
      >
        <ArrowsUpDownIcon className="h-5 w-5 text-child-of-light-500 mr-1" />
        <span className="text-child-of-light-500 p7">
          Sort by
        </span>
        <span className="mx-2 text-white">
          {selectedLabel}
        </span>
        {isOpen ?
          <ChevronUpIcon className="h-5 w-5 text-white"
                         aria-hidden="true"
          />
        :
          <ChevronDownIcon className="h-5 w-5 text-white"
                           aria-hidden="true"
          />
        }
      </button>

      {isOpen &&
        <div className="absolute right-0 z-50 mt-2 w-44 origin-top-right rounded-md shadow-lg text-white  bg-not-tonight-200 ring-1  ring-black ring-opacity-5">
          <div className="py-1"
               role="menu"
               aria-orientation="vertical"
               aria-labelledby="options-menu"
          >
            {options.map((option) =>
              <div className={`block px-4 py-3 p7 text-child-of-light-1000 hover:bg-not-tonight-300 hover:text-gray-900 cursor-pointer ${selectedOption === option.value ? 'bg-gray-100' : ''}`}
                   key={option.value}
                   role="menuitem"
                   onClick={(evt) => {
                     evt.preventDefault();
                     onSelect(option.value);
                     setIsOpen(false);
                   }}
              >
                {option.label}
              </div>
            )}
          </div>
        </div>
      }
    </div>
  );
};
