import React, { useEffect, useState } from 'react';
import './SalesVolumeByGC.css';
import CanvasJSReact from '../../../../assets/canvasjs.react';
import {TramRounded} from '@mui/icons-material';
import Listing from '../../../Listing/Listing';
import TCGFishApi from '../../../../lib/TCGFishApi';


const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function SalesVolumeByGC({ windowWidth }) {
  const [weeklyInfo, setWeeklyInfo] = useState([]);
  const [barData, setBarData] = useState([]);
  const [dData, setDData] = useState({});
  const [show, setShow] = useState(false);
  const [columnMax, setColumnMax] = useState(100);

  useEffect(() => {
    TCGFishApi
      .get('market/getms5table')
      .then((res) => {
        const temp = res.data.count;
        for (let j = 0; j < temp.length; j++) {
          temp[j].week_time = temp[j].week_time
            .slice(0, 10)
            .split('-')
            .reverse()
            .join('-');

          setWeeklyInfo(temp);
        }
      })
      .catch((err) => {
        console.error('Failed to get market/getms5table', err);
      });

    TCGFishApi
      .get('market/getms5bar')
      .then((res) => {
          const temp = res.data.count;
          let max = columnMax;
          for (let j = 0; j < temp.length; j++) {
            temp[j].date = temp[j].date
              .slice(0, 10)
              .split('-')
              .reverse()
              .join('-');
            if (temp[j].count > max) {
              max = temp[j].count;
          }

          setColumnMax(max);
          setBarData(temp);
        }
      })
      .catch((err) => {
        console.error('Failed to get market/getms5bar', err);
      });

    TCGFishApi
      .get('market/getms5doughnut')
      .then((res) => {
          const temp = res.data?.count[0] || {};
          setDData(temp);
      })
      .catch((err) => {
        console.error('Failed to get market/getms5doughnut', err);
      });
  }, []);

  const psaBar = barData.filter(
    (item) => item.grading_company === 'Graded by PSA'
  );
  const cgcBar = barData.filter(
    (item) => item.grading_company === 'Graded by CGC'
  );
  const bgsBar = barData.filter(
    (item) => item.grading_company === 'Graded by BGS'
  );

  const psaBarChart = () => {
    const psaDp = [];
    psaBar.forEach((item) => {
      const toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(20 + year, month - 1, day);
      };
      psaDp.push({
        y: parseInt(item.count),
        x: toDate(item.date),
      });
    });
    return psaDp;
  };

  const bgsBarChart = () => {
    const bgsDp = [];
    bgsBar.forEach((item) => {
      const toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(20 + year, month - 1, day);
      };
      bgsDp.push({
        y: parseInt(item.count),
        x: toDate(item.date),
      });
    });
    return bgsDp;
  };

  const cgcBarChart = () => {
    const cgcDp = [];
    cgcBar.forEach((item) => {
      const toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(20 + year, month - 1, day);
      };
      cgcDp.push({
        y: parseInt(item.count),
        x: toDate(item.date),
      });
    });
    return cgcDp;
  };

  CanvasJS.addColorSet('svgccolorSet', ['#2277c0', '#E0645A']);

  const options = {
    colorSet: 'svgccolorSet',
    animationEnabled: true,
    animationDuration: 2000,
    dataPointWidth: 15,
    backgroundColor: '#0F0F0F',
    theme: 'none',
    title: {
      text: 'Sales Volume (#)',
      fontColor: 'white',
      fontSize: 16,
      fontFamily: 'Roboto',
      horizontalAlign: 'left',
      padding: 20,
    },
    axisX: {
      margin: 10,
      padding: {
        top: 10,
      },
      labelFontColor: '#fff',
      tickLength: 10,
      tickColor: '#0F0F0F',
      lineColor: '#0F0F0F',
      lineThickness: 1,
      valueFormatString: 'MM/DD',
    },
    axisY: {
      margin: 10,
      labelFontColor: '#fff',
      valueFormatString: '#,###,###.##',
      tickLength: 10,
      minimum: 0,
      maximum: columnMax,
      tickColor: '#0F0F0F',
      lineThickness: 6,
      lineColor: '#0F0F0F',
    },
    toolTip: {
      content:
        '{name}:<br/>Date: <strong>{x}</strong><br/>Value: <strong>{y}</strong>',
    },
    legend: {
      cursor: 'pointer',
      fontColor: '#fff',
      maxWidth: 600,
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: 16,
      itemWidth: 80,
      markerMargin: 10,
      itemclick: function (e) {
        e.dataSeries.visible = !(typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible);
        e.chart.render();
      },
    },
    data: [
      {
        type: 'column',
        bevelEnabled: true,
        legendMarkerType: 'circle',
        showInLegend: true,
        name: 'CGC',
        color: '#4472C4',
        dataPoints: cgcBarChart(),
      },
      {
        type: 'column',
        bevelEnabled: true,
        legendMarkerType: 'circle',
        showInLegend: true,
        name: 'PSA',
        color: '#70AD47',
        dataPoints: psaBarChart(),
      },
      {
        type: 'column',
        showInLegend: true,
        legendMarkerType: 'circle',
        bevelEnabled: true,
        name: 'BGS',
        color: '#FFC000',
        dataPoints: bgsBarChart(),
      },
    ],
  };

  const mobOptions = {
    colorSet: 'svgccolorSet',
    animationEnabled: true,
    animationDuration: 2000,
    dataPointWidth: 5,
    height: 200,
    backgroundColor: '#0F0F0F',
    theme: 'none',
    title: {
      text: 'Sales Volume (#)',
      fontColor: 'white',
      fontSize: 11,
      fontFamily: 'Roboto',
      horizontalAlign: 'left',
      padding: 5,
    },
    axisX: {
      margin: 10,
      padding: {
        top: 0,
      },
      labelFontColor: '#fff',
      tickLength: 5,
      tickColor: '#0F0F0F',
      lineColor: '#0F0F0F',
      lineThickness: 1,
      valueFormatString: 'MM/DD',
    },
    axisY: {
      margin: 0,
      labelFontColor: '#fff',
      valueFormatString: '#,###,###.##',
      tickLength: 0,
      minimum: 0,
      maximum: columnMax,
      tickColor: '#0F0F0F',
      lineThickness: 6,
      lineColor: '#0F0F0F',
    },
    toolTip: {
      content:
        '{name}:<br/>Date: <strong>{x}</strong><br/>Value: <strong>{y}</strong>',
    },
    legend: {
      cursor: 'pointer',
      fontColor: '#fff',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: 11,
      itemWidth: 50,
      markerMargin: 10,
      itemclick: function (e) {
        e.dataSeries.visible = !(typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible);
        e.chart.render();
      },
    },
    data: [
      {
        type: 'column',
        bevelEnabled: true,
        legendMarkerType: 'circle',
        showInLegend: true,
        name: 'CGC',
        color: '#4472C4',
        dataPoints: cgcBarChart(),
      },
      {
        type: 'column',
        bevelEnabled: true,
        legendMarkerType: 'circle',
        showInLegend: true,
        name: 'PSA',
        color: '#70AD47',
        dataPoints: psaBarChart(),
      },
      {
        type: 'column',
        showInLegend: true,
        legendMarkerType: 'circle',
        bevelEnabled: true,
        name: 'BGS',
        color: '#FFC000',
        dataPoints: bgsBarChart(),
      },
    ],
  };

  const dataPoints = () => {
    const dp = [];

    if (Object.keys(dData).length) {
      const sum = dData.psa + dData.cgc + dData.bgs;
      dp.push({
        name: 'Graded by PSA',
        y: (dData.psa / sum) * 100,
        exploded: TramRounded
      });
      dp.push({
        name: 'Graded by BGS',
        y: (dData.bgs / sum) * 100,
      });
      dp.push({
        name: 'Graded by CGC',
        y: (dData.cgc / sum) * 100,
      });
    }

    return dp;
  };

  const doptions = {
    animationEnabled: true,
    animationDuration: 2000,
    backgroundColor: '#0F0F0F',
    colorSet: 'dcolorSet',
    theme: 'dark1',
    height: 250,
    axisY: {
      includesZero: true,
    },
    subtitles: [
      {
        fontSize: 10,
      },
    ],
    data: [
      {
        type: 'doughnut',
        showInLegend: false,
        indexLabel: '{name}: {y}',
        indexLabelFontSize: 9,
        yValueFormatString: "(##.##'%)'",
        horizontalAlign: 'center',
        startAngle: -90,
        radius: '100%',
        innerRadius: '60%',
        dataPoints: dataPoints(),
      },
    ],
  };

  return (
    <div className="sales__volume__by__GC">
      <Listing
        data={weeklyInfo}
        show={show}
        setShow={(b) => { setShow(b) }}
        order={[
          { name: 'week_time', displayName: 'Weekly' },
          { name: 'sales_vol', displayName: 'Sales Volume' },
          { name: 'psa_perc', displayName: 'PSA Percentage', type: 'percentage' },
          { name: 'cgc_perc', displayName: 'CGC Percentage', type: 'percentage' },
          { name: 'bgs_perc', displayName: 'BGS Percentage', type: 'percentage' }
        ]}
      />
      <div className="svgc__box">
        <div className="svgc__heading__area">
          <div className="svgc__heading">
            Sales Volume Organized <br /> by Grading Companies
          </div>
          <span className="svgc__description">
            <p>Reveal the favourable choice in the hobby.</p>
            <p>(Only Includes BGS, CGC and PSA)</p>
          </span>
        </div>
        <div className="svgc__graph__area">
          <div className="svgc__chart">
            <CanvasJSChart options={windowWidth > 550 ? options : mobOptions} />
          </div>
        </div>
      </div>
      <div className="svgc__table__box">
        <div className="svgc__pie__graph">
          <div className="svgc__pie__graph__heading">
            <div>Past 90 Days Summary</div>
          </div>
          <div className="svgc__doughnut">
            <div className="svgc__dchart">
              <CanvasJSChart options={doptions} />
            </div>
            <div className="svgc__legend__section">
              <div className="svgc__legends__row">
                <div className="legend">
                  <div className="svgc__legend__r1"></div>
                  <div className="svgc__legend__r1__char">
                    Graded by PSA
                  </div>
                </div>
                <div className="legend">
                  <div className="svgc__legend__r2"></div>
                  <div className="svgc__legend__r2__char">
                    Graded by BGS
                  </div>
                </div>
                <div className="legend">
                  <div className="svgc__legend__r3"></div>
                  <div className="svgc__legend__r3__char">
                    Graded by CGC
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="svgc__weekly__info">
          <div className="svgc__weekly__info__heading">
            <div>Weekly Information</div>
          </div>
          <div className="svgc__weekly__info__header">
            <p className="svgc__wih__w">Weekly</p>
            <p className="svgc__wih__sv">Sales Volume</p>
            <p className="svgc__wih__psap">PSA Percentage</p>
            <p className="svgc__wih__cgcp">CGC Percentage</p>
            <p className="svgc__wih__bgsp">BGS Percentage</p>
          </div>
          <div className="svgc__wih__data">
            {weeklyInfo.slice(0, 5).map((item) =>
              <div className="svgc__data__row"
                   key={item._id}
              >
                <p className="svgc__data__w">{item.week_time}</p>
                <p className="svgc__data__sv">{item.sales_vol}</p>
                <p className="svgc__data__psap">
                  {item.psa_perc?.toFixed(2)}%
                </p>
                <p className="svgc__data__cgcp">
                  {item.cgc_perc?.toFixed(2)}%
                </p>
                <p className="svgc__data__bgsp">
                  {item.bgs_perc?.toFixed(2)}%
                </p>
              </div>
            )}
          </div>
          <div className="svgc__fl">
            <div className="svgc__fl__link"
                 onClick={() => { setShow(true) }}
            >
              Full List >>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
