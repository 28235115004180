import axios from 'axios';

const TCGFishApi = axios.create(
  {
    withCredentials: true,
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/api/`
  }
);

export default TCGFishApi;
