import React from 'react';
import { useNavigate } from 'react-router-dom';

// `link` should be a registered route within the app.
export const FeatureCard = ({ title, text, link }) => {
  const navigate = useNavigate();
  return (
    <div className={`flex flex-col flex-shrink-0 justify-between w-78 h-72.5 rounded-lg px-6 pt-6 pb-8 border-min border-purple-300 bg-purple-200 group hover:border-purple-500 ${link ? 'cursor-pointer' : ''}`}
         role={link ? 'link' : 'banner'}
         onClick={() => { link && navigate(link) }}
    >
      <div className="flex flex-shrink-0 items-start justify-center gap-8.5">
        <h3 className="w-47.5 flex-shrink-0 text-not-tonight-1000 text-5.5 font-medium">
          {title}
        </h3>
        <svg className="flex-shrink-0 w-10 h-10 group-hover:duration-100 group-hover:ease-out group-hover:-rotate-40"
             xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
          <mask id="mask0_346_305" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
            <rect x="8" y="8" width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_346_305)">
            <path
              d="M24.15 20.9375H13.1875C12.9292 20.9375 12.7083 20.8458 12.525 20.6625C12.3417 20.4792 12.25 20.2583 12.25 20C12.25 19.7417 12.3417 19.5208 12.525 19.3375C12.7083 19.1542 12.9292 19.0625 13.1875 19.0625H24.15L19.325 14.2375C19.1333 14.0458 19.0396 13.825 19.0438 13.575C19.0479 13.325 19.1458 13.1042 19.3375 12.9125C19.5292 12.7292 19.75 12.6354 20 12.6312C20.25 12.6271 20.4708 12.7208 20.6625 12.9125L27.0875 19.3375C27.1792 19.4292 27.2479 19.5312 27.2937 19.6437C27.3396 19.7562 27.3625 19.875 27.3625 20C27.3625 20.125 27.3396 20.2437 27.2937 20.3562C27.2479 20.4687 27.1792 20.5708 27.0875 20.6625L20.6625 27.0875C20.4792 27.2708 20.2604 27.3625 20.0062 27.3625C19.7521 27.3625 19.5292 27.2708 19.3375 27.0875C19.1458 26.8958 19.05 26.6729 19.05 26.4187C19.05 26.1646 19.1458 25.9417 19.3375 25.75L24.15 20.9375Z"
              fill="white"/>
          </g>
        </svg>
      </div>
      <p className="text-not-tonight-700 p7">
        {text}
      </p>
    </div>
  );
};
