import React from 'react';

export const Table = ({ headers, data, keyExtractor, emptyStateMessage, maxHeight = 1000 }) => {
  if (!data || data.length === 0) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-100 text-gray-500">
        {emptyStateMessage || 'No data available'}
      </div>
    );
  }

  return (
    <div className="overflow-y-auto modal-scroll" style={{ maxHeight: `${maxHeight}px` }}>
      <table className="w-full text-left border-collapse">
        <thead className='border-b border-not-tonight-300 bg-purple-100 sticky top-0 z-10'>
          <tr className="text-child-of-light-400 p8">
            {headers.map((header, index) => (
              <th
                key={index}
                style={{ width: header.width || 'auto' }}
                className={`pl-4 pr-2 py-3 ${header.headerClassName || ''}`}
              >
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, rowIndex) => (
            <tr key={keyExtractor(item, rowIndex)} className={`border-b border-not-tonight-300 ${rowIndex % 2 === 1 ? 'bg-not-tonight-200' : ''}`}>
              {headers.map((header, colIndex) => (
                <td
                  key={colIndex}
                  className={`pl-4 pr-2 py-5 ${header.cellClassName || ''} ${header.bodyClassName || ''}`}
                >
                  {header.render ? header.render(item) : item[header.key]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
