import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid';

export const CustomCheckbox = ({ id, label, checked, onChange }) => {
  return (
    <label className="flex items-center cursor-pointer"
           onClick={(evt) => { evt.stopPropagation(); evt.nativeEvent.stopImmediatePropagation(); } }
    >
      <input className="sr-only"
             type="checkbox"
             id={id}
             checked={checked}
             onChange={onChange}
      />
      <div className={`w-5 h-5 rounded-md flex justify-center items-center border-2 ${
             checked ? 'bg-purple-600 border-purple-600' : 'bg-transparent border-not-tonight-600'
           }`}
      >
        {checked &&
          <CheckIcon className="w-4 h-4 text-white" />
        }
      </div>
      <span className="ml-2 text-white p7">
        { label }
      </span>
    </label>
  );
};
