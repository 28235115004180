import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowUturnLeftIcon, ChevronDownIcon, ChevronUpIcon, PencilSquareIcon } from '@heroicons/react/20/solid';

import TCGFishApi from '../lib/TCGFishApi';
import logo from '../assets/images/tcglogo.png';
import { AuthContext } from '../contexts/AuthContext';
import { ModalsContext } from '../contexts/ModalsContext';
import { HeaderSearch } from './HeaderSearch';
import { LogInModal } from './LogInModal';
import { SignUpModal } from './SignUpModal';
import { ForgotPasswordModal } from './ForgotPasswordModal';
import { ChangePasswordModal } from './ChangePasswordModal';
import { SecondaryMediumButton } from './SecondaryMediumButton';
import { PrimaryMediumButton } from './PrimaryMediumButton';
import { EmailVerificationModal } from './EmailVerificationModal';

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { user, setUser } = useContext(AuthContext);
  const { modals, setModals } = useContext(ModalsContext);
  const [state, setState] = useState({
    showUserActions: false,
    showResearchDropdown: false
  });

  const userIconRef = useRef(null);
  const userActionsDropdownRef = useRef(null);
  const researchToolRef = useRef(null);
  const researchDropdownRef = useRef(null);

  const routeLinkClass = (currentRoute) => {
    if (currentRoute === 'research tool' && ['/market', '/setvalue', '/ranking'].includes(location.pathname)) {
      return 'text-white';
    }

    return currentRoute === location.pathname ? 'text-white' : 'text-not-tonight-900';
  };

  const handleHideLogInModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showLogInModal: false
      };
    });
  };

  const handleHideSignUpModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showSignUpModal: false
      };
    });
  };

  const handleHideForgotPasswordModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showForgotPasswordModal: false
      };
    });
  };

  const handleHideChangePasswordModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showChangePasswordModal: false
      };
    });
  };

  const handleShowLogInModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showLogInModal: true,
        showSignUpModal: false,
        showForgotPasswordModal: false,
        showChangePasswordModal: false
      };
    });
  };

  const handleShowSignUpModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showLogInModal: false,
        showSignUpModal: true,
        showForgotPasswordModal: false,
        showChangePasswordModal: false
      };
    });
  };

  const handleShowForgotPasswordModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showLogInModal: false,
        showSignUpModal: false,
        showForgotPasswordModal: true,
        showChangePasswordModal: false
      };
    });
  };

  const handleShowChangePasswordModalClick = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showLogInModal: false,
        showSignUpModal: false,
        showForgotPasswordModal: false,
        showChangePasswordModal: true
      };
    });
  };

  const handleUserIconClick = () => {
    setState((prevState) => {
      return {
        ...prevState,
        showUserActions: !prevState.showUserActions
      };
    });
  };

  const handleResearchToolClick = (evt) => {
    if (evt?.key === 'Tab') {
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        showResearchDropdown: !prevState.showResearchDropdown
      };
    });
  };

  const handleResearchDropdownLinkClick = () => {
    handleResearchToolClick();
  };

  const handleLogOutClick = () => {
    TCGFishApi
      .post('user/logout')
      .then((_res) => {
        setUser(undefined);
        setState((prevState) => {
          return {
            ...prevState,
            showUserActions: false // Prevent UserActions being open after logout and login.
          };
        });
        navigate('/');
      })
      .catch((err) => {
        console.error('Failed to log out.', err);
      });
  };

  useEffect(() => {
    if (!user) {
      return;
    }

    setModals((prevModals) => {
      return {
        ...prevModals,
        showLogInModal: false,
        showSignUpModal: false,
        showForgotPasswordModal: false,
        showChangePasswordModal: false
      };
    });
  }, [user]);

  useEffect(() => {
    function handleClickOutsideUserActions(evt) {
      if (!userActionsDropdownRef.current ||
        !userIconRef.current ||
        userActionsDropdownRef.current.contains(evt.target) ||
        userIconRef.current.contains(evt.target)
      ) {
        return;
      }

      setState((prevState) => {
        return {
          ...prevState,
          showUserActions: false
        };
      });
    }

    document.addEventListener('mousedown', handleClickOutsideUserActions);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideUserActions);
    };
  }, [userIconRef, userActionsDropdownRef]);

  useEffect(() => {
    function handleClickOutsideResearchDropdown(evt) {
      if (!researchDropdownRef.current ||
        !researchToolRef.current ||
        researchDropdownRef.current.contains(evt.target) ||
        researchToolRef.current.contains(evt.target)
      ) {
        return;
      }

      setState((prevState) => {
        return {
          ...prevState,
          showResearchDropdown: false
        };
      });
    }

    document.addEventListener('mousedown', handleClickOutsideResearchDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideResearchDropdown);
    };
  }, [researchToolRef, researchDropdownRef]);

  return (
    <header className="flex w-full h-24 px-15 py-6 items-center border-b-min border-b-not-tonight-200 box-border relative">
      <Link className="inline-block"
            to="/"
      >
        <img className="w-18 h-11.1"
             src={logo}
             alt="TCGFish logo"
        />
      </Link>
      <HeaderSearch />
      <nav className="relative">
        <Link className="ml-8 inline-block"
              to="/search"
        >
          <span className={`p7 ${routeLinkClass('/search')}`}>
            Catalog
          </span>
        </Link>
        <Link className="ml-5 inline-block"
              to="/auction-monitor"
        >
          <span className={`p7 ${routeLinkClass('/auction-monitor')}`}>
            Auction Monitor
          </span>
        </Link>
        <Link className="ml-5 inline-block"
              to="/undervalue-bot"
        >
          <span className={`p7 ${routeLinkClass('/undervalue-bot')}`}>
            Undervalue Bot
          </span>
        </Link>
        <Link className="ml-5 inline-block"
              to="/market-index"
        >
          <span className={`p7 ${routeLinkClass('/market-index')}`}>
            Market Index
          </span>
        </Link>
        <div className={`ml-5 inline-block p7 cursor-pointer ${routeLinkClass('research tool')} ${state.showResearchDropdown && 'text-white'}`}
             onClick={handleResearchToolClick}
             onKeyDown={handleResearchToolClick}
             ref={researchToolRef}
             tabIndex="0"
        >
          Research Tool
          {state.showResearchDropdown ?
            <ChevronUpIcon className="inline-block w-5 h-5 -mt-1 text-white" />
          :
            <ChevronDownIcon className="inline-block w-5 h-5 -mt-1 text-white" />
          }
          {state.showResearchDropdown &&
            <div className="flex flex-col flex-shrink-0 justify-center items-start w-50 h-36 absolute right-0 z-40 py-3 gap-1.5 rounded-lg bg-purple-100 p7 text-not-tonight-1000 header_research-dropdown"
                 ref={researchDropdownRef}
            >
              <Link className="flex flex-shrink-0 items-center w-50 h-9 px-4 gap-2"
                    onClick={handleResearchDropdownLinkClick}
                    onKeyDown={(evt) => { evt.key === 'Enter' && navigate('/market') }}
                    to="/market"
              >
                Market Research Tool
              </Link>
              <Link className="flex flex-shrink-0 items-center h-9 px-4 gap-2"
                    onClick={handleResearchDropdownLinkClick}
                    onKeyDown={(evt) => { evt.key === 'Enter' && navigate('/setvalue') }}
                    to="/setvalue"
              >
                Master Set Value
              </Link>
              <Link className="flex flex-shrink-0 items-center h-9 px-4 gap-2"
                    onClick={handleResearchDropdownLinkClick}
                    onKeyDown={(evt) => { evt.key === 'Enter' && navigate('/ranking') }}
                    to="/ranking"
              >
                Market Cap Ranking
              </Link>
            </div>
          }
        </div>
      </nav>
      <div className="flex justify-center items-center gap-4 h-full absolute right-0 p-inherit">
        {!user &&
          <>
            <SecondaryMediumButton text="Log in"
                                   onClick={handleShowLogInModalClick}
            />
            <PrimaryMediumButton text="Sign up"
                                 onClick={handleShowSignUpModalClick}
            />
          </>
        }
        {user &&
          <>
            <div className="flex w-18 gap-1 justify-center items-center text-white p3"
                 ref={userIconRef}
                 onClick={handleUserIconClick}
            >
              <div className="flex w-12 h-12 bg-user-icon rounded-full justify-center items-center">
                {user.char}
              </div>
              {/* TODO: find corresponding heroicons */}
              {!state.showUserActions &&
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.2762 8.65128C6.6345 8.30676 7.20424 8.31793 7.54875 8.67623L12 13.4015L16.4513 8.67623C16.7958 8.31793 17.3655 8.30676 17.7238 8.65128C18.0821 8.99579 18.0933 9.56553 17.7487 9.92382L12.6488 15.3238C12.4791 15.5003 12.2448 15.6 12 15.6C11.7552 15.6 11.5209 15.5003 11.3513 15.3238L6.25125 9.92382C5.90674 9.56553 5.91791 8.99579 6.2762 8.65128Z" fill="white"/>
                </svg>
              }
              {state.showUserActions &&
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M6.2762 15.3487C6.6345 15.6932 7.20424 15.6821 7.54875 15.3238L12 10.5985L16.4513 15.3238C16.7958 15.6821 17.3655 15.6932 17.7238 15.3487C18.0821 15.0042 18.0933 14.4345 17.7487 14.0762L12.6488 8.67618C12.4791 8.49971 12.2448 8.39997 12 8.39997C11.7552 8.39997 11.5209 8.49971 11.3513 8.67618L6.25125 14.0762C5.90674 14.4345 5.91791 15.0042 6.2762 15.3487Z" fill="white"/>
                </svg>
              }
            </div>
            {state.showUserActions &&
              <div className="w-65 pt-4 pb-2 absolute top-19 right-15 z-40 bg-purple-100 dropdown-box-shadow"
                   ref={userActionsDropdownRef}
              >
                <div className="inline-flex flex-col items-start mx-4 gap-0.5">
                  <span className="p3 text-child-of-light-1000">
                    {user.fullName}
                  </span>
                  <span className="p4 text-not-tonight-700">
                    {user.email}
                  </span>
                </div>
                <div className="h-9 flex flex-shrink-0 items-center mt-5.1 ml-1.5 pl-2.5 mr-1 gap-2 text-white cursor-pointer rounded-lg hover:bg-not-tonight-200"
                     onClick={handleShowChangePasswordModalClick}
                >
                  <PencilSquareIcon className="w-5 h-5" />
                  <span className="p7">
                    Change password
                  </span>
                </div>
                <hr className="mt-4 border-not-tonight-200 border-min" />
                <div className="h-9 flex flex-shrink-0 items-center mt-2 ml-1.5 pl-2.5 mr-1 gap-2 text-white cursor-pointer rounded-lg hover:bg-not-tonight-200"
                     onClick={handleLogOutClick}
                >
                  <ArrowUturnLeftIcon className="w-5 h-5" />
                  <span className="p7">
                    Logout
                  </span>
                </div>
              </div>
            }
          </>
        }
      </div>
      {modals.showLogInModal &&
        <LogInModal close={handleHideLogInModalClick}
                    showSignUpModal={handleShowSignUpModalClick}
                    showForgotPasswordModal={handleShowForgotPasswordModalClick}
        />
      }
      {modals.showSignUpModal &&
        <SignUpModal close={handleHideSignUpModalClick}
                     showLogInModal={handleShowLogInModalClick}
        />
      }
      {modals.showForgotPasswordModal &&
        <ForgotPasswordModal close={handleHideForgotPasswordModalClick}
                             showLogInModal={handleShowLogInModalClick}
        />
      }
      {modals.showChangePasswordModal &&
        <ChangePasswordModal close={handleHideChangePasswordModalClick}
                             showLogInModal={handleShowLogInModalClick}
        />
      }
      {modals.showEmailVerificationModal &&
        <EmailVerificationModal />
      }
    </header>
  );
};
