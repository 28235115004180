import React from 'react';
import { useNavigate } from 'react-router-dom';

import './BreadCrumb.css';

export default function BreadCrumb() {
    const navigate = useNavigate();
    const [BC, setBC] = React.useState(null);
    const [Comp, setComp] = React.useState(null);

    React.useEffect(() => {
        setBC(sessionStorage.getItem("bread"))
    }, [sessionStorage.getItem("bread")])

    React.useEffect(() => {
        if (window.location.pathname === "/search") {
            setComp(<span>Search</span>);
        }
        else if (window.location.pathname === '/market') {
            setComp(<span>Market Research Tool</span>);
        }
        else if (window.location.pathname === "/auction-monitor") {
            setComp(<span>Auction Monitor</span>);
        }
        else if (window.location.pathname === "/undervalue-bot") {
            setComp(<span>Undervalue Bot</span>);
        }
        // else if (window.location.pathname === "/market-trend") {
        //     setComp(<span>Market Trend</span>);
        // }
        else if (window.location.pathname === "/setvalue") {
            setComp(<span>Master Set Value</span>);
        }
        else if (window.location.pathname === "/ranking") {
            setComp(<span>Market Cap Ranking</span>);
        }
        else {
            if (BC === "shop") {
                if (sessionStorage.getItem("searchLink")) {
                    setComp(<>
                        <span onClick={() => { navigate(sessionStorage.getItem('searchLink')) }}>Search</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                } else {
                    setComp(<>
                        <span onClick={() => { navigate('/search') }}>Search</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                }
            } else if (BC === "home") {
                setComp(<>
                    <span>Product</span>
                </>);
            }
            else if (BC === "setvalue") {
                setComp(<>
                    <span onClick={() => { navigate('/setvalue') }}>Master Set Value</span>
                    <span>{">"}</span>
                    <span>Product</span>
                </>);
            }
            // else if (BC === "trend") {
            //     setComp(<>
            //         <span onClick={() => { navigate('/market-trend') }}>Market Trend</span>
            //         <span>{">"}</span>
            //         <span>Product</span>
            //     </>);
            // }
            else if (BC === "ranking") {
                if (sessionStorage.getItem("searchLink")) {
                    setComp(<>
                        <span onClick={() => { navigate(sessionStorage.getItem('searchLink')) }}>Market Cap Ranking</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                } else {
                    setComp(<>
                        <span onClick={() => { navigate('/ranking') }}>Market Cap Ranking</span>
                        <span>{">"}</span>
                        <span>Product</span>
                    </>);
                }
            }
            else {
                setComp(<>
                    <span>Product</span>
                </>);
            }
        }
    }, [BC])

    return (
        <div className="BreadCrumb">
            <div className="BreadCrumb__Text"
                 onClick={() => { navigate('/') }}
            >
                Home
            </div>
            <div className="BreadCrumb__Text">
                {Comp && '>'}
            </div>
            <div className="BreadCrumb__Text">
                {Comp}
            </div>
        </div>
    )
}
