import React from 'react';

export const LoadingSpinner = ({ className }) => {
  return (
    <div className={`min-w-100 min-h-100 w-100 h-100 ${className}`}>
      <div className="preloader">
        <div className="preloader">
          <div className="preloader">
            <div className="preloader">
              <div className="preloader"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
