import React, { useEffect, useState } from 'react';
import './PremiumSales.css';

import Listing from '../../../Listing/Listing';
import TCGFishApi from '../../../../lib/TCGFishApi';
import { normalizeGrade } from '../../../../lib/helpers';

export default function PremiumSales({ windowWidth }) {
  const [show, setShow] = useState(false);
  const [premiumSales, setPremiumSales] = useState([]);

  useEffect(() => {
    TCGFishApi
      .get('market/getms6premium')
      .then((res) => {
        const temp = res.data.count;
        for (let j = 0; j < temp.length; j++) {
          temp[j].date = temp[j].date
            .slice(0, 10)
            .split('-')
            .reverse()
            .join('-');
          if (temp[j].grade) {
            temp[j].grade = temp[j].grade.replace('.0', '');
          }

          setPremiumSales(temp);
        }
      })
      .catch((err) => {
        console.error('Failed to get market/getms6premium', err);
      });
  }, []);

  return (
    <div className="premium__sales">
      <Listing
        data={premiumSales}
        show={show}
        setShow={(b) => { setShow(b) }}
        order={[
          { name: 'name', displayName: 'Card Name' },
          { name: 'set_name', displayName: 'Set Name' },
          { name: 'date', displayName: 'Sold Date' },
          { name: 'grade', displayName: 'Grade' },
          { name: 'sold', displayName: 'Sold Price' },
          { name: 'Language', displayName: 'Language' }
        ]}
      />
      <div className="prsa__box">
        <div className="prsa__heading__area">
          <div className="prsa__heading">Premium Sales</div>
          <span className="prsa__description">
            <p>Uncover the high-end sales in the past 90 days.</p>
            <p>(Minimum sold price > $2,000.)</p>
          </span>
        </div>
        <div className="prsa__info__box">
          <div className="prsa__info__box__header">
            <p className="prsa__cn">Card Name</p>
            <p className="prsa__sn">Set Name</p>
            <p className="prsa__sd">Sold Date</p>
            <p className="prsa__g">Grade</p>
            <p className="prsa__sp">Sold Price</p>
            <p className="prsa__l">Language</p>
          </div>

          <div className="prsa__data">
            {premiumSales.slice(0, windowWidth > 550 ? 8 : 5).map((item) =>
              <div className="prsa__data__row"
                   key={item._id}
              >
                <p className="prsa__data__cn">{item.name}</p>
                <p className="prsa__data__sn">{item.set_name}</p>
                <p className="prsa__data__sd">{item.date}</p>
                <p className="prsa__data__g">{normalizeGrade(item.grade)}</p>
                <p className="prsa__data__sp">{item.sold}</p>
                <p className="prsa__data__l">{item.Language}</p>
              </div>
            )}
          </div>
        </div>
        <div className="prsa__fl">
          <div className="prsa__fl__link"
               onClick={() => { setShow(true) }}
          >
            Full List >>
          </div>
        </div>
      </div>
    </div>
  );
}
