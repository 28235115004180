import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useCopyToClipboard } from 'usehooks-ts';
import { DocumentDuplicateIcon, ShareIcon } from '@heroicons/react/20/solid';

import MetadataTags from '../components/MetadataTags/MetadataTags';
import empty from '../assets/images/empty.webp';
import twitterLogo from '../assets/icons/twitterLogo.png';
import { CardAnalysis } from '../components/CardAnalysis';
import { CardSalesChart } from '../components/CardSalesChart';
import { Statistics } from '../components/Statistic';
import { Snapshot } from '../components/Snapshot';
import { SalesTable } from '../components/SalesTable';
import { SecondaryMediumButton } from '../components/SecondaryMediumButton';
import { SmallTextButton } from '../components/SmallTextButton';
import TCGFishApi from '../lib/TCGFishApi';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS
} from '../lib/constants';

export const Card = () => {
  const [_value, copy] = useCopyToClipboard();
  const shareDropdownRef = useRef(null);
  const shareButtonRef = useRef(null);

  const search = useLocation().search;
  const searchParams = useMemo(() => { return new URLSearchParams(search) }, [search]);

  const [state, setState] = useState({
    showShareDropdown: false
  });

  const [productID, setProductID] = useState(searchParams.get('id') || '');
  const [cardID, setCardID] = useState(searchParams.get('cardNumber') || '');
  const [versionNumber, setVersionNumber] = useState(0);
  const [card, setCard] = useState();
  const [lastUpdated, setLastUpdated] = useState(null);
  const [company, setCompany] = useState('PSA');
  const [grades, setGrades] = useState(undefined);
  const [salesSnapshot, setSalesSnapshot] = useState();
  const [statistics, setStatistics] = useState({});
  const [salesDistribution, setSalesDistribution] = useState(
    {
      psa: {
        percent: 0,
        value: 0
      },
      bgs: {
        percent: 0,
        value: 0
      },
      cgc: {
        percent: 0,
        value: 0
      }
    }
  );
  const [cardPopulation, setCardPopulation] = useState(undefined);
  const [subCategory, setSubCategory] = useState();

  const handleShareClick = () => {
    setState((prevState) => {
      return {
        ...prevState,
        showShareDropdown: !prevState.showShareDropdown
      };
    });
  };

  const handleCopyLinkClick = () => {
    copy(window.location.href);
    handleShareClick();
  };

  const pageTitle = useMemo(() => {
    return `${card?.name || 'Card'}`.concat(
      ` ${card?.releaseYear || ''}`,
      ` ${card?.set_name || ''}`,
      ` ${(card?.card_number && card.card_number !== 'Missing') ? `#${card.card_number}` : ''}`,
      ` ${card?.language ? `(${card.language})` : ''}`,
      ` - ${DEFAULT_PAGE_TITLE}`
    );
  }, [card]);

  const pageDescription = useMemo(() => {
    return `${card?.name || 'Card'}`.concat(
      ` ${card?.releaseYear || ''}`,
      ` ${card?.set_name || ''}`,
      ` ${(card?.card_number && card.card_number !== 'Missing') ? `#${card.card_number}` : ''}`,
      ` ${card?.language ? `(${card.language})` : ''}`,
      ` - ${DEFAULT_PAGE_DESCRIPTION}`
    );
  }, [card]);

  const pageKeywords = useMemo(() => {
    return `${card?.name || 'Card'}`.concat(
      ` ${card?.releaseYear || ''}`,
      ` ${card?.language ? `(${card.language}),` : ''}`,
      ` ${DEFAULT_PAGE_KEYWORDS}`
    );
  }, [card]);

  useEffect(() => {
    setProductID(searchParams.get('id') || '');
    setCardID(searchParams.get('cardNumber') || '');
  }, [searchParams]);

  useEffect(() => {
    if (!productID) {
      return;
    }

    TCGFishApi
      .get(
        'cards/card', { params: { id: productID } }
      )
      .then((res) => {
          setCard(res.data.data);
      })
      .catch((err) => {
        console.error('Failed to get cards/card', err);
      });
  }, [productID]);

  useEffect(() => {
    TCGFishApi
      .get('sales/cardSales', { params: { card_id: cardID } })
      .then((res) => {
        setLastUpdated(
          new Date(res.data.lastModified)
            .toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })
        );
        setSalesSnapshot(res.data.snapshot);
        setSalesDistribution(res.data.distribution);
        setStatistics(res.data.statistics);
      })
      .catch((err) => {
        console.error('Failed to get sales/cardSales', err);
      });

    TCGFishApi
      .get('population/cardPopulation', { params: { card_id: cardID } })
      .then((res) => {
        setCardPopulation(res.data.data);
      })
      .catch((err) => {
        setCardPopulation({});
        console.error('Failed to get population/cardPopulation', err);
      });
  }, [cardID]);

  useEffect(() => {
    if (!card?.sub_cat) {
      return;
    }

    card.sub_cat.forEach((item, index) => {
      if (item.card_id === cardID) {
        setSubCategory(item.sub_category);
        setVersionNumber(index);
      }
    })
  }, [card, cardID]);

  useEffect(() => {
    function handleClickOutsideShareDropdown(evt) {
      if (!shareDropdownRef.current ||
        !shareButtonRef.current ||
        shareDropdownRef.current.contains(evt.target) ||
        shareButtonRef.current.contains(evt.target)
      ) {
        return;
      }

      setState((prevState) => {
        return {
          ...prevState,
          showShareDropdown: false
        };
      });
    }

    document.addEventListener('mousedown', handleClickOutsideShareDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideShareDropdown);
    };
  }, [shareButtonRef, shareDropdownRef]);

  if (!card || !cardPopulation) {
    return (
      <p>
        Loading...
      </p>
    );
  }

  return (
    <main className="w-full flex flex-col px-15 pt-14 pb-6">
      <MetadataTags title={pageTitle}
                    description={pageDescription}
                    keywords={pageKeywords}
                    image={card ? card.sub_cat?.[versionNumber]?.image : null}
      />
      <div className="flex justify-between">
        <h1 className="text-child-of-light-1000 h4">
          {card.name || 'Card'}
        </h1>
        <span className="text-not-tonight-600 p7">
          {lastUpdated &&
            `Latest Update: ${lastUpdated !== 'Invalid Date' ? lastUpdated : 'No data'}`
          }
        </span>
      </div>
      <div className="flex justify-between mb-12 relative">
        <h2 className="text-child-of-light-1000 h6">
          { `${card?.set_name || 'No set'}, ${card.card_number === 'Missing' ? '' : `#${card.card_number}`}${card.language ? ` (${card.language})` : ''}` }
        </h2>
        <SecondaryMediumButton text="Share"
                               Icon={ShareIcon}
                               onClick={handleShareClick}
                               innerRef={shareButtonRef}
        >
        </SecondaryMediumButton>
        {state.showShareDropdown &&
          <div className="w-60 absolute right-0 top-12 flex-shrink-0 rounded-lg bg-purple-100 text-not-tonight-1000 card_share-dropdown"
               ref={shareDropdownRef}
          >
            <span className="mt-3 ml-3 p7">
              Share link to the page
            </span>
            <SmallTextButton className="ml-2 mt-1 mb-3"
                             text="Copy Link"
                             Icon={DocumentDuplicateIcon}
                             onClick={handleCopyLinkClick}
            />
            <hr className="w-full mb-3 border-min flex-shrink-0 border-not-tonight-200"/>
            <div className="flex flex-shrink-0 w-full h-9 mb-1.5 px-2 items-center p7">
              <a
                className="fb-xfbml-parse-ignore flex items-center w-full h-full px-2 gap-2 cursor-pointer hover:bg-not-tonight-200 hover:rounded-lg"
                href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}&src=sdkpreparse`}
                target="_blank" rel="noreferrer"
              >
                <svg className="w-6 h-6 flex-shrink-0"
                     xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_820_435)">
                    <path
                      d="M20.4 0H3.6C1.61177 0 0 1.61177 0 3.6V20.4C0 22.3882 1.61177 24 3.6 24H20.4C22.3882 24 24 22.3882 24 20.4V3.6C24 1.61177 22.3882 0 20.4 0Z"
                      fill="#1877F2"/>
                    <path
                      d="M13.4531 21.375V7.35938C13.4531 6.375 13.7344 5.71875 15.0938 5.71875H16.875V2.76562C16.5469 2.71875 15.5156 2.625 14.2969 2.625C11.7656 2.625 10.0312 4.17188 10.0312 7.03125V21.375M16.7344 9.46875H7.125V12.8438H16.3125"
                      fill="white"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_820_435">
                      <rect width="24" height="24" fill="white"/>
                    </clipPath>
                  </defs>
                </svg>
                Facebook
              </a>
            </div>
            <div className="flex flex-shrink-0 w-full h-9 mb-4 px-2 items-center p7">
              <a
                className="flex items-center w-full h-full px-2 gap-2 cursor-pointer hover:bg-not-tonight-200 hover:rounded-lg"
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${pageTitle}`}
                target="_blank" rel="noreferrer"
              >
                <img className="w-6 h-6 justify-center items-center flex-shrink-0"
                     src={twitterLogo}
                     alt="Twitter logo"
                />
                X (Twitter)
              </a>
            </div>
          </div>
        }
      </div>
      <div className="inline-flex w-full items-center mb-10 text-not-tonight-800">
        {card.sub_cat && card.sub_cat.map((sub_cat) => (
          <Link
            className={`inline-flex px-6 py-2 justify-center items-center gap-2.5 cursor-pointer rounded-4xl ${sub_cat.card_id === cardID ? 'bg-purple-300 text-white' : ''}`}
            key={sub_cat.card_id}
            to={`/product?id=${card._id}&cardNumber=${sub_cat.card_id}`}
            disabled={sub_cat.card_id === cardID}
          >
            {sub_cat.sub_category}
          </Link>
        ))}
      </div>
      <div className="inline-flex w-full items-start gap-6 mb-14">
        <div className="min-w-78 w-78 h-136 bg-no-repeat rounded-lg relative"
             style={{background: `url('${card.sub_cat?.[versionNumber]?.image}'), lightgray -42.545px 0px / 127.273% 102.056% no-repeat`}}>
          <div
            className="flex flex-col justify-center items-center w-full h-full flex-shrink-0 bg-darkgrey bg-opacity-40 backdrop-blur-5 rounded-lg">
            <div
              className="flex absolute top-0 left-0 w-full p-2 justify-center items-center gap-1 bg-not-tonight-1000 bg-opacity-70 rounded-t-2 text-child-of-light-100">
              <span className="p8">
                Version:
              </span>
              <span className="p8 font-medium">
                {card.sub_cat && card.sub_cat.find(e => e.card_id === cardID)?.sub_category}
              </span>
            </div>
            <img className="w-68 h-92"
                 src={card.sub_cat?.[versionNumber]?.image}
                 onError={(evt) => {
                   evt.target.src = empty
                 }}
                 alt={`${card.name ? card.name : 'Card'} image`}
            />
          </div>
        </div>
        <CardAnalysis cardID={cardID}
                      distribution={salesDistribution}
                      population={cardPopulation}
        />
      </div>
      <CardSalesChart cardID={cardID}
                      company={company}
                      setCompany={setCompany}
                      onChartGradesChange={setGrades}
                      salesGrades={grades}
      />
      <div className="flex flex-row justify-between">
        {statistics &&
          <Statistics statistics={statistics} />
        }
        {salesSnapshot &&
          <Snapshot salesSnapshot={salesSnapshot} />
        }
      </div>
    </main>
  );
};
