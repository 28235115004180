import React from 'react';
import './PrivacyPolicy.css';
import { default as Policy } from '../../components/PrivacyPolicy/PrivacyPolicy';
import MetadataTags from '../../components/MetadataTags/MetadataTags';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS
} from '../../lib/constants';

export default function PrivacyPolicy() {
  const pageTitle = `Privacy Policy - ${DEFAULT_PAGE_TITLE}`;
  const pageDescription = `Privacy Policy - ${DEFAULT_PAGE_DESCRIPTION}`;
  const pageKeywords = `Privacy Policy, ${DEFAULT_PAGE_KEYWORDS}`;

  return (
    <div className="container">
      <MetadataTags title={ pageTitle }
                    description={ pageDescription }
                    keywords={ pageKeywords }
      />

      <div className="privacy_policy__page_content">
        <Policy />
      </div>
    </div>
  );
}
