import React, { useEffect, useState } from 'react';

import './Export.css';
import CheckIcon from '@mui/icons-material/Check';

export const Export = ({ allSales, onCloseExportDataModalClick }) => {
  const [company, setCompany] = useState('PSA');
  const [time, setTime] = useState('ALL');
  const [fileFormat, setFileFormat] = useState('Excel');
  const [cSales, setCSales] = useState();
  const [avgSales, setAvgSales] = useState([]);
  const [timeRangeSales, setTimeRangeSales] = useState([]);
  const [grades, setGrades] = useState([10, 9, 8]);
  const [gradesAvailable, setGradesAvailable] = useState([]);
  const [refresh, setRefresh] = useState(true);
  const [recalculate, setRecalculate] = useState(true);
  const [exportData, setExportData] = useState();
  const [allGrades, setAllGrades] = useState(null);

  const halfGrades = [];
  for (let i = 9.5; i >= 1; i = i - 1) {
    halfGrades.push(i);
  }

  function getFromAll() {
    setCSales(() => allSales.filter((item) => item.company === company));
  }

  function GetTimeRangeSales() {
    let temp = [];
    if (time === 'ALL') {
      temp = cSales;
    } else {
      let timespan;
      if (time === '3M') timespan = 92;
      else if (time === '6M') timespan = 183;
      else if (time === '1Y') timespan = 365;
      else if (time === '2Y') timespan = 730;

      let current = new Date();
      current.setDate(current.getDate() - timespan);
      let date;
      let month = "";
      let days = "";
      if (current.getMonth() < 9) {
        month = '0' + (current.getMonth() + 1);
      } else {
        month = current.getMonth() + 1;
      }
      if (current.getDate() < 10) {
        days = '0' + current.getDate();
      } else {
        days = current.getDate();
      }
      let year = current.getFullYear().toString();
      date = `${days}-${month}-${year}`;
      temp = cSales.filter((item) => item.date.split('-').reverse().join('') > date.split('-').reverse().join(''));
    }
    if (temp.length === 0) {
      setTimeRangeSales([]);
    } else {
      setTimeRangeSales(temp);
    }
  }

  function GetAvgSales() {
    let inArr = timeRangeSales
    let temp = [];
    let prev = null;
    let sum = 0;
    let count = 0;
    inArr.map((item) => {
      if (prev === null) {
        prev = item;
        count = 1;
        sum = item.sold;
      } else {
        if (prev.date === item.date && prev.grade === item.grade) {
          sum = sum + item.sold;
          count = count + 1;
        } else {
          temp.push({
            company: prev.company,
            date: prev.date,
            grade: prev.grade,
            serial_num: prev.serial_num,
            sold: sum / count,
            _id: prev._id
          });
          prev = item;
          sum = item.sold;
          count = 1;
          if (inArr.indexOf(item) === inArr.length - 1) {
            temp.push(prev);
          }
        }
      }
    });

    if (temp.length) {
      setAvgSales(temp);
    } else {
      setAvgSales([]);
    }
  }

  function getAvailable() {
    let temp = [];
    timeRangeSales.map((item) => {
      if (temp.indexOf(item.grade) === -1) {
        temp.push(item.grade);
      }
    });

    if (recalculate) {
      setRecalculate(false);
      if (temp.length > 4) {
        setGrades(temp.sort((a, b) => {
          return b - a
        }).slice(0, 4));
      } else {
        setGrades(temp.sort((a, b) => {
          return b - a;
        }).slice(0, temp.length));
      }
    }
    setGradesAvailable(temp);
  }

  function getTimeData() {
    if (cSales?.length) {
      GetTimeRangeSales();
    } else {
      setTimeRangeSales([]);
    }
  }

  function getAvgData() {
    if (timeRangeSales !== undefined && timeRangeSales.length > 1) {
      GetAvgSales();
      getAvailable();
    } else {
      setAvgSales([]);
      setGradesAvailable([]);
    }
  }

  useEffect(() => {
    if (allGrades === true) {
      setGrades(gradesAvailable);
    } else if (allGrades === false) {
      setGrades([10, 9, 8]);
      setRecalculate(true);
      getAvailable();
      setRefresh(!refresh);
    }
  }, [allGrades]);

  useEffect(() => {
    getFromAll();
  }, [allSales, company]);

  useEffect(() => {
    getTimeData();
  }, [cSales, time]);

  useEffect(() => {
    getAvgData();
  }, [timeRangeSales, refresh]);

  useEffect(() => {
    setExportData(grades.map((item) => {
      return avgSales.filter((t) => t.grade === item);
    }));
  }, [time, company, grades, fileFormat, avgSales]);

  function handleRadioButton(num) {
    let gone = false;
    let temp = grades;
    for (let i = temp.length - 1; i >= 0; i--) {
      if (temp[i] === num) {
        temp.splice(i, 1);
        gone = true;
      }
    }
    if (gone === false) {
      temp.push(num);
    }

    setGrades(temp);
    setRefresh(!refresh);
  }

  const timeRange = [
    {id: 'ALL', title: 'All'},
    {id: '2Y', title: '2 Years'},
    {id: '1Y', title: '1 Year'},
    {id: '6M', title: '6 Months'},
    {id: '3M', title: '3 Months'}
  ];
  const companies = ['PSA', 'BGS', 'CGC'];
  const exportFormats = ['Excel', 'CSV'];

  const ToggleButtons = (props) => {
    if (props.type === timeRange) {
      return (
        <div className="flex">
          {props.type.map((type) =>
            <div className="inline-flex items-center mr-6 cursor-pointer"
                 onClick={() => setTime(type.id)}
            >
              <div className={`flex items-center justify-center w-5 h-5 border-min border-solid ${time === type.id ? "border-purple-600" : "border-not-tonight-600"} rounded-full`}>
                {time === type.id &&
                  <div className="bg-purple-600 w-3 h-3 rounded-full">
                  </div>
                }
              </div>
              <span className="ml-2 p4 text-not-tonight-1000">
                {type.title}
              </span>
            </div>
          )}
        </div>
      );
    }

    if (props.type === companies) {
      return (
        <div className="flex">
          {props.type.map((type) =>
            <div className="inline-flex items-center mr-6 cursor-pointer"
                 onClick={() => {
                   setRecalculate(true);
                   setCompany(type);
                 }}
            >
              <div className={`flex items-center justify-center w-5 h-5 border-min border-solid ${company === type ? "border-purple-600" : "border-not-tonight-600"} rounded-full`}>
                {company === type &&
                  <div className="bg-purple-600 w-3 h-3 rounded-full">
                  </div>
                }
              </div>
              <span className="ml-2 p4 text-not-tonight-1000">
                {type}
              </span>
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="flex">
        {props.type.map((type) =>
          <div className="inline-flex items-center mr-6 cursor-pointer"
               onClick={() => {
                 setFileFormat(type)
               }}
          >
            <div className={`flex items-center justify-center w-5 h-5 border-min border-solid rounded-full ${fileFormat === type ? 'border-purple-600' : 'border-not-tonight-600'}`}>
              {fileFormat === type &&
                <div className="bg-purple-600 w-3 h-3 rounded-full">
                </div>
              }
            </div>
            <span className="ml-2 p4 text-not-tonight-1000">
              {type}
            </span>
          </div>
        )}
      </div>
    )
  };

  const gradeButton = (num) => {
    if (gradesAvailable.indexOf(num) !== -1 || num === 'ALL') {
      let position = grades.indexOf(num);
      if (position === -1 || num === 'ALL') {
        if (allGrades) {
          return (
            <div className="inline-flex items-center mr-6 cursor-pointer"
                 onClick={() => {
                   if (num === 'ALL') {
                     setAllGrades(false);
                     handleRadioButton(num);
                   } else {
                     handleRadioButton(num);
                   }
                 }}
            >
              <div className="flex items-center justify-center w-5 h-5 border-min border-solid border-purple-600 rounded-full">
                <div className="bg-purple-600 w-3 h-3 rounded-full">
                </div>
              </div>
              <span className="ml-2 p4 text-not-tonight-1000">
                {num}
              </span>
            </div>
          );
        } else {
          return (
            <div className="inline-flex items-center mr-6 cursor-pointer"
                 onClick={() => {
                   if (num === "ALL") {
                     setAllGrades(true);
                     handleRadioButton(num);
                   } else {
                     handleRadioButton(num);
                   }
                 }}
            >
              <div className="flex items-center justify-center w-5 h-5 border-min border-solid border-not-tonight-600 rounded-full">
              </div>
              <span className="ml-2 p4 text-not-tonight-1000">
                {num}
              </span>
            </div>
          );
        }
      } else {
        return (
          <div className="flex items-center cursor-pointer"
               onClick={() => {
                 if (num === "ALL") {
                   setAllGrades(false);
                   handleRadioButton(num);
                 } else {
                   handleRadioButton(num);
                 }
               }}
          >
            <div className="bg-purple-600 w-5 h-5 rounded mr-3 flex items-center justify-between text-not-tonight-1000">
              <CheckIcon sx={{fontSize: '16px', marginLeft: '2px'}}/>
            </div>
            <p className="p7 text-not-tonight-1000">
              {num}
            </p>
          </div>
        );
      }
    } else {
      return (
        <div className="flex items-center cursor-not-allowed opacity-50">
          <div className="border-solid border-min border-not-tonight-600 w-5 h-5 rounded mr-3 flex items-center justify-between text-not-tonight-1000">
          </div>
          <p className="p7 text-not-tonight-1000">
            {num}
          </p>
        </div>
      );
    }
  };

  return (
    <div>
      <p className="p1 text-not-tonight-1000 mb-11">
        Export Data
      </p>
      <div className="mb-10">
        <p className="p3 text-not-tonight-1000 mb-4 pb-2 border-b border-solid border-not-tonight-200">
          Time Frame
        </p>
        <ToggleButtons type={timeRange}
                       default={4}
                       theme="gold"
        />
      </div>
      <div className="mb-10">
        <p className="p3 text-not-tonight-1000 mb-4 pb-2 border-b border-solid border-not-tonight-200">
          Grading Company
        </p>
        <ToggleButtons type={companies}
                       default={0}
                       theme="gold"
        />
      </div>
      <div className="mb-10">
        <p className="p3 text-not-tonight-1000 mb-4 pb-2 border-b border-solid border-not-tonight-200">
          Grade
        </p>
        <div className="grid grid-cols-10 gap-5">
          <div className="col-span-1">
            {gradeButton('ALL')}
          </div>
          <div className="col-span-1">
            {gradeButton(10)}
          </div>
          {halfGrades.map((item) => {
            return [
              <>
                <div className="col-span-1">
                  {gradeButton(item - 0.5)}
                </div>
                <div className="col-span-1">
                  {gradeButton(item)}
                </div>
              </>
            ];
          })}
        </div>
      </div>
      <div className="mb-10">
        <p className="p3 text-not-tonight-1000 mb-4 pb-2 border-b border-solid border-not-tonight-200">
          File Format
        </p>
        <ToggleButtons type={exportFormats}
                       default={0}
                       theme="gold"
        />
      </div>
      <div>
        {exportData ?
          <div>
            <p className="p3 text-dusk-orange-600 text-right mb-3">
              This feature is still under development.
            </p>
            <div className="flex justify-end">
              <button className="text-white p4 py-3 px-14 rounded bg-not-tonight-200 mr-5"
                      onClick={onCloseExportDataModalClick}>
                Cancel
              </button>
              <button className="text-white p4 py-3 px-6 rounded bg-purple-600 opacity-50 cursor-not-allowed"
                      disabled>
                Download Data
              </button>
            </div>
          </div>
        :
          <div className="export__btn__loading">Waiting for Data</div>
        }
      </div>
    </div>
  );
};
