import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { InformationCircleIcon, DocumentTextIcon } from '@heroicons/react/20/solid';

import MetadataTags from '../components/MetadataTags/MetadataTags';
import { MarketIndexModal } from '../components/MarketIndexModal';
import { PMIChart } from '../components/PMIChart';
import { PMIModalContendData } from '../data/PMIModalContendData';
import TCGFishApi from '../lib/TCGFishApi';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS,
} from '../lib/constants';
import { CustomRadioButton } from '../components/CustomRadioButton';
import { CustomSwitch } from '../components/CustomSwitch';
import { CustomTooltip } from '../components/CustomTooltip';
import { MainTabs } from '../components/MainTabs';
import { Table } from '../components/Table';

const pageTitle = `Pokémon Market Index 500 - ${DEFAULT_PAGE_TITLE}`;
const pageDescription = `Pokémon Market Index 500 - ${DEFAULT_PAGE_DESCRIPTION}`;
const pageKeywords = `Pokémon, Market Index 500, ${DEFAULT_PAGE_KEYWORDS}`;

export const MarketIndex = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [PMIData, setPMIData] = useState(null);
  const [selectedTab, setSelectedTab] = useState('pmi500');
  const [cardsLangSelect, setCardsLangSelect] = useState('english');
  const [listLangSelect, setListLangSelect] = useState('english');
  const [scalingFlag, setScalingFlag] = useState(0);
  const [distributionDetails, setDistributionDetails] = useState([]);
  const [listingDetails, setListingDetails] = useState([]);
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    TCGFishApi
      .get(`pmi/getPmiData?langFlag=${selectedTab === 'pmi500' ? 0 : 1}&scalingFlag=${scalingFlag}`)
      .then((res) => {
        setPMIData(res.data.data);
      })
      .catch((err) => {
        console.error('Failed to get pmi data', err);
      });
  }, [selectedTab, scalingFlag]);

  useEffect(() => {
    TCGFishApi
      .get(`pmi/getGraphData?langFlag=${selectedTab === 'pmi500' ? 0 : 1}&scalingFlag=${scalingFlag}`)
      .then((res) => {
        setGraphData(res.data.data);
      })
      .catch((err) => {
        console.error('Failed to get graph data', err);
      });
  }, [selectedTab, scalingFlag]);

  useEffect(() => {
    TCGFishApi
      .get(`pmi/getDistributionDetails?langFlag=${selectedTab === 'pmi500' ? 0 : 1}&langSelected=${cardsLangSelect === 'english' ? 'en' : 'jp'}`)
      .then((res) => {
        setDistributionDetails(res.data.data);
      })
      .catch((err) => {
        console.error('Failed to get distribution details', err);
      });
  }, [selectedTab, cardsLangSelect]);

  useEffect(() => {
    TCGFishApi
      .get(`pmi/getListingDetails?langFlag=${selectedTab === 'pmi500' ? 0 : 1}&langSelected=${listLangSelect === 'english' ? 'en' : 'jp'}`)
      .then((res) => {
        setListingDetails(res.data.data);
      })
      .catch((err) => {
        console.error('Failed to get listing details', err);
      });
  }, [selectedTab, listLangSelect]);

  const handleModalOpen = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalContent('');
    setModalTitle('');
  };

  const handleToggle = (event) => {
    setScalingFlag(event.target.checked ? 1 : 0);
  };

  const formatDate = (date) => {
    return format(new Date(date), "MMM d, yyyy, HH:mm");
  };

  const calculateChange = (oldValue, newValue) => {
    if (!oldValue || !newValue) return null;
    return (((newValue - oldValue) / oldValue) * 100).toFixed(2);
  };

  const handleLangCardsChange = (event) => {
    setCardsLangSelect(event.target.value);
  };

  const handleListLangChange = (event) => {
    setListLangSelect(event.target.value);
  };

  const indexData = selectedTab === 'pmi500' ? [
    {
      title: "PMI 500 Index (PSA 10 Gem Mint Version)",
      value: PMIData?.latest?.psaTotal,
      date: PMIData?.latest?.date,
      yearReturn: calculateChange(PMIData?.yearBack?.psaTotal, PMIData?.latest?.psaTotal),
      twoYearReturn: calculateChange(PMIData?.twoYearBack?.psaTotal, PMIData?.latest?.psaTotal),
    },
    {
      title: "PMI 500 Index (CGC Gem Mint 10 Version)",
      value: PMIData?.latest?.cgcGMTotal,
      date: PMIData?.latest?.date,
      yearReturn: calculateChange(PMIData?.yearBack?.cgcGMTotal, PMIData?.latest?.cgcGMTotal),
      twoYearReturn: calculateChange(PMIData?.twoYearBack?.cgcGMTotal, PMIData?.latest?.cgcGMTotal),
    },
    {
      title: "PMI 500 Index (CGC Pristine 10 Version)",
      value: PMIData?.latest?.cgcPRTotal,
      date: PMIData?.latest?.date,
      yearReturn: calculateChange(PMIData?.yearBack?.cgcPRTotal, PMIData?.latest?.cgcPRTotal),
      twoYearReturn: calculateChange(PMIData?.twoYearBack?.cgcPRTotal, PMIData?.latest?.cgcPRTotal),
    },
  ] : [
    {
      title: "PMI 500 Index (PSA) Version",
      value: PMIData?.latest?.psaTotal,
      date: PMIData?.latest?.date,
      yearReturn: calculateChange(PMIData?.yearBack?.psaTotal, PMIData?.latest?.psaTotal),
      twoYearReturn: calculateChange(PMIData?.twoYearBack?.psaTotal, PMIData?.latest?.psaTotal),
    },
    {
      title: "English Market PMI 500 Index",
      value: PMIData?.latest?.engTotal,
      date: PMIData?.latest?.date,
      yearReturn: calculateChange(PMIData?.yearBack?.engTotal, PMIData?.latest?.engTotal),
      twoYearReturn: calculateChange(PMIData?.twoYearBack?.engTotal, PMIData?.latest?.engTotal),
    },
    {
      title: "Japanese Market PMI 500 Index",
      value: PMIData?.latest?.jpnTotal,
      date: PMIData?.latest?.date,
      yearReturn: calculateChange(PMIData?.yearBack?.jpnTotal, PMIData?.latest?.jpnTotal),
      twoYearReturn: calculateChange(PMIData?.twoYearBack?.jpnTotal, PMIData?.latest?.jpnTotal),
    },
  ];

  const getMaxValueClass = (value, maxValue) => value === maxValue ? 'text-welsh-onion-1100' : '';

  const getYearMax = (distribution) => {
    return Math.max(
      distribution?.n5ton8 || 0,
      distribution?.n9tot3 || 0,
      distribution?.t4tot9 || 0,
      distribution?.t10tot16 || 0,
      distribution?.t17Up || 0
    );
  };

  const getGradeMax = (distribution) => {
    return Math.max(
      distribution?.grade10 || 0,
      distribution?.grade9 || 0,
      distribution?.grade8 || 0,
      distribution?.gradeOthers || 0
    );
  };

  const getMktValueMax = (distribution) => {
    return Math.max(
      distribution?.lt50 || 0,
      distribution?.lt150 || 0
    );
  };

  const getLanguageMax = (distribution) => {
    return Math.max(
      distribution?.English || 0,
      distribution?.Japanese || 0
    );
  };

  const yearMax = getYearMax(distributionDetails?.yearDistribution);
  const gradeMax = getGradeMax(distributionDetails?.gradeDistribution);
  const mktValueMax = getMktValueMax(distributionDetails?.mktValueDistribution);
  const languageMax = getLanguageMax(distributionDetails?.languageDistribution);

  const tabs = [
    {
      id: 'pmi500',
      label: 'PMI 500 Index',
      content: (
        <div className="bg-not-tonight-100 rounded-lg p-6 text-child-of-light-1000">
          <div className="flex justify-between items-center mb-6">
            <h3 className='h6 font-semibold text-child-of-light-1000'>
              Pokémon Market Index 500
            </h3>
            <div className="flex items-center space-x-1">
              <CustomSwitch checked={scalingFlag === 1}
                            onChange={handleToggle}
              />
              <span className="p3">Data Normalization</span>
              <CustomTooltip title={`The CGC Index begins with the same value as the PSA Index as of January 1, 2021, and is initially set at $1000 to facilitate easier comparison.
                             (This starting value can be arbitrary.)`}>
                <InformationCircleIcon className="w-6 h-6 text-child-of-light-400 cursor-pointer" />
              </CustomTooltip>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {indexData.map((data, index) => (
              <div key={index} className="pt-4 px-6 pb-5 border border-not-tonight-300 rounded-lg shadow-md">
                <h4 className="p3 mb-2">{data.title}</h4>
                <div className='pb-3 flex items-center gap-3 border-b border-not-tonight-300'>
                  <p className="h5">{data.value?.toFixed(1) ?? '---'}</p>
                  <p className="p7 text-child-of-light-500">As of {data.date ? formatDate(data.date) : '---'}</p>
                </div>
                <div className='mt-4 flex gap-10'>
                  <div className="flex flex-col gap-2">
                    <span className='p6 text-child-of-light-500'>1 year return</span>
                    <span className={`p2 ${data.yearReturn > 0 ? 'text-welsh-onion-500' :'text-dusk-orange-700'}`}>{data.yearReturn ?? '---'}%</span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className='p6 text-child-of-light-500'>2 years return</span>
                    <span className={`p2 ${data.twoYearReturn > 0 ? 'text-welsh-onion-500' :'text-dusk-orange-700'}`}>{data.twoYearReturn ?? '---'}%</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <PMIChart data={graphData} selectedTab={selectedTab}/>
        </div>
      )
    },
    {
      id: 'englishJapanesePmi500',
      label: 'English/Japanese PMI 500 Index',
      content: (
        <div className="bg-not-tonight-100 rounded-lg p-6 text-child-of-light-1000">
          <div className="flex justify-between items-center mb-6">
            <h3 className='h6 font-semibold text-child-of-light-1000'>
              English/Japanese Pokémon Market Index 500
            </h3>
            <div className="flex items-center space-x-1">
              <CustomSwitch checked={scalingFlag === 1}
                            onChange={handleToggle}
              />
              <span className="p3">Data Normalization</span>
              <CustomTooltip title={`Japanese/English Market Index starts with the same value as the PMI 500 Index on 2019-May-01`}>
                <InformationCircleIcon className="w-6 h-6 text-child-of-light-400 cursor-pointer" />
              </CustomTooltip>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {indexData.map((data, index) => (
              <div key={index} className="pt-4 px-6 pb-5 border border-not-tonight-300 rounded-lg shadow-md">
                <h4 className="p3 mb-2">{data.title}</h4>
                <div className='pb-3 flex items-center gap-3 border-b border-not-tonight-300'>
                  <p className="h5">{data.value?.toFixed(1) ?? '---'}</p>
                  <p className="p7 text-child-of-light-500">As of {data.date ? formatDate(data.date) : '---'}</p>
                </div>
                <div className='mt-4 flex gap-10'>
                  <div className="flex flex-col gap-2">
                    <span className='p6 text-child-of-light-500'>1 year return</span>
                    <span className={`p2 ${data.yearReturn > 0 ? 'text-welsh-onion-500' :'text-dusk-orange-700'}`}>{data.yearReturn ?? '---'}%</span>
                  </div>
                  <div className="flex flex-col gap-2">
                    <span className='p6 text-child-of-light-500'>2 years return</span>
                    <span className={`p2 ${data.twoYearReturn > 0 ? 'text-welsh-onion-500' :'text-dusk-orange-700'}`}>{data.twoYearReturn ?? '---'}%</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <PMIChart data={graphData} />
        </div>
      )
    }
  ];

  const tableHeaders = [
    { title: 'CARD DETAILS', key: 'titles' },
    { title: 'GRADE', key: 'gradeBest', render: (item) => `PSA ${item.gradeBest}` },
    { title: 'PORTFOLIO WEIGHT', key: 'weights', render: (item) => `${(item.weights * 100).toFixed(2)}%`, cellClassName: 'text-right' },
    { title: 'EST. MARKET VALUE', key: 'paDate', render: (item) => `$${item.paDate.toFixed(2)}`, cellClassName: 'text-right' }
  ];

  return (
    <main className="w-full flex flex-col px-15 pt-14 pb-6">
      <MetadataTags title={pageTitle}
                    description={pageDescription}
                    keywords={pageKeywords}
      />
      <div className="text-child-of-light-1000">
        <p className='p4 mb-2 text-child-of-light-500'>TCGFISH Graded Card Market Index</p>
        <div className='flex justify-between mb-6 items-end'>
          <h1 className="h4">Pokémon Market Index 500 (PMI 500)</h1>
          <p className='p7 text-not-tonight-600'>Latest Update: {PMIData?.latest?.date ? formatDate(PMIData.latest.date) : 'Loading...'}</p>
        </div>
        <div className="px-4 py-5 bg-purple-300 rounded-lg">
          <div className="flex p7 gap-2">
            <InformationCircleIcon className="w-10 h-6" />
            <div className='flex flex-col'>
              <p className='mb-4'>
                Similar to the S&P 500 Index, the subsequent index provides a comprehensive reflection of the professionally graded collectible trading cards, specifically the Pokémon category (the Sports Card index can use the same methodology). (Simple terms, we have 500 cards in one big basket, and we track the performance of the whole basket.)
              </p>
              <p>
                TCGFish is pleased to announce this market index and we are confident this will become a foundational tool in the collectibles industry. To foster transparency, we outline the methodologies employed for the index in the following documentations:
              </p>
              <div className="mt-4">
                <div className="flex items-center cursor-pointer mb-3" onClick={() => handleModalOpen('Pokémon Market Index 500', PMIModalContendData.pokemonMarketIndex500)}>
                  <DocumentTextIcon className="text-white w-5 h-5 mr-2" />
                  <span>Pokémon Market Index 500</span>
                </div>
                <div className="flex items-center cursor-pointer mb-3" onClick={() => handleModalOpen('CGC Pokémon Market Index 500', PMIModalContendData.cgcPokemonMarketIndex500)}>
                  <DocumentTextIcon className="text-white w-5 h-5 mr-2" />
                  <span>CGC Pokémon Market Index 500</span>
                </div>
                <div className="flex items-center cursor-pointer" onClick={() => handleModalOpen('English/Japanese Pokémon Market Index 500', PMIModalContendData.englishJapanesePokemonMarketIndex500)}>
                  <DocumentTextIcon className="text-white w-5 h-5 mr-2" />
                  <span>English/Japanese Pokémon Market Index 500</span>
                </div>
              </div>
              <p className="mt-8">
                Should you have any recommendations or feedback, kindly connect with us on our <a href="https://discord.gg/YCgc779Syz" target='_blank' className="text-link-purple p6" rel="noreferrer">Discord</a> or reach out at <a href="mailto:support@tcgfish.com" className="text-link-purple p6">support@tcgfish.com</a>.
              </p>
            </div>
          </div>
        </div>
      </div>
      <MainTabs tabs={tabs} selectedTab={selectedTab} onSelectTab={setSelectedTab} contentClassName="text-child-of-light-1000" />
      <div className="p-6 bg-not-tonight-100 rounded-lg mt-8 text-child-of-light-1000">
        <h3 className="h6 font-semibold mb-9">
          {selectedTab === 'pmi500' ? `PMI 500 Index Characteristics Breakdown`
            : cardsLangSelect === 'english' ? `English PMI 500 Index Characteristics Breakdown`
              : `Japanese PMI 500 Index Characteristics Breakdown`
          }
        </h3>
        {selectedTab === 'englishJapanesePmi500' && (
          <div className='mb-7'>
            <CustomRadioButton label="English cards"
              value={'english'}
              checked={cardsLangSelect === 'english'}
              onChange={handleLangCardsChange}
            />
            <CustomRadioButton label="Japanese cards"
              value={'japanese'}
              checked={cardsLangSelect === 'japanese'}
              onChange={handleLangCardsChange}
            />
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
          <div className="pt-4 px-6 pb-6 p3 border border-not-tonight-300 rounded-lg">
            <h4 className="mb-6">Card release year distribution</h4>
            <ul className="space-y-4">
              <li className='p4 flex justify-between'>1995-1998 <span className={`p3 ${getMaxValueClass(distributionDetails?.yearDistribution?.n5ton8, yearMax)}`}>{distributionDetails?.yearDistribution?.n5ton8 ? `${distributionDetails?.yearDistribution?.n5ton8.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>1999-2003 <span className={`p3 ${getMaxValueClass(distributionDetails?.yearDistribution?.n9tot3, yearMax)}`}>{distributionDetails?.yearDistribution?.n9tot3 ? `${distributionDetails?.yearDistribution?.n9tot3.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>2004-2009 <span className={`p3 ${getMaxValueClass(distributionDetails?.yearDistribution?.t4tot9, yearMax)}`}>{distributionDetails?.yearDistribution?.t4tot9 ? `${distributionDetails?.yearDistribution?.t4tot9.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>2010-2016 <span className={`p3 ${getMaxValueClass(distributionDetails?.yearDistribution?.t10tot16, yearMax)}`}>{distributionDetails?.yearDistribution?.t10tot16 ? `${distributionDetails?.yearDistribution?.t10tot16.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>{`>= 2017`} <span className={`p3 ${getMaxValueClass(distributionDetails?.yearDistribution?.t17Up, yearMax)}`}>{distributionDetails?.yearDistribution?.t17Up ? `${distributionDetails?.yearDistribution?.t17Up.toFixed(2)}%` : "- - -"}</span></li>
            </ul>
          </div>
          <div className="pt-4 px-6 pb-6 p3 border border-not-tonight-300 rounded-lg">
            <h4 className="mb-6">Grade distribution</h4>
            <ul className="space-y-4">
              <li className='p4 flex justify-between'>Grade 10 <span className={`p3 ${getMaxValueClass(distributionDetails?.gradeDistribution?.grade10, gradeMax)}`}>{distributionDetails?.gradeDistribution?.grade10 ? `${distributionDetails?.gradeDistribution?.grade10.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>Grade 9 <span className={`p3 ${getMaxValueClass(distributionDetails?.gradeDistribution?.grade9, gradeMax)}`}>{distributionDetails?.gradeDistribution?.grade9 ? `${distributionDetails?.gradeDistribution?.grade9.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>Grade 8 <span className={`p3 ${getMaxValueClass(distributionDetails?.gradeDistribution?.grade8, gradeMax)}`}>{distributionDetails?.gradeDistribution?.grade8 ? `${distributionDetails?.gradeDistribution?.grade8.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>Others <span className={`p3 ${getMaxValueClass(distributionDetails?.gradeDistribution?.gradeOthers, gradeMax)}`}>{distributionDetails?.gradeDistribution?.gradeOthers ? `${distributionDetails?.gradeDistribution?.gradeOthers.toFixed(2)}%` : "- - -"}</span></li>
            </ul>
          </div>
          <div className="pt-4 px-6 pb-6 p3 border border-not-tonight-300 rounded-lg">
            <h4 className="mb-6">Market value distribution</h4>
            <ul className="space-y-4">
              <li className='p4 flex justify-between'>&lt;= $50 <span className={`p3 ${getMaxValueClass(distributionDetails?.mktValueDistribution?.lt50, mktValueMax)}`}>{distributionDetails?.mktValueDistribution?.lt50 ? `${distributionDetails?.mktValueDistribution?.lt50.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>&gt; $50 <span className={`p3 ${getMaxValueClass(distributionDetails?.mktValueDistribution?.lt150, mktValueMax)}`}>{distributionDetails?.mktValueDistribution?.lt150 ? `${distributionDetails?.mktValueDistribution?.lt150.toFixed(2)}%` : "- - -"}</span></li>
            </ul>
          </div>
          <div className="pt-4 px-6 pb-6 p3 border border-not-tonight-300 rounded-lg">
            <h4 className="mb-6">Card language distribution</h4>
            <ul className="space-y-4">
              <li className='p4 flex justify-between'>English <span className={`p3 ${getMaxValueClass(distributionDetails?.languageDistribution?.English, languageMax)}`}>{distributionDetails?.languageDistribution?.English ? `${distributionDetails?.languageDistribution?.English.toFixed(2)}%` : "- - -"}</span></li>
              <li className='p4 flex justify-between'>Japanese <span className={`p3 ${getMaxValueClass(distributionDetails?.languageDistribution?.Japanese, languageMax)}`}>{distributionDetails?.languageDistribution?.Japanese ? `${distributionDetails?.languageDistribution?.Japanese.toFixed(2)}%` : "- - -"}</span></li>
            </ul>
          </div>
        </div>
      </div>
      {/* Table for PMI 500 Index Constituents/Weights Allocation */}
      <div className="mt-12 bg-purple-100 text-child-of-light-1000">
        <h3 className="h6 font-semibold mb-9">
          {selectedTab === 'pmi500' ? `PMI 500 Index Constituents/Weights Allocation`
            : listLangSelect === 'english' ? `English PMI 500 Index Constituents/Weights Allocation`
              : `Japanese PMI 500 Index Constituents/Weights Allocation`
          }
        </h3>
        {selectedTab === 'englishJapanesePmi500' && (
          <div className='mb-4'>
            <CustomRadioButton label="English cards"
              value="english"
              checked={listLangSelect === 'english'}
              onChange={handleListLangChange}
            />
            <CustomRadioButton label="Japanese cards"
              value="japanese"
              checked={listLangSelect === 'japanese'}
              onChange={handleListLangChange}
            />
          </div>
        )}
        <Table headers={tableHeaders} 
               data={listingDetails?.listings || []}
               keyExtractor={(item, index) => index}
               emptyStateMessage="No listings available at the moment"
        />
      </div>
      {isModalOpen && <MarketIndexModal title={modalTitle}
        content={modalContent}
        onClose={handleModalClose}
      />}
    </main>
  );
}