import React, { useContext } from 'react';

import { ModalsContext } from '../contexts/ModalsContext';
import { HomeHeroSection } from '../components/HomeHeroSection';
import { HomeFeaturesSection } from '../components/HomeFeaturesSection';
import { HomeAppSection } from '../components/HomeAppSection';
import { HomePopularCards } from '../components/HomePopularCards';
// import HomeChart from '../components/HomeChart/HomeChart';
import MetadataTags from '../components/MetadataTags/MetadataTags';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS
} from '../lib/constants';

export const Home = ({ windowWidth }) => {
  const { modals } = useContext(ModalsContext);

  return (
    <div className="flex flex-col">
      <MetadataTags title={ DEFAULT_PAGE_TITLE }
                    description={ DEFAULT_PAGE_DESCRIPTION }
                    keywords={ DEFAULT_PAGE_KEYWORDS }
      />
      <HomeHeroSection />
      <HomeFeaturesSection />
      <HomePopularCards />
      {/*<HomeChart windowWidth={windowWidth} />*/}
      <HomeAppSection />
    </div>
  );
};
