import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';
import { ModalsContext } from '../contexts/ModalsContext';
import { LoadingSpinner } from './LoadingSpinner';

// Page checks if the user is authorized to see the children component.
export const Page = ({ requireAuthenticatedUser, UnauthorizedFallbackComponent, children }) => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { modals, setModals } = useContext(ModalsContext);

  const [state, setState] = useState({
    authorized: true // Optimistically try to render the page
  });

  useEffect(() => {
    if (requireAuthenticatedUser && !user) {
      // User is loading.
      if (user === null) {
        return;
      }

      navigate('/');
      setModals((prevModals) => {
        return {
          ...prevModals,
          showLogInModal: true
        };
      });
      setState((prevState) => {
        return {
          ...prevState,
          authorized: false
        };
      });
    }

    if (requireAuthenticatedUser && user) {
      setState((prevState) => {
        return {
          ...prevState,
          authorized: true
        };
      });
    }
  }, [requireAuthenticatedUser, user]);

  useEffect(() => {
    if (Object.values(modals).find(shown => shown)) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';

      return;
    }

    document.body.style.overflow = 'unset';
    document.body.style.height = 'unset';
  }, [modals]);

  // User is loading.
  if (requireAuthenticatedUser && user === null) {
    return (
      // TODO: custom classes to tailwind.
      <div className="app__preloader__whole">
        <LoadingSpinner />
      </div>
    );
  }

  if (requireAuthenticatedUser && !state.authorized) {
    return UnauthorizedFallbackComponent;
  }

  return (
    <>
      { children }
    </>
  );
};
