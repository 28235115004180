import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  XMarkIcon, 
  ArrowTopRightOnSquareIcon, 
  ArrowPathIcon, 
} from '@heroicons/react/20/solid';
import { format, parse } from 'date-fns';

import MetadataTags from '../components/MetadataTags/MetadataTags';
import TCGFishApi from '../lib/TCGFishApi';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS,
} from '../lib/constants';
import ebay from '../assets/images/ebay_logo.svg';
import logo from '../assets/images/tsglogo.svg';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { formatDate } from '../assets/canvasjs.min';
import { Accordion } from '../components/Accordion';
import { Pagination } from '../components/Pagination';
import { useDebounce } from '../hooks/useDebounce';
import { DealChip } from '../components/DealChip';
import { TextLink } from '../components/TextLink';
import { TextButton } from '../components/TextButton';
import { SidebarSearchBlock } from '../components/SidebarSearchBlock';
import { getInternalLink } from '../utils/getInternalLink';
import { SortDropdown } from '../components/SortDropdown';
import { AuctionInfo } from '../components/AuctionInfo';

const CARDS_PER_PAGE = 15;
const DEFAULT_SORT_OPTION = 'endingSoon';
const pageTitle = `Undervalue Bot - Wallet Smasher 2.1 - ${DEFAULT_PAGE_TITLE}`;
const pageDescription = `Undervalue Bot - Wallet Smasher 2.1 - ${DEFAULT_PAGE_DESCRIPTION}`;
const pageKeywords = `Undervalue Bot, Wallet Smasher 2.1, ${DEFAULT_PAGE_KEYWORDS}`;

const fixEndTimeFormat = (endTime) => {
  const cleanedEndTime = endTime.replace(/ *\([^)]*\) */g, '');
  const parsedDate = parse(cleanedEndTime, 'yyyy-MM-dd HH:mm:ss', new Date());
  return format(parsedDate, 'yyyy-MM-dd HH:mm');
};

export const UnderValueBot = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const initialSearchParams = new URLSearchParams(search);

  const [refresh, setRefresh] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(null);
  const [metaData, setMetaData] = useState(null);
  const [currentPage, setCurrentPage] = useState(parseInt(initialSearchParams.get('page') || '1'));
  const [total, setTotal] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [completeTotal, setCompleteTotal] = useState(0);
  const [anonLimitExceeded, setAnonLimitExceeded] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialSearchParams.get('query') || '');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);
  const [filters, setFilters] = useState({
    grader: initialSearchParams.getAll('grader')
  });
  const filtersAreApplied = Object.values(filters).some(filter => filter.length > 0);
  const [sortOption, setSortOption] = useState(initialSearchParams.get('sortOption') || DEFAULT_SORT_OPTION);
  const sortOptions = [
    { value: 'highToLow', label: 'Value: high to low' },
    { value: 'lowToHigh', label: 'Value: low to high' },
    { value: 'newlyAdded', label: 'Newly added' },
    { value: 'endingSoon', label: 'Ending soon' }
  ];

  const graderOptions = ['PSA', 'BGS', 'CGC'];

  const updateURLParams = () => {
    const params = new URLSearchParams();
    params.set('page', currentPage.toString());
    params.set('sortOption', sortOption);
    if (searchQuery) {
      params.set('query', searchQuery);
    }
    if (filters.grader.length) {
      filters.grader.forEach(value => params.append('grader', value));
    }
    navigate(`/undervalue-bot?${params.toString()}`);
  };

  const getData = async () => {
    if (anonLimitExceeded) {
      setAnonLimitExceeded(false);
    }
    setIsLoading(true);
    try {
      const res = await TCGFishApi.get('underValue/getNewData', {
        params: {
          limit: CARDS_PER_PAGE,
          page: currentPage,
          query: debouncedSearchQuery,
          grader: filters.grader.join(','),
          sortET: sortOption === 'endingSoon' ? 'asc' : sortOption === 'newlyAdded' ? 'desc' : '',
          sortPAF: sortOption === 'highToLow' ? 'asc' : sortOption === 'lowToHigh' ? 'desc' : ''
        }
      });
      const updatedData = res.data.data.map(item => ({
        ...item,
        endTime: fixEndTimeFormat(item.endTime)
      }));
  
      setData(updatedData);
      setTotal(res.data.count);
      setCompleteTotal(res.data.totalCount);
      setTotalPages(Math.ceil(res.data.count / CARDS_PER_PAGE));
    } catch (err) {
      if (err.response && err.response.data === 'Anonymous access limit exceeded, please sign in or try later') {
        setAnonLimitExceeded(true);
        setIsLoading(false);
        return;
      }
      console.error('Failed to get underValue/getNewData', err);
      setError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    updateURLParams();
    getData();
  }, [currentPage, sortOption, debouncedSearchQuery, filters]);

  useEffect(() => {
    if (error) {
      setError(false);
    }
    getData();
  }, [refresh]);

  useEffect(() => {
    setMetaData(null);
    if (error) {
      setError(false);
    }
    TCGFishApi.get('underValue/getMetaData')
      .then((res) => {
        setMetaData(res.data.data?.metadata);
      })
      .catch((err) => {
        console.error('Failed to get underValue/getMetaData', err);
        setError(true);
      });
  }, [refresh]);

  useEffect(() => {
    const initializeEBaySmartLinks = () => {
      window._epn = {
        campaign: '5338993909',
        smartPopover: false,
      };
      const script = document.createElement('script');
      script.src = 'https://epnt.ebay.com/static/epn-smart-tools.js';
      document.head.appendChild(script);
    };

    initializeEBaySmartLinks();
  }, []);

  const handleFilterChange = (category, item) => {
    setCurrentPage(1);
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [category]: prevFilters[category].includes(item)
          ? prevFilters[category].filter((x) => x !== item)
          : [...prevFilters[category], item],
      };
    });
  };

  const clearFilters = () => {
    setFilters({
      grader: []
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setCurrentPage(1);
    setSearchQuery(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
  };

  const handleSortChange = (sortOption) => {
    setSortOption(sortOption);
    setCurrentPage(1);
    updateURLParams();
    getData();
  };

  return (
    <div className="bg-gray-900 min-h-screen text-child-of-light-1000">
    <MetadataTags title={pageTitle}
                  description={pageDescription}
                  keywords={pageKeywords}
    />

      <div className="container mx-auto py-8">
        <div className="flex justify-between items-center mb-8">
          <h1 className="h4">Undervalue <img src={logo} alt="logo" className="inline-block h-10 w-12" /> Bot - Wallet Smasher 2.1</h1>
          <div>
            {metaData && 
              <p className="p7 text-not-tonight-600 mb-1">Last Update: {formatDate(metaData.update_time, 'YYYY-MMM-DD H:mm')}</p>
            }
            <TextButton Icon={ArrowPathIcon}
                        onClick={() => setRefresh(!refresh)}
                        text={'Refresh Page'}
            />
          </div>
        </div>

        <p className="p7 text-child-of-light-500 mb-15">
          For information and education purposes only. This page does not contain any financial advice. All information used are public information, we do not own any of the content displayed below. TCGFish is an eBay Partner Network affiliate. If you click on the affiliated links, and make a purchase, TCGFish may receive a commission.
        </p>

        <p className="p1 mb-9">
          FishBot has gone fishing through{' '}
          <strong>{metaData ? metaData.auction_total_count : null}</strong>
          {' '}eBay listings. Fish Bot caught{' '}
          <strong>{metaData ? metaData.auction_deal_count : null}</strong>
          {' '}Deals. Good Deal rate at{' '}
          <strong>
            {metaData && ((metaData.auction_deal_count / metaData.auction_total_count) * 100).toFixed(2)}
            %.
          </strong>
          {' '}Enjoy &#127843;!
        </p>
        {!anonLimitExceeded && 
          <div className='flex justify-between w-full items-center h-8'>
            <p className='p4 text-not-tonight-900 shrink-0'>Selected Listings: <span className='p3 text-not-tonight-1000'>{total}/{completeTotal}</span></p>
            <div className="w-full inline-flex flex-wrap justify-start items-center flex-0">
              {filtersAreApplied &&
                <span className="ml-6">
                  {Object.keys(filters).map((category) =>
                    <span className="inline-flex flex-wrap"
                          key={category}
                    >
                    {filters[category]?.map((filterValue, index) =>
                      <span className="inline-flex items-center flex-wrap ml-2 py-1.5 pl-4 pr-3 bg-purple-300 rounded-5.75"
                            key={filterValue}
                      >
                        <span className="p7 text-white mr-2">
                          {filterValue}
                        </span>
                        <XMarkIcon className='h-5 w-5 text-white cursor-pointer'
                                  onClick={() => {
                                    setFilters((prevFilters) => {
                                      return {
                                        ...prevFilters,
                                        [category]: filters[category].toSpliced(index, 1)
                                      };
                                    });
                                  }}
                        />
                      </span>
                    )}
                    </span>
                  )}
                </span>
              }
            </div>
            {total !== 0 && 
              <SortDropdown options={sortOptions}
                            selectedOption={sortOption}
                            onSelect={handleSortChange}
              />
            }
          </div>
        }
        {anonLimitExceeded ? 
          <p className="mx-auto mt-4 p-4 rounded bg-dusk-orange-900 text-dusk-orange-500 text-center">Please log in to get fresh data</p>
        : (
            <div className="flex flex-wrap lg:flex-nowrap my-4 gap-6">
              <aside className="w-full lg:w-1/4">
                <div className="border-min border-not-tonight-200 px-5 pt-5 pb-2 rounded-lg mt-4">
                  <div className="flex items-center justify-between h-6">
                    <h3 className="p2 text-white">
                      Filters
                    </h3>
                    {filtersAreApplied &&
                      <button className="flex items-center justify-center p-1 rounded-full bg-transparent"
                              onClick={clearFilters}
                      >
                        <XMarkIcon className="h-5 w-5 text-child-of-light-500"
                                  aria-hidden="true"
                        />
                        <span className="p7 text-child-of-light-500">
                          Clear filters
                        </span>
                      </button>
                    }
                  </div>
                  <Accordion title="Grader"
                             options={graderOptions}
                             selectedItems={filters.grader}
                             onChange={(item) => handleFilterChange('grader', item)}
                             isCollapsedAccordion={false}
                  />
                </div>
                <SidebarSearchBlock searchQuery={searchQuery} 
                                    handleSearch={handleSearch} 
                                    handleClearSearch={handleClearSearch}
                />
              </aside>
              <div className="grid gap-8 w-full lg:w-3/4 mt-5">
                {isLoading ? (
                  <div className="flex items-center justify-center min-h-100">
                    <LoadingSpinner/>
                  </div>
                ) : (
                  <>
                    {data && data.length > 0 ? (
                      data.map((item) => (
                        <div key={item.ebayLink} className="pb-6 flex items-start flex-col md:flex-row border-b border-not-tonight-300 gap-6">
                          <div className="md:w-1/6 flex-shrink-0 flex justify-center mb-4 md:mb-0 h-[240px]">
                            <a href={item.ebayLink} target="_blank" rel="noreferrer" className="p7 text-child-of-light-100 mb-1 text-center">
                              <img src={item.imageLink} alt="imageLink" className="h-full" />
                            </a>
                          </div>
                          <div className="md:w-5/6 flex flex-col justify-between h-full">
                            <div className='flex justify-between items-center'>
                              <h3 className="p3 pr-2">{item.cardTitle}</h3>
                              <DealChip measure={item.measure}/>
                            </div>
                            <AuctionInfo item={item} />
                            <div className="flex items-center space-x-4">
                              <a href={item.ebayLink} target="_blank" rel="noreferrer" className="p7 w-[200px] h-[40px] flex items-center justify-center gap-2 bg-white text-child-of-light-100 py-2 px-4 rounded hover:bg-gray-100">
                                Right now on <img src={ebay} alt="ebay" className="w-16 h-16" />
                              </a>
                              <a href={item.ebayLink} target="_blank" rel="noreferrer" className="p7 w-[200px] h-[40px] flex items-center justify-center gap-2 bg-purple-600 text-white py-3 px-4 rounded hover:bg-purple-700">
                                <i className="fas fa-gavel" style={{fontSize: 20}}/> Bid Now
                              </a>
                              <TextLink text='Card Data'
                                        Icon={ArrowTopRightOnSquareIcon}
                                        to={getInternalLink(item.website)}
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p className="p4 text-child-of-light-600 mt-6">Sorry, no listings were found. Please try different filters or search criteria.</p>
                    )}
                    {!!total && totalPages > 1 &&
                      <div className="mt-8 flex justify-end">
                        <Pagination currentPage={currentPage}
                                    totalPages={totalPages}
                                    onChange={setCurrentPage}
                        />
                      </div>
                    }
                  </>
                )}
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};
