import React from 'react';

import { ANDROID_APP_URL, IOS_APP_URL } from '../lib/constants';

export const HomeAppSection = () => {
  return (
    <div className="flex flex-row justify-between w-full h-91.25 flex-shrink-0 pl-15 border-min border-purple-300 rounded-1.75 bg-purple-200">
      <div className="flex flex-col">
        <p className="mt-10 text-not-tonight-1000 h4">
          Try TCGFish App
        </p>
        <p className="w-108.75 mt-5 p4 text-not-tonight-900">
          Advanced Graded Card Scanner helps capture the graded card's market value in seconds.TCGFish now available on the App Store and Google Play Store.
        </p>
        <div className="flex flex-row flex-shrink-0 mt-24.1625">
          <a href={IOS_APP_URL}>
            <img className="inline-flex w-38.79296 h-11.8805"
                 src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=155x48&amp;releaseDate=1674000000"
                 alt="Download on the App Store"
                 width="9.69824rem"
                 height="2.970125rem"
            />
          </a>
          <a href={ANDROID_APP_URL}>
            <img className="inline-flex w-40 h-17.5 -mt-2.75"
                 src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                 alt="Get it on Google Play"
                 width="10rem"
                 height="2.9375rem"
            />
          </a>
        </div>
      </div>
      <div className="home_app_section-iphone w-137.75 h-91.25 mr-37.5 relative">
        <div className="absolute flex flex-col gap-2.5 top-16.75 -left-8.75">
          <p className="p2 text-white">
            SEARCH
          </p>
          <svg xmlns="http://www.w3.org/2000/svg" width="263" height="6" viewBox="0 0 263 6" fill="none">
            <path d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5H263V2.5H3V3.5Z"
                  fill="#9A7FBC"/>
          </svg>
        </div>
        <div className="absolute flex flex-col gap-2.5 top-44 -left-21.5">
          <p className="p2 text-white">
            SCAN
          </p>
          <svg width="231" height="6" viewBox="0 0 231 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.333333 3C0.333333 4.47276 1.52724 5.66667 3 5.66667C4.47276 5.66667 5.66667 4.47276 5.66667 3C5.66667 1.52724 4.47276 0.333333 3 0.333333C1.52724 0.333333 0.333333 1.52724 0.333333 3ZM3 3.5H231V2.5H3V3.5Z"
                  fill="#9A7FBC"/>
          </svg>
        </div>
        <div className="absolute flex flex-col items-end gap-2.5 top-65.25 left-91.25">
          <p className="p2 text-white">
            DATA
          </p>
          <svg width="263" height="6" viewBox="0 0 263 6" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M257.333 3C257.333 4.47276 258.527 5.66667 260 5.66667C261.473 5.66667 262.667 4.47276 262.667 3C262.667 1.52724 261.473 0.333333 260 0.333333C258.527 0.333333 257.333 1.52724 257.333 3ZM0 3.5H260V2.5H0V3.5Z"
                  fill="#9A7FBC"/>
          </svg>
        </div>
      </div>
    </div>
  );
};
