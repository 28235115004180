import React, {useEffect, useState} from 'react';
import './AverageSalesPrice.css';

import CanvasJSReact from '../../../../assets/canvasjs.react';
import TCGFishApi from '../../../../lib/TCGFishApi';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function AverageSalesPrice({ windowWidth }) {
  const [averageSalesPrice, setAverageSalesPrice] = useState([]);

  useEffect(() => {
    TCGFishApi
      .get('market/getms1')
      .then((res) => {
        let temp = res.data.count;
        for (let j = 0; j < temp.length; j++) {
          temp[j].date = temp[j].date
            .slice(0, 10)
            .split('-')
            .reverse()
            .join('-');
        }
        setAverageSalesPrice(temp);
      })
      .catch((err) => {
        console.error('Failed to get market/getms1', err);
      });

  }, []);

  const dataPointsJapAvgPrice = () => {
    const dataPointsJapAP = [];
    averageSalesPrice.map((item) => {
      const toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day);
      };
      dataPointsJapAP.push({
        x: toDate(item.date),
        y: parseInt(item.japanese),
      });
    });
    return dataPointsJapAP;
  };

  const dataPointsEngAvgPrice = () => {
    const dataPointsEngAP = [];
    averageSalesPrice.map((item) => {
      const toDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-');
        return new Date(year, month - 1, day);
      };
      dataPointsEngAP.push({
        x: toDate(item.date),
        y: parseInt(item.english),
      });
    });
    return dataPointsEngAP;
  };

  CanvasJS.addColorSet('revenueColorSet', [
    '#2277c0',
    '#F6BA02',
  ]);

  const options = {
    colorSet: 'revenueColorSet',
    animationEnabled: true,
    animationDuration: 2000,
    backgroundColor: '#08131f',
    theme: 'none',
    title: {
      text: 'Average Price(USD)',
      fontColor: 'white',
      fontSize: 15,
      fontFamily: 'Roboto',
      horizontalAlign: 'left',
      padding: 20,
    },
    axisX: {
      margin: 10,
      padding: {
        top: 10,
      },
      labelFontColor: '#fff',
      tickLength: 20,
      tickColor: '#08131F',
      lineColor: '#365C9E',
      lineThickness: 1,
      valueFormatString: 'YYYY MMM',
    },
    axisY: {
      margin: 10,
      labelFontColor: '#fff',
      includesZero: true,
      valueFormatString: '$#,###,###.##',
      tickLength: 20,
      minimum: 0,
      maximum: 500,
      tickColor: '#08131F',
      lineThickness: 6,
      lineColor: '#08131F',
    },
    toolTip: {
      content:
        '{name}:<br/> Date: <strong>{x}</strong><br/>Average Price: <strong>${y}</strong>',
    },
    legend: {
      cursor: 'pointer',
      fontColor: '#fff',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: 16,
      markerMargin: 10,
      itemclick: function (e) {
        e.dataSeries.visible = !(typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible);
        e.chart.render();
      },
    },
    data: [
      {
        type: 'spline',
        markerType: 'circle',
        legendMarkerType: 'circle',
        legendMarkerBorderThickness: 11,
        legendMarkerColor: '#2277c0',
        legendMarkerBorderColor: '#2277c0',
        markerSize: 1,
        showInLegend: true,
        name: 'English Average Price',
        dataPoints: dataPointsEngAvgPrice(),
      },
      {
        type: 'spline',
        markerType: 'circle',
        legendMarkerType: 'circle',
        legendMarkerBorderThickness: 11,
        legendMarkerColor: '#F6BA02',
        legendMarkerBorderColor: '#F6BA02',
        markerSize: 1,
        showInLegend: true,
        name: 'Japanese Average Price',
        dataPoints: dataPointsJapAvgPrice(),
      },
    ],
  };

  const mobOptions = {
    colorSet: 'revenueColorSet',
    animationEnabled: true,
    animationDuration: 2000,
    height: 220,
    backgroundColor: '#08131f',
    theme: 'none',
    title: {
      text: 'Average Price(USD)',
      fontColor: 'white',
      fontSize: 11,
      fontFamily: 'Roboto',
      horizontalAlign: 'left',
      padding: 10,
    },
    axisX: {
      labelFontColor: '#fff',
      labelFontSize: 10,
      tickColor: '#08131F',
      lineColor: '#365C9E',
      lineThickness: 1,
      valueFormatString: "MMM 'YY",
    },
    axisY: {
      labelFontColor: '#fff',
      labelFontSize: 10,
      includesZero: true,
      valueFormatString: '$#,###,###.##',
      tickColor: '#08131F',
      lineColor: '#08131F',
    },
    toolTip: {
      content:
        '{name}:<br/> Date: <strong>{x}</strong><br/>Average Price: <strong>${y}</strong>',
    },
    legend: {
      cursor: 'pointer',
      fontColor: '#fff',
      margin: {
        top: 10,
      },
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      fontSize: 8,
      markerMargin: 10,
      itemclick: function (e) {
        e.dataSeries.visible = !(typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible);
        e.chart.render();
      },
    },
    data: [
      {
        type: 'spline',
        lineThickness: 1,
        markerType: 'circle',
        legendMarkerType: 'circle',
        legendMarkerBorderThickness: 5,
        legendMarkerColor: '#2277c0',
        legendMarkerBorderColor: '#2277c0',
        markerSize: 1,
        showInLegend: true,
        name: 'English Average Price',
        dataPoints: dataPointsEngAvgPrice(),
      },
      {
        type: 'spline',
        lineThickness: 1,
        markerType: 'circle',
        legendMarkerType: 'circle',
        legendMarkerBorderThickness: 5,
        legendMarkerColor: '#F6BA02',
        legendMarkerBorderColor: '#F6BA02',
        markerSize: 1,
        showInLegend: true,
        name: 'Japanese Average Price',
        dataPoints: dataPointsJapAvgPrice(),
      },
    ],
  };

  return (
    <div className="revenue__volume">
      <div className="box">
        <div className="heading__area">
          <div className="rv__heading">Average Sales Price</div>
          <span className="rv__description">
            <p>Track the overall market movement.</p>
            <p>This is a good indicator of the market direction.</p>
          </span>
        </div>
        <div className="graph__area">
          <div className="chart__background">
            <div className="revenue__chart">
              <CanvasJSChart options={windowWidth > 650 ? options : mobOptions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
