import React, { useRef, useEffect } from 'react';

export default function CheckBoxSet({ hideSets, allSets, setFilters, changeSets }) {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        hideSets();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref]);

  if (!allSets) {
    return (
      <span style={{ color: "white", fontSize: "12px" }}>
        Loading...
      </span>
    );
  }

  if (!allSets.length) {
    return (
      <div ref={ref}>
          <span style={{ color: "white", fontSize: "12px" }}>
            No sets found
          </span>
      </div>
    );
  }

  return (
    <div ref={ref}>
      {allSets.map((item, index) =>
        <div
          className={
            (setFilters.indexOf(item) === -1) ? 'MasterSetValue_Set_List_OneLine' : 'MasterSetValue_Set_List_OneLine_Selected'
          }
          key={index}
          onClick={() => { changeSets(item); }}
        >
          {item}
        </div>
      )}
    </div>
  );
}
