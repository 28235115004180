import React from 'react';
import './TermsOfUse.css';
import { default as TOS } from '../../components/TermsOfUse/TermsOfUse';
import MetadataTags from '../../components/MetadataTags/MetadataTags';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS
} from '../../lib/constants';

export default function TermsOfUse() {
  const pageTitle = `Terms of Use - ${DEFAULT_PAGE_TITLE}`;
  const pageDescription = `Terms of Use - ${DEFAULT_PAGE_DESCRIPTION}`;
  const pageKeywords = `Terms of Use, ${DEFAULT_PAGE_KEYWORDS}`;


  return (
    <div className="container">
      <MetadataTags title={ pageTitle }
                    description={ pageDescription }
                    keywords={ pageKeywords }
      />

      <div className="terms_of_use__page_content">
        <TOS />
      </div>
    </div>
  );
}
