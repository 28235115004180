import React, { useEffect, useState } from 'react';
import './SalesValueBreakdown.css';
import { SteppedLineTo } from 'react-lineto';
import TCGFishApi from '../../../../lib/TCGFishApi';
import { numFormatter } from '../../../../lib/helpers';

export default function SalesValueBreakdown() {
  const [salesValue, setSalesValue] = useState({});

  useEffect(() => {
    TCGFishApi
      .get('market/getms22')
      .then((res) => {
        const result = res.data?.count[0] || {};
        setSalesValue(result);
      })
      .catch((err) => {
        console.error('Failed to get market/getms22', err);
      });
  }, []);

  const ninetyDays = salesValue['90days'];

  return (
    <div className="sales__revenue__breakdown">
      <div className="srbl__box">
        <div className="srbl__heading__area">
          <div className="srbl__heading">
            Sales Volume <br />
            Breakdown by Language
          </div>
        </div>
        <div className="srbl__graph__area">
          <div className="srbl__top">
            <div className="srbl__graph__header">
              <div className="srbl__graph__heading">Past 90 Days</div>
            </div>
            <div className="srbl__pd__graph">
              <div className="srbl__pd__bar__container">
                {ninetyDays &&
                  <>
                    <div id="from4" className="srbl__pd__bar from4">
                      <div
                        className="srbl__pd__bar__japan from3"
                        id="from3"
                        style={{
                          minWidth: `${ninetyDays[1]?.toFixed(2)}%`,
                          maxWidth: `${ninetyDays[1]?.toFixed(2)}%`,
                        }}
                      ></div>
                    </div>
                    <SteppedLineTo
                      from="from3" to="pd_jap" borderColor="#78AF53" borderWidth={2} delay={1000} toAnchor={'top'} zIndex={1} fromAnchor={'top'} orientation={'v'}
                    />
                    <SteppedLineTo
                      from="from4" to="to4" borderColor="#507AC4" borderWidth={2} delay={1000} toAnchor={'bottom'} zIndex={1} fromAnchor={'bottom'}
                    />
                  </>
                }
              </div>
              <div className="srbl__pd__data">
                <div className="srbl__pd__jap pd_jap" id="to3">
                  <p className="srbl__language">
                    Japanese Cards:{' '}
                    <span className="srbl__pd__jap__value">
                      {ninetyDays ? ninetyDays[1]?.toFixed(2) : null}%
                    </span>
                  </p>
                  <p className="srbl__total">
                    Total: {ninetyDays ? numFormatter(ninetyDays[0]) : null}
                  </p>
                </div>
                <div className="srbl__pd__eng to4" id="to4">
                  <p className="srbl__language">
                    English Cards:{' '}
                    <span className="srbl__pd__eng__value">
                      {ninetyDays ? ninetyDays[3]?.toFixed(2) : null}%
                    </span>
                  </p>
                  <p className="srbl__total">
                    Total: {ninetyDays ? numFormatter(ninetyDays[2]) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="srbl__bottom">
            <div className="srbl__graph__header">
              <div className="srbl__graph__heading">(2019 - 2023) Full History Statistics</div>
            </div>
            <div className="srbl__fhs__graph">
              <div id="content" className="srbl__fhs__bar__container">
                {ninetyDays &&
                  <>
                    <div
                      id="from2"
                      className="srbl__fhs__bar from1">
                      <div
                        id="from1"
                        className="srbl__fhs__bar__japan from2"
                        style={{
                          minWidth: `${salesValue.fullhistory[1]?.toFixed(2)}%`,
                          maxWidth: `${salesValue.fullhistory[1]?.toFixed(2)}%`,
                        }}
                      ></div>
                    </div>
                    <SteppedLineTo
                      from="from2" to="to1" borderColor="#F9C013" borderWidth={2} delay={1000} toAnchor={'top'} fromAnchor={'top'}
                    />
                    <SteppedLineTo
                      from="from1" to="to2" borderColor="#783EA3" borderWidth={2} delay={1000} toAnchor={'bottom'} fromAnchor={'bottom'}
                    />
                  </>
                }
              </div>
              <div className="srbl__fhs__data">
                <div className="srbl__fhs__jap to1" id="to1" >
                  <p className="srbl__language">
                    Japanese Cards:{' '}
                    <span className="srbl__fhs__jap__value">
                      {ninetyDays ? salesValue?.fullhistory[1]?.toFixed(2) : null}
                      %
                    </span>
                  </p>
                  <p className="srbl__total">
                    Total: {ninetyDays ? numFormatter(salesValue.fullhistory[0]) : null}
                  </p>
                </div>
                <div className="srbl__fhs__eng to2" id="to2">
                  <p className="srbl__language">
                    English Cards:{' '}
                    <span className="srbl__fhs__eng__value">
                      {ninetyDays ? salesValue.fullhistory[3]?.toFixed(2) : null}
                      %
                    </span>
                  </p>
                  <p className="srbl__total">
                    Total: {ninetyDays ? numFormatter(salesValue.fullhistory[2]) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
