import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

import './RadarChart.css';
import TCGFishApi from '../../lib/TCGFishApi';

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const RadarChart = ({ id }) => {
  const [chartData, setChartData] = useState([]);

  const score = useMemo(() => {
    if (!chartData?.length) {
      return 0;
    }

    const sum = chartData.reduce((total, item) => total + item, 0);

    return sum / chartData.length;
  }, [chartData]);

  useEffect(() => {
    TCGFishApi
      .get(
        'radar/radarData',
        {
          params: { card_id: id }
        }
      )
      .then((res) => {
        const result = res.data.data;

        if (!Object.keys(result).length) {
          setChartData([]);
          return;
        }

        setChartData([
            result.popularity,
            result.liquidity,
            result.affordability,
            result.gd,
            result.vintage,
        ]);
      })
      .catch((err) => {
        setChartData([]);
        console.error('Failed to get radar/radarData', err);
      });
  }, [id]);

  const data = {
    labels: ['Popularity', 'Liquidity', 'Affordability', 'Grade Difficulty', 'Vintage'],
    responsive: true,
    height: '200',
    datasets: [
      {
        data: chartData,
        borderWidth: 2,
        borderColor: '#6F74FF',
        pointBackgroundColor: '#FFFFFF',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#CE8A16',
        pointBorderColor: '#FFFFFF',
        lineTension: 0.5,
        fill: true,
        backgroundColor: '#6F74FF66',
        hoverBackgroundColor: 'light-blue',
        hoverBorderColor: 'blue',
      }
    ]
  };
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: 'white'
        }
      },
      tooltip: {
        padding: {
          top: 8,
          bottom: 8,
          left: 16,
          right: 16
        },
        bodyAlign: 'left',
        titleColor: '#181819', // child-of-light-100
        backgroundColor: '#DDD4E9', // purple-1000
        bodyColor: '#181819', // child-of-light-100
        bodyFont: {
          weight: '600'
        },
        displayColors: false
      }
    },
    scales: {
      r: {
        backgroundColor: 'transparent',
        animate: 'true',
        grid: {
          color: '#342E3B', // not-tonight-300
          backgroundColor: 'grey',
          circular: true
        },
        angleLines: {
          color: '#342E3B', // not-tonight-300
        },
        ticks: {
          display: false,
        },
        pointLabels: {
          color: '#FCFDFD', // child-of-light-1000
          font: {
            size: 14
            }
        },
        beginAtZero: true,
        max: 100,
        min: 0,
        stepSize: 20
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center pb-4">
        <h3 className="p1">
          Collectability Score
        </h3>
        <p className={"p2 text-violet"}>
          {score}/100
        </p>
      </div>
      <div className="RadarChart">
        <Radar data={data}
               width={190}
               height={220}
               options={options}
        />
      </div>
    </div>
  );
};
