import React from 'react';

export const SecondaryMediumButton = ({ text, onClick, Icon, innerRef, className }) => {
  return (
    <div className={`inline-flex flex-shrink-0 h-10 ${Icon ? 'pl-5' : 'pl-6'} pr-6 py-3 justify-center items-center ${Icon ? 'gap-2' : 'gap-2.5'} p7 text-white box-border bg-not-tonight-200 rounded-lg ease-out duration-100 hover:bg-not-tonight-300 ${className ? className : ''}`}
         role="button"
         onClick={onClick}
         ref={innerRef}
    >
      {Icon &&
        <Icon className="w-5 h-5" />
      }
      { text }
    </div>
  )
}
