import React, { useEffect, useState, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/20/solid';

import empty from '../assets/images/empty.png';
import { LoadingSpinner } from '../components/LoadingSpinner';
import MetadataTags from '../components/MetadataTags/MetadataTags';
import TCGFishApi from '../lib/TCGFishApi';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS,
} from '../lib/constants';
import { Accordion } from '../components/Accordion';
import { RangeSlider } from '../components/RangeSlider';
import { SortDropdown } from '../components/SortDropdown';
import { Pagination } from '../components/Pagination';
import { useDebounce } from '../hooks/useDebounce';
import { SidebarSearchBlock } from '../components/SidebarSearchBlock';
import { formatNumber } from '../utils/formatNumber';

const DEFAULT_SORT_OPTION = 'popularityDesc';
const pageTitle = `Search Collectible Cards Catalog - ${DEFAULT_PAGE_TITLE}`;
const pageDescription = `Search Collectible Cards Catalog - ${DEFAULT_PAGE_DESCRIPTION}`;
const pageKeywords = `Search, Collectible Cards Catalog, ${DEFAULT_PAGE_KEYWORDS}`;

export const Catalog = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const search = location.search;
  const initialSearchParams = new URLSearchParams(search);

  const [resultsLoading, setResultsLoading] = useState(true);
  const [resultsLoadError, setResultsLoadError] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState(initialSearchParams.get('query') || '');
  const debouncedSearchQuery = useDebounce(searchQuery, 300);

  const [allSets, setAllSets] = useState([]);
  const [allYears, setAllYears] = useState([]);
  const [allPokemonNames, setAllPokemonNames] = useState([]);
  const [allLanguages, setAllLanguages] = useState([]);
  const [filters, setFilters] = useState({
    sets: initialSearchParams.getAll('sets'),
    years: initialSearchParams.getAll('years'),
    languages: initialSearchParams.getAll('languages'),
    pokemonNames: initialSearchParams.getAll('pokemonNames')
  });

  const [sort, setSort] = useState(initialSearchParams.get('sort') || DEFAULT_SORT_OPTION);
  const initialPage = parseInt(initialSearchParams.get('page'), 10) || 1;
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [totalCards, setTotalCards] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [finalList, setFinalList] = useState([]);
  const [maxPrice, setMaxPrice] = useState(null);
  const [minPrice, setMinPrice] = useState(null);
  const [priceRange, setPriceRange] = useState([minPrice, maxPrice]);

  const initialMinScore = Number(initialSearchParams.get('minScore')) || 0;
  const initialMaxScore = Number(initialSearchParams.get('maxScore')) || 100;
  const [scoreRange, setScoreRange] = useState([initialMinScore, initialMaxScore]);

  const debouncedPriceRange = useDebounce(priceRange, 300);
  const debouncedScoreRange = useDebounce(scoreRange, 300);

  const filtersAreApplied = useMemo(() => {
    const isPriceRangeChanged = priceRange && (priceRange[0] !== minPrice || priceRange[1] !== maxPrice);
    const isScoreRangeChanged = scoreRange && (scoreRange[0] !== 0 || scoreRange[1] !== 100);
    const areFiltersApplied = !!Object.keys(filters).find((category) => filters[category].length);
    return areFiltersApplied || isPriceRangeChanged || isScoreRangeChanged;
  }, [filters, priceRange, minPrice, maxPrice, scoreRange]);

  const sortOptions = [
    { value: DEFAULT_SORT_OPTION, label: 'Popularity' },
    { value: 'nameAsc', label: 'Name: A to Z' },
    { value: 'nameDesc', label: 'Name: Z to A' },
    { value: 'releaseYearAsc', label: 'Release year' }
  ];

  const updateURLParams = () => {
    const params = new URLSearchParams();
    params.set('query', searchQuery);
    params.set('sort', sort);
    params.set('page', currentPage);

    if (priceRange[0] !== minPrice || priceRange[1] !== maxPrice) {
      params.set('minPrice', debouncedPriceRange[0]);
      params.set('maxPrice', debouncedPriceRange[1]);
    } else {
      params.delete('minPrice');
      params.delete('maxPrice');
    }

    if (scoreRange[0] !== 0 || scoreRange[1] !== 100) {
      params.set('minScore', debouncedScoreRange[0]);
      params.set('maxScore', debouncedScoreRange[1]);
    } else {
      params.delete('minScore');
      params.delete('maxScore');
    }

    Object.entries(filters).forEach(([key, values]) => {
      params.delete(key); // Clear previous filter values
      values.forEach(value => params.append(key, value));
    });

    navigate(`?${params.toString()}`, { replace: true });
  };

  const fetchInitialData = async () => {
    setResultsLoading(true);
    try {
      const { data } = await TCGFishApi.get('cards/cardsInformation');
      setAllSets(data.sets);
      setAllYears(data.years);
      setAllPokemonNames(data.pokemonNames);
      setAllLanguages(data.languages);
      setMaxPrice(data.maxPrice);
      setMinPrice(data.minPrice);

      const initialMinPrice = Math.min(Number(initialSearchParams.get('minPrice')) || data.minPrice, data.maxPrice);
      const initialMaxPrice = Math.min(Number(initialSearchParams.get('maxPrice')) || data.maxPrice, data.maxPrice);
      setPriceRange([initialMinPrice, initialMaxPrice]);
    } catch (error) {
      console.error('Error fetching initial data:', error);
    } finally {
      setResultsLoading(false);
      setDataLoaded(true);
    }
  };

  const fetchData = async () => {
    setResultsLoadError(false);
    setResultsLoading(true);
    try {
      const params = {
        query: debouncedSearchQuery,
        sort,
        page: currentPage - 1,
        perPage: 25,
        ...filters,
      };
      if (Array.isArray(priceRange) && priceRange.length === 2 && (priceRange[0] !== minPrice || priceRange[1] !== maxPrice)) {
        params.minPrice = debouncedPriceRange[0];
        params.maxPrice = debouncedPriceRange[1];
      }
      if (Array.isArray(scoreRange) && scoreRange.length === 2 && (scoreRange[0] !== 0 || scoreRange[1] !== 100)) {
        params.minScore = debouncedScoreRange[0];
        params.maxScore = debouncedScoreRange[1];
      }
      const { data } = await TCGFishApi.get('cards/search/v2', { params });
      setTotalCards(data.count);
      setTotalPages(data.totalPages);
      setFinalList(data.cards);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setResultsLoadError(true);
    } finally {
      setResultsLoading(false);
    }
  };
  
  useEffect(() => {
    fetchInitialData();
  }, []);

  useEffect(() => {
    if (dataLoaded) {
      updateURLParams();
    }
  }, [debouncedSearchQuery, filters, sort, currentPage, debouncedPriceRange, debouncedScoreRange]);

  useEffect(() => {
    if (dataLoaded) {
      fetchData();
    }
  }, [location.search, dataLoaded]);

  // When search query is being updated from header search by clicking on "View all results"
  useEffect(() => {
    const getQuery = new URLSearchParams(search).get('query');
    if (getQuery && getQuery !== debouncedSearchQuery) {
      setSearchQuery(getQuery);
    }
  }, [search]);

  const handleSortChange = (sortOption) => {
    if (sort === sortOption) return;
    setSort(sortOption);
    setCurrentPage(1);
  };

  const handleFilterChange = (category, item) => {
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [category]: prevFilters[category].includes(item)
          ? prevFilters[category].filter((x) => x !== item)
          : [...prevFilters[category], item],
      };
    });
    setCurrentPage(1);
  };

  const clearFilters = () => {
    setFilters({
      sets: [],
      years: [],
      languages: [],
      pokemonNames: []
    });
    setPriceRange([minPrice, maxPrice]);
    setScoreRange([0, 100]);
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setCurrentPage(1);
  };

  const listItems = () => {
    if (resultsLoading) return null;

    if (!finalList.length) {
      return <span className="text-white text-xl mx-20">No cards found</span>;
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
        {finalList.map((item, index) => (
          <div className="group relative" key={item._id}>
            <Link className="block"
                  to={`/product?id=${item._id}&cardNumber=${item.sub_cat[0].card_id}`}
            >
              <img className="h-auto w-full object-cover transition-transform duration-300 ease-in-out group-hover:scale-105"
                   src={item.sub_cat[0].image}
                   onError={(e) => (e.target.src = empty)}
                   alt={`IMG-${item.name}`}
              />
            </Link>
            <div className="absolute hidden group-hover:block group-hover:hover:hidden w-56 p-4 bg-not-tonight-100 text-white rounded-lg transform -translate-y-1/2 top-1/2 z-10"
                 style={{
                   width: '140%',
                   height: '101%',
                   [index % 5 === 4 ? 'right' : 'left']: 'calc(100% + 0.75rem)',
                   transition: 'visibility 0s, opacity 0.5s linear',
                 }}
            >
              <div className="mb-2">
                <h4 className="text-not-tonight-700 p8 mb-1">NAME</h4>
                <p className="text-not-tonight-1000 p6">{item.name}</p>
              </div>
              <div className="mb-2">
                <h4 className="text-not-tonight-700 p8 mb-1">SET</h4>
                <p className="text-not-tonight-1000 p6">{item.set_name}</p>
              </div>
              <div className="mb-2">
                <h4 className="text-not-tonight-700 p8 mb-1">YEAR</h4>
                <p className="text-not-tonight-1000 p6">{item.releaseYear}</p>
              </div>
              <div className="mb-2">
                <h4 className="text-not-tonight-700 p8 mb-1">LANGUAGE</h4>
                <p className="text-not-tonight-1000 p6">{item.language}</p>
              </div>
              <div className="mb-2">
                <h4 className="text-not-tonight-700 p8 mb-1">AVERAGE PRICE</h4>
                <p className="text-not-tonight-1000 p6">${formatNumber(item.price)}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <main className="flex flex-col px-15 pt-14 pb-6">
      <MetadataTags title={pageTitle}
                    description={pageDescription}
                    keywords={pageKeywords}
      />
      {resultsLoadError &&
        <div className="mx-auto mt-4 p-4 rounded bg-dusk-orange-900 text-dusk-orange-500 text-center">
          <p>Sorry, we couldn't load the search results. Please try again later.</p>
        </div>
      }
      <h1 className="text-not-tonight-1000 h4">
        Catalog
      </h1>
      <div className="flex justify-between items-center mt-8 h-8">
        <span className="min-w-fit inline-block text-not-tonight-900 p4">
          Number of cards:&nbsp;
          <span className="text-not-tonight-1000 p5">
            {totalCards || 0}
          </span>
        </span>
        <div className="w-full inline-flex flex-wrap justify-start items-center">
          {filtersAreApplied &&
            <span className="ml-6">
              {Object.keys(filters).map((category) =>
                <span className="inline-flex flex-wrap"
                      key={category}
                >
                  {filters[category]?.map((filterValue, index) =>
                    <span className="inline-flex items-center flex-wrap ml-2 py-1.5 pl-4 pr-3 bg-purple-300 rounded-5.75"
                          key={filterValue}
                    >
                      <span className="p7 text-white mr-2">
                        {filterValue}
                      </span>
                      <XMarkIcon className='h-5 w-5 text-white cursor-pointer'
                                 onClick={() => {
                                   setFilters((prevFilters) => {
                                     return {
                                       ...prevFilters,
                                       [category]: filters[category].toSpliced(index, 1)
                                     };
                                   });
                                 }}
                      />
                    </span>
                  )}
                </span>
              )}
              {(scoreRange[0] !== 0 || scoreRange[1] !== 100)  && (
                <span className="inline-flex items-center flex-wrap ml-2 py-1.5 pl-4 pr-3 bg-purple-300 rounded-5.75">
                  <span className="p7 text-white mr-2">Score: {`${scoreRange[0]} - ${scoreRange[1]}`}</span>
                  <XMarkIcon className="h-5 w-5 text-white cursor-pointer"
                             onClick={() => setScoreRange([0, 100])}
                  />
                </span>
              )}
              {(priceRange[0] !== minPrice || priceRange[1] !== maxPrice)  && (
                <span className="inline-flex items-center flex-wrap ml-2 py-1.5 pl-4 pr-3 bg-purple-300 rounded-5.75">
                  <span className="p7 text-white mr-2">Price: {`$${priceRange[0]} - $${priceRange[1]}`}</span>
                  <XMarkIcon className="h-5 w-5 text-white cursor-pointer"
                             onClick={() => setPriceRange([0, maxPrice])}
                  />
                </span>
              )}
            </span>
          }
        </div>
        {finalList?.length &&
          <SortDropdown options={sortOptions}
                        selectedOption={sort}
                        onSelect={handleSortChange}
          />
        }
      </div>
      <div className="flex flex-wrap lg:flex-nowrap my-4">
        <aside className="basis-full lg:basis-1/4">
          <div className="border-min border-not-tonight-200 px-5 pt-5 pb-2 rounded-lg">
            <div className="flex items-center justify-between h-6">
              <h3 className="p2 text-white">
                Filters
              </h3>
              {filtersAreApplied &&
                <button className="flex items-center justify-center p-1 rounded-full bg-transparent"
                        onClick={clearFilters}
                >
                  <XMarkIcon className="h-5 w-5 text-child-of-light-500"
                             aria-hidden="true"
                  />
                  <span className="p7 text-child-of-light-500">
                    Clear filters
                  </span>
                </button>
              }
            </div>
            <Accordion title="Set"
                       options={allSets}
                       selectedItems={filters.sets}
                       onChange={(item) => handleFilterChange('sets', item)}
                       hasSearch
            />
            <Accordion title="Year"
                       options={allYears}
                       selectedItems={filters.years}
                       onChange={(item) => handleFilterChange('years', item)}
                       hasSearch
            />
            <Accordion title="Pokemon"
                       options={allPokemonNames}
                       selectedItems={filters.pokemonNames}
                       onChange={(item) => handleFilterChange('pokemonNames', item)}
                       hasSearch
            />
            <Accordion title="Language"
                       options={allLanguages}
                       selectedItems={filters.languages}
                       onChange={(item) => handleFilterChange('languages', item)}
            />
            <Accordion title="Average Collectability Score"
                       content={
                         <RangeSlider range={scoreRange}
                                      setRange={setScoreRange}
                                      maxValue={100}
                                      minValue={0}
                         />
                       }
            />
            <Accordion title="Average Price"
                       content={
                          <RangeSlider range={priceRange}
                                       setRange={setPriceRange}
                                       maxValue={maxPrice}
                                       minValue={minPrice}
                                       isPriceRange
                          />
                       }
            />
          </div>
          <SidebarSearchBlock searchQuery={searchQuery}
                              handleSearch={handleSearch}
                              handleClearSearch={handleClearSearch}
          />
        </aside>
        <section className="basis-full lg:basis-3/4 lg:pl-4">
          {resultsLoading ?
            <div className="flex items-center justify-center min-h-100">
              <LoadingSpinner/>
            </div>
          :
            <>
              {listItems()}
              {totalCards && totalPages > 1 &&
                <div className="mt-10">
                  <Pagination currentPage={currentPage}
                              totalPages={totalPages}
                              onChange={setCurrentPage}
                    />
                </div>
              }
            </>
          }
        </section>
      </div>
    </main>
  );
};
