import { format } from 'date-fns';
import { 
  ClockIcon, 
  CurrencyDollarIcon, 
  MapPinIcon, 
  UserIcon, 
  CalendarIcon, 
  HandThumbUpIcon,
} from '@heroicons/react/20/solid';

import logo from '../assets/images/tsglogo.svg';

export const AuctionInfo = ({ item }) => {
  const dollarUSLocale = Intl.NumberFormat('en-US');
  const formattedEndTime = item.endTime ? format(new Date(item.endTime), 'yyyy/MM/dd HH:mm:ss') : '';

  return (
    <div className="rounded-lg shadow-lg grid grid-cols-1 md:grid-cols-3 gap-4">
      {item.avgMktValue && (
        <div className="flex items-center p7 text-child-of-light-500">
          <img src={logo} alt="logo" className="w-6 h-4 mr-1" />
          <p className="mr-2">Market price:</p>
          <a href={item.ebayLink} target="_blank" rel="noreferrer" className="p5 text-child-of-light-1000 mr-1">
            ${dollarUSLocale.format(item.avgMktValue.slice(1))}
          </a>
        </div>
      )}
      {item.timeLeft && (
        <div className="flex items-center p7 text-child-of-light-500">
        <p className='flex items-center gap-1 mr-2'><ClockIcon className='w-5 h-5'/>Time Left:</p>
          <a href={item.ebayLink} target="_blank" rel="noreferrer" className="p5 text-child-of-light-1000">
            {item.timeLeft.slice(0, item.timeLeft.length - 4)}
          </a>
        </div>
      )}
      {item.seller_name && (
        <div className="flex items-center p7 text-child-of-light-500">
          <p className='p7 flex items-center gap-1 text-child-of-light-500 mr-1'><UserIcon className="h-5 w-5" />Seller:</p>
          <span className='p5 text-child-of-light-1000'>{item.seller_name}</span>
        </div>
      )}
      {item.currentPrice && (
        <div className="flex items-center gap-2">
          <p className='p7 flex items-center gap-1 text-child-of-light-500'><CurrencyDollarIcon className='w-5 h-5'/>Current Bid Price:</p>
          <a href={item.ebayLink} target="_blank" rel="noreferrer" className="p5 text-child-of-light-1000">
            ${dollarUSLocale.format(item.currentPrice.slice(1))}
          </a>
        </div>
      )}

      {item.endTime && (
        <div className="flex items-center p7 text-child-of-light-500">
          <p className='p7 flex items-center gap-1 text-child-of-light-500 mr-2'><CalendarIcon className="h-5 w-5" />End date:</p>
          <span className='p5 text-child-of-light-1000'>{formattedEndTime}</span>
        </div>
      )}
      {item.seller_positive_feedback !== undefined && item.seller_review_count !== undefined && (
        <div className="flex items-center p7 text-child-of-light-500">
          <p className='p7 flex items-center gap-1 text-child-of-light-500 mr-1'><HandThumbUpIcon className="h-5 w-5" />Seller rating:</p>
          <span className='p5 text-child-of-light-1000 mr-1'>{item.seller_positive_feedback}% positive</span>
          ({item.seller_review_count})
        </div>
      )}
      {item.bids !== undefined && (
        <div className="flex items-center p7 text-child-of-light-500">
          <CurrencyDollarIcon className="h-5 w-5" />
          <span className="mr-2">Number of bids:</span>
          <span className="p5 text-child-of-light-1000">{item.bids}</span>
        </div>
      )}
      <div></div>
      {item.location && (
        <div className="flex items-center p7 text-child-of-light-500">
          <p className='p7 flex items-center gap-1 text-child-of-light-500 mr-1'><MapPinIcon className="h-5 w-5" />Located:</p>
          <span className='p5 text-child-of-light-1000'>{item.location}</span>
        </div>
      )}
    </div>
  );
};