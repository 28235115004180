import React, { useEffect, useState } from 'react';

const Table = ({ companyData }) => {
  if (!companyData || companyData.length === 0) {
    return (
      <div className="mt-6">
        <p className="text-white p2">
          No Data Available
        </p>
      </div>
    )
  }

  return (
    <div className="mt-6 max-h-108.25 overflow-y-scroll">
      <table className="pr-2 pl-2 min-w-full">
        <thead>
          <tr className="bg-not-tonight-100">
            <th className="w-39 text-child-of-light-400 pl-2 py-2 text-left p8">
              GRADE
            </th>
            <th className="w-27.6 text-child-of-light-400 py-2 text-right p8">
              AVERAGE
            </th>
            <th className="w-28 text-child-of-light-400 py-2 text-right p8">
              MINIMUM
            </th>
            <th className="w-27.5 text-child-of-light-400 py-2 text-right p8">
              MEDIAN
            </th>
            <th className="w-27.5 text-child-of-light-400 pr-2 py-2 text-right p8">
              HIGH
            </th>
          </tr>
        </thead>
        <tbody>
          {companyData.map((company, index)=> {
            return (
              <tr className={`${index % 2 === 0 ? 'bg-not-tonight-100' : 'bg-not-tonight-200'} border-t-min border-solid border-not-tonight-300 p6`}
                  key={`snapshot-company-${index}`}
              >
                <td className="pl-2 py-3 text-child-of-light-1000 text-left">
                  {company.grade}
                </td>
                <td className="py-3 text-secondary-blue text-right">
                  {company.average}
                </td>
                <td className="py-3 text-dusk-orange-600 text-right">
                  {company.minimum}
                </td>
                <td className="py-3 text-yellow text-right">
                  {company.median}
                </td>
                <td className="pr-2 py-3 text-welsh-onion-600 text-right">
                  {company.high}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
};

export const Snapshot = ({salesSnapshot}) => {
  const companies = ['PSA', 'BGS', 'CGC'];
  const [activeCompany, setActiveCompany] = useState('PSA')
  const [companyData, setCompanyData] = useState([])

  const grades = [];
  for (let i = 10; i >= 1; i -= 0.5) {
    grades.push(i);
  }
  useEffect(() => {
    let data = [];
    if(!salesSnapshot || Object.keys(salesSnapshot).length === 0) return;
    grades.forEach((grade) => {
      data.push({
        grade: `${activeCompany} ${grade}`,
        average: salesSnapshot[activeCompany.toLowerCase()][grade] ?
          `$${salesSnapshot[activeCompany.toLowerCase()][grade].avg.toFixed(2)}` : '-',
        minimum: salesSnapshot[activeCompany.toLowerCase()][grade] ?
          `$${salesSnapshot[activeCompany.toLowerCase()][grade].min.toFixed(2)}` : '-',
        median: salesSnapshot[activeCompany.toLowerCase()][grade] ?
          `$${salesSnapshot[activeCompany.toLowerCase()][grade].median.toFixed(2)}` : '-',
        high: salesSnapshot[activeCompany.toLowerCase()][grade] ?
          `$${salesSnapshot[activeCompany.toLowerCase()][grade].max.toFixed(2)}` : '-'
      })
    })
    setCompanyData(data)
  }, [activeCompany, salesSnapshot]);

  const handleTabSelect = (index) => {
    setActiveCompany(companies[index])
  };

  const tabs = [
    {
      title: 'PSA',
      content: <Table companyData={companyData} />
    },
    {
      title: 'BGS',
      content: <Table companyData={companyData} />
    },
    {
      title: 'CGC',
      content: <Table companyData={companyData} />
    }
  ];

  return (
    <div className="w-162 h-fit max-h-144.25 bg-not-tonight-100 border-solid border-min border-not-tonight-200 rounded pt-6 pr-6 pb-3 pl-6">
      <div className="flex mb-2">
        <p className="p1 text-not-tonight-1000 pr-2">
          90 Days Price Summary
        </p>
      </div>
      <div className="w-full">
        <div className="flex">
          {companies.map((company) =>
            <div className={`p-2 mr-8 p6 cursor-pointer ${company === activeCompany ? 'text-white border-b  border-solid border-white' : 'text-not-tonight-900'}`}
                 key={`${company}-snapshot`}
                 onClick={() => setActiveCompany(company)}
            >
              {company}
            </div>
          )}
        </div>
        <Table companyData={companyData} />
      </div>
    </div>
  )
};
