import React, { useState, useMemo, useCallback } from 'react';
import CanvasJSReact from '../assets/canvasjs.react';

let CanvasJS = CanvasJSReact.CanvasJS;
CanvasJS.addColorSet("marketIndexColorSet", ["#2D6FEF", "#31CDCD", "#E74ABB"]);
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const timeRanges = [
  { id: 'ALL', title: 'All' },
  { id: '3Y', title: '3 Years' },
  { id: '2Y', title: '2 Years' },
  { id: '1Y', title: '1 Year' },
  { id: '6M', title: '6 Months' },
  { id: '3M', title: '3 Months' }
];

export const PMIChart = ({ data, selectedTab }) => {
  const [time, setTime] = useState('ALL');
  const [isEmptyData, setIsEmptyData] = useState(false);

  const toDate = useCallback((dateStr) => {
    const [year, month, day] = dateStr.split("T")[0].split("-");
    return new Date(year, month - 1, day);
  }, []);

  const filterDataByTime = useCallback(() => {
    if (time === "ALL") return data;

    let timespan;
    if (time === "3M") timespan = 92;
    else if (time === "6M") timespan = 183;
    else if (time === "1Y") timespan = 365;
    else if (time === "2Y") timespan = 730;
    else if (time === "3Y") timespan = 1095; 

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - timespan);

    return data.filter(item => new Date(item.date) >= currentDate);
  }, [time, data]);

  const timeData = useMemo(() => filterDataByTime(), [filterDataByTime]);

  const createDataPoints = useCallback((key) => {
    const points = timeData.map(item => ({
      x: toDate(item.date),
      y: item[key] ? parseFloat(item[key]) : null,
    }));
    
    if (points.length === 0) {
      setIsEmptyData(true);
    } else {
      setIsEmptyData(false);
    }
    
    return points;
  }, [timeData, toDate]);

  const dataSeries = useMemo(() => {
    if (selectedTab === 'pmi500') {
      return [
        {
          name: "PMI 500 Index (PSA) Version",
          type: "spline",
          markerType: "circle",
          markerSize: 0,
          showInLegend: true,
          dataPoints: createDataPoints('psaTotal'),
        },
        {
          name: "PMI 500 Index (CGC Gem Mint 10)",
          type: "spline",
          markerType: "circle",
          markerSize: 0,
          showInLegend: true,
          dataPoints: createDataPoints('cgcGMTotal'),
        },
        {
          name: "PMI 500 Index (CGC Pristine 10)",
          type: "spline",
          markerType: "circle",
          markerSize: 0,
          showInLegend: true,
          dataPoints: createDataPoints('cgcPRTotal'),
        },
      ];
    } else {
      return [
        {
          name: "PSA Total",
          type: "spline",
          markerType: "circle",
          markerSize: 0,
          showInLegend: true,
          dataPoints: createDataPoints('psaTotal'),
        },
        {
          name: "English Total",
          type: "spline",
          markerType: "circle",
          markerSize: 0,
          showInLegend: true,
          dataPoints: createDataPoints('engTotal'),
        },
        {
          name: "Japanese Total",
          type: "spline",
          markerType: "circle",
          markerSize: 0,
          showInLegend: true,
          dataPoints: createDataPoints('jpnTotal'),
        },
      ];
    }
  }, [createDataPoints, selectedTab]);

  const options = useMemo(() => ({
    theme: "none",
    colorSet: "marketIndexColorSet",
    backgroundColor: '#150E1C',
    animationEnabled: true,
    options: {
      plugins: {
        legend: {
          labels: {
            usePointStyle: true,
          },
        }
      }
    },
    title: {
      text: ""
    },
    axisX: {
      tickLength: 16,
      scaleBreaks: {
        autoCalculate: true
      },
      labelFontColor: '#F3F5F8',
      tickColor: '#150E1C',
      lineColor: '#342E3B',
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        lineDashType: "dash",
        color: "#DDD4E9",
        thickness: 1,
        label: {
          enabled: false 
        }
      },
      valueFormatString: 'YYYY MMM'
    },
    axisY: {
      tickLength: 16,
      scaleBreaks: {
        autoCalculate: true
      },
      labelFontColor: '#F3F5F8',
      includesZero: true,
      valueFormatString: '$#,###,###.##',
      tickColor: '#150E1C',
      lineColor: '#150E1C',
      gridColor: '#342E3B'
    },
    toolTip: {
      shared: true,
      borderThickness: 0,
      backgroundColor: "#DDD4E9",
      fontColor: "#181819",
      cornerRadius: 8,
      contentFormatter: function(e) {
        const date = CanvasJS.formatDate(e.entries[0].dataPoint.x, "DD MMM YY");
        let content = `<div class='p-1 p7' style='padding: 5px;'>
                        <p><strong>${date}</strong></p>`;
        for (let i = 0; i < e.entries.length; i++) {
          content += `<p class='mt-2'>${e.entries[i].dataSeries.name}: <strong>$${CanvasJS.formatNumber(e.entries[i].dataPoint.y, "#,###")}</strong></p>`;
        }
        content += `</div>`;
        return content;
      },
      custom: {
        style: 'box-shadow: 0px 4px 20px 0px #000'
      }
    },
    data: dataSeries,
    legend: {
      horizontalAlign: "left",
      verticalAlign: "bottom",
      fontSize: 16,
      fontColor: "#FCFDFD",
      markerMargin: 8,
      markerSize: 12,
      itemWrap: false,
      cursor: "pointer",
      itemclick: function (e) {
        if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      }
    },
  }), [dataSeries]);

  return (
    <div className="bg-not-tonight-100 rounded-lg mt-8 text-child-of-light-1000 pmi-chart">
      <div className="flex justify-between items-center mb-6">
        <div className="inline-block bg-not-tonight-200 rounded px-1 py-1">
          {timeRanges.map((range) =>
            <button className={`${time !== range.id ? 'text-not-tonight-800' : 'text-white bg-not-tonight-300'} px-8 py-1 rounded`}
                    key={range.id}
                    onClick={() => setTime(range.id)}
            >
              {range.title}
            </button>
          )}
        </div>
      </div>
      {isEmptyData ? (
        <div className="p1 h-100 flex justify-center items-center text-child-of-light-500">
          No data found for this time period. Please select a different range.
        </div>
      ) : (
        <CanvasJSChart options={options} />
      )}
      <div className="p7 text-child-of-light-500 mt-4">
        * PMI 500 Index CGC Gem Mint 10 (or Pristine 10) means the CGC 10 prices uses the Gem Mint 10 market prices (or Pristine 10) for graded 10 cards, it does not mean this index only contains CGC 10.
      </div>
    </div>
  );
};
