import React, { useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactGTM from 'react-gtm-module';
// import CookieConsent, { Cookies, OPTIONS } from 'react-cookie-consent';

import './App.css';
import {
  // COOKIES_CONSENT_ACCEPT_ALL,
  // COOKIES_CONSENT_ACCEPT_REQUIRED,
  // COOKIES_CONSENT_COOKIE_NAME,
  DEFAULT_PAGE_TITLE,
} from './lib/constants';
import TCGFishApi from './lib/TCGFishApi';
import { AuthContext } from './contexts/AuthContext';
import { ModalsContext } from './contexts/ModalsContext';
import { Card } from './pages/Card';
import { Home } from './pages/Home';
import { Catalog } from './pages/Catalog';
import { UnderValueBot } from './pages/UnderValueBot';
import Ranking from './pages/Ranking/Ranking';
import Market from './pages/Market/Market';
import MasterSetValue from './pages/MasterSetValue/MasterSetValue';
import RealtimeAuction from './pages/RealtimeAuction/RealtimeAuction';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
// import ScrollToTop from './components/ScrollToTop';
import { Header } from './components/Header';
import { Page } from './components/Page';
import { Footer } from './components/Footer';
import Documents from './components/Documents/Documents';
import { MarketIndex } from './pages/MarketIndex';

// import UnderValueBot2 from './pages/UnderValueBot2/UnderValueBot2';
// import MarketTrend from './pages/MarketTrend/MarketTrend';
// import Maintenance from './pages/Maintenance/Maintenance';

const showDocumentsOnly = window.location.pathname === '/documents';
const showEmailVerificationModal = window.location.pathname === '/verify';
const showChangePasswordModal = window.location.pathname === '/reset-password';

const getWindowDimensions = () => {
  const width = window.innerWidth || null;
  const height = window.innerHeight || null;

  return { width, height };
};

document.title = DEFAULT_PAGE_TITLE;

// App checks cookies, terms of service and privacy policy consent,
// sets user session, manages routing.
//
// Components hierarchy:
// Root
//   App
//     Contexts
//       Page
//         Component (or loading spinner or UnauthorizedFallbackComponent)
export default function App() {
  const [user, setUser] = useState(null);

  const [modals, setModals] = useState({
    showEmailVerificationModal: showEmailVerificationModal,
    showLogInModal: false,
    showSignUpModal: false,
    showForgotPasswordModal: false,
    showChangePasswordModal: showChangePasswordModal,
  });

  const [state, setState] = useState({
    showConsentWall: false, // Optimistically do now show consent wall
    showConsentButtons: false,
  });
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const windowWidth = useMemo(() => {
    return windowDimensions.width || 0;
  }, [windowDimensions.width]);

  // const handleCookieConsentCheckboxChange = (evt) => {
  //   setState((prevState) => {
  //     return {
  //       ...prevState,
  //       showConsentButtons: evt.target.checked,
  //     };
  //   });
  // };

  // const handleAcceptCookie = () => {
  //   Cookies.set(COOKIES_CONSENT_COOKIE_NAME, COOKIES_CONSENT_ACCEPT_ALL);
  //   setState((prevState) => {
  //     return {
  //       ...prevState,
  //       showConsentWall: false,
  //       showConsentButtons: false,
  //     };
  //   });
  //   ReactGTM.initialize({ gtmId: process.env.REACT_APP_GTM_ID });
  // };

  // const handleDeclineCookie = () => {
  //   Cookies.set(COOKIES_CONSENT_COOKIE_NAME, COOKIES_CONSENT_ACCEPT_REQUIRED);
  //   Cookies.remove('_ga');
  //   setState((prevState) => {
  //     return {
  //       ...prevState,
  //       showConsentWall: false,
  //       showConsentButtons: false,
  //     };
  //   });
  // };

  useEffect(() => {
    if (showDocumentsOnly || user) {
      return;
    }

    TCGFishApi
      .get('user/user-info')
      .then((res) => {
        setUser({
          id: res.data.userInfo.id,
          char: res.data.userInfo.fullname.charAt(0).toUpperCase(),
          fullName: res.data.userInfo.fullname,
          email: res.data.userInfo.email,
        });

        ReactGTM.dataLayer({
          dataLayer: { userId: res.data.userInfo?.id || null },
        });
      })
      .catch((_err) => {
        setUser(undefined);
        // It's ok to be signed out
        // console.error('Failed to get user/user-info', err);
      });
  });

  useEffect(() => {
    if (showDocumentsOnly) {
      return;
    }

    if (!user) {
      ReactGTM.dataLayer({ dataLayer: { userId: null } });
    }
  }, [user]);

  // useEffect(() => {
  //   // Email verification should work without cookie consent.
  //   // Because it is also being used for mobile app user flow.
  //   if (showDocumentsOnly || showEmailVerificationModal) {
  //     return;
  //   }
  //
  //   const consent = Cookies.get(COOKIES_CONSENT_COOKIE_NAME);
  //   if (consent === COOKIES_CONSENT_ACCEPT_ALL) {
  //     handleAcceptCookie();
  //     return;
  //   }
  //
  //   // Cookies consent cookie exist
  //   if (consent) {
  //     return;
  //   }
  //
  //   setState((prevState) => {
  //     return {
  //       ...prevState,
  //       showConsentWall: true,
  //       showConsentButtons: false,
  //     };
  //   });
  // }, []);

  // TODO: remove dependency on windowWidth
  useEffect(() => {
    let timeout;
    function handleResize() {
      timeout && clearTimeout(timeout);
      timeout = setTimeout(() => {
        if (windowWidth === window.innerWidth) {
          return;
        }
        setWindowDimensions(getWindowDimensions());
      }, 100);
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(timeout);
    };
  }, [window.innerWidth]);

  function DefaultPage() {
    window.location.pathname = '/';
    return <Home />;
  }

  // Show without app layout for embedding into mobile app.
  if (showDocumentsOnly) {
    return (
      <BrowserRouter>
        <Documents />
      </BrowserRouter>
    );
  }

  return (
    // <BrowserRouter>
    //   <ScrollToTop>
    //     <Routes>
    //       <Route element={<Maintenance />} />
    //     </Routes>
    //   </ScrollToTop>
    // </BrowserRouter>

    <BrowserRouter>
      <div className={`flex flex-col ${state.showConsentWall ? 'h-screen overflow-y-hidden blur-sm' : ''}`}>
        <AuthContext.Provider value={{ user, setUser }}>
          <ModalsContext.Provider value={{ modals, setModals }}>
            {/*<ScrollToTop>*/}
            <Header />
            <Routes>
              <Route path="/"
                     exact={true}
                     element={
                       <Page>
                         <Home />
                       </Page>
                     }
              />

              <Route path="/verify"
                     element={
                       <Page>
                         <Home />
                       </Page>
                     }
              />

              <Route path="/reset-password"
                     element={
                       <Page>
                         <Home />
                       </Page>
                     }
              />

              <Route path="/search"
                     element={
                       <Page requireAuthenticatedUser={true}
                             UnauthorizedFallbackComponent={<Home />}
                       >
                         <Catalog />
                       </Page>
                     }
              />

              <Route path="/product"
                     element={
                       <Page>
                         <Card />
                       </Page>
                     }
              />

              <Route path="/undervalue-bot"
                     element={
                       <Page>
                         <UnderValueBot />
                       </Page>
                     }
              />

              <Route path="/auction-monitor"
                     element={
                       <Page requireAuthenticatedUser={true}
                             UnauthorizedFallbackComponent={<Home />}
                       >
                         <RealtimeAuction />
                       </Page>
                     }
              />

              <Route path="/setvalue"
                     element={
                       <Page requireAuthenticatedUser={true}
                             UnauthorizedFallbackComponent={<Home />}
                       >
                         <MasterSetValue windowWidth={windowWidth} />
                       </Page>
                     }
              />

              <Route path="/ranking"
                     element={
                       <Page requireAuthenticatedUser={true}
                             UnauthorizedFallbackComponent={<Home />}
                       >
                         <Ranking />
                       </Page>
                     }
              />

              {/*<Route path='/market-trend'*/}
              {/*       element={*/}
              {/*         <Page>*/}
              {/*           <MarketTrend />*/}
              {/*         </Page>*/}
              {/*       }*/}
              {/*/>*/}

              <Route path="/market"
                     element={
                       <Page requireAuthenticatedUser={true}
                             UnauthorizedFallbackComponent={<Home />}
                       >
                         <Market windowWidth={windowWidth} />
                       </Page>
                     }
              />

              <Route path="/market-index"
                     element={
                       <Page>
                         <MarketIndex />
                       </Page>
                     }
              />

              <Route path="/privacy-policy"
                     element={
                       <Page>
                         <PrivacyPolicy />
                       </Page>
                     }
              />

              <Route path="/terms-of-use"
                     element={
                       <Page>
                         <TermsOfUse />
                       </Page>
                     }
              />

              <Route path="/documents"
                     element={
                       <Page>
                         <Documents />
                       </Page>
                     }
              />

              <Route path="*"
                     element={
                       <Page>
                         <DefaultPage />
                       </Page>
                     }
              />
            </Routes>
            <Footer />
            {/*</ScrollToTop>*/}
          </ModalsContext.Provider>
        </AuthContext.Provider>
      </div>
      {/* TODO: tailwind classes */}
      {/*{state.showConsentWall && (*/}
      {/*  <CookieConsent disableStyles={true}*/}
      {/*                 overlay={true}*/}
      {/*                 overlayClasses="cookie_consent__overlay"*/}
      {/*                 containerClasses="cookie_consent__container"*/}
      {/*                 contentClasses="cookie_consent__content"*/}
      {/*                 buttonWrapperClasses="cookie_consent__button_wrapper"*/}
      {/*                 cookieValue={COOKIES_CONSENT_ACCEPT_ALL}*/}
      {/*                 declineCookieValue={COOKIES_CONSENT_ACCEPT_REQUIRED}*/}
      {/*                 cookieName={COOKIES_CONSENT_COOKIE_NAME}*/}
      {/*                 location={OPTIONS.NONE}*/}
      {/*  >*/}
      {/*    <h1>*/}
      {/*      TCG Fish User Agreement*/}
      {/*    </h1>*/}
      {/*    <p>*/}
      {/*      By using this website, you acknowledge that you have read and accept*/}
      {/*      the following Terms of Use and Privacy Policy. This website uses*/}
      {/*      cookies to enhance the user experience and collect usage statistics.*/}
      {/*    </p>*/}
      {/*    <div className="cookie_consent__scroll_view">*/}
      {/*      <Documents />*/}
      {/*    </div>*/}
      {/*    <div className="cookie_consent__buttons_section">*/}
      {/*      <div className="cookie_consent__buttons_section_left">*/}
      {/*        <input className="cookie_consent__checkbox"*/}
      {/*               type="checkbox"*/}
      {/*               id="consent_checkbox"*/}
      {/*               onChange={handleCookieConsentCheckboxChange}*/}
      {/*        />*/}
      {/*        <label className="pl-2"*/}
      {/*               htmlFor="consent_checkbox"*/}
      {/*        >*/}
      {/*          Yes, I have read and accept the user agreement.*/}
      {/*        </label>*/}
      {/*      </div>*/}
      {/*      {state.showConsentButtons && (*/}
      {/*        <div className="cookie_consent__buttons_section_right">*/}
      {/*          <div className="cookie_consent__button cookie_consent__button_accept_essential"*/}
      {/*               onClick={handleDeclineCookie}*/}
      {/*          >*/}
      {/*            Use Essential*/}
      {/*            <br />*/}
      {/*            Cookie Only*/}
      {/*          </div>*/}
      {/*          <div className="cookie_consent__button cookie_consent__button_accept_all"*/}
      {/*               onClick={handleAcceptCookie}*/}
      {/*          >*/}
      {/*            Accept All*/}
      {/*            <br />*/}
      {/*            Cookies*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      )}*/}
      {/*    </div>*/}
      {/*  </CookieConsent>*/}
      {/*)}*/}
    </BrowserRouter>
  );
}
