import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { EyeIcon, EyeSlashIcon, CheckCircleIcon } from '@heroicons/react/20/solid';

import logo from '../assets/images/modal_logo.svg';
import TCGFishApi from '../lib/TCGFishApi';
import { IconButtonBig } from './IconButtonBig';
import { PrimaryLargeButton } from './PrimaryLargeButton';
import {
  ALL_FIELDS_ARE_REQUIRED_MESSAGE,
  MIN_PASSWORD_LENGTH,
  PASSWORDS_DO_NOT_MATCH_MESSAGE,
  UNEXPECTED_ERROR_MESSAGE
} from '../lib/constants';

const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: ''
};

export const ChangePasswordModal = ({ close, showLogInModal }) => {
  const search = useLocation().search;
  const resetCode = new URLSearchParams(search).get('code');

  const [formValues, setFormValues] = useState(initialValues);
  const [oldPasswordType, setOldPasswordType] = useState('password');
  const [newPasswordType, setNewPasswordType] = useState('password');
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');
  const [errors, setErrors] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    generic: ''
  });
  const [successfullyReset, setSuccessfullyReset] = useState(false);

  const toggleOldPasswordType = () => {
    if (oldPasswordType === 'password') {
      setOldPasswordType('text');
    } else {
      setOldPasswordType('password');
    }
  };

  const toggleNewPasswordType = () => {
    if (newPasswordType === 'password') {
      setNewPasswordType('text');
    } else {
      setNewPasswordType('password');
    }
  };

  const toggleConfirmPasswordType = () => {
    if (confirmPasswordType === 'password') {
      setConfirmPasswordType('text');
    } else {
      setConfirmPasswordType('password');
    }
  };

  const handleSubmitResetWithOldPassword = (e) => {
    e.preventDefault();
    setErrors({});

    const { oldPassword, newPassword, confirmPassword } = formValues;

    if (oldPassword === '' || newPassword === '' || confirmPassword === '') {
      setErrors({ generic: ALL_FIELDS_ARE_REQUIRED_MESSAGE });
      return;
    }

    const newErrors = {};

    if (newPassword.length < MIN_PASSWORD_LENGTH) {
      newErrors.newPassword = `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`;
    }

    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = PASSWORDS_DO_NOT_MATCH_MESSAGE;
    }

    for (const property in newErrors) {
      if (newErrors[property]) {
        setErrors(newErrors);
        return;
      }
    }

    TCGFishApi
      .post(
        'user/reset-password',
        {
          oldPassword: oldPassword,
          newPass1: newPassword,
          newPass2: confirmPassword
        }
      )
      .then((_res) => { setSuccessfullyReset(true); })
      .catch((err) => {
        console.error('POST user/reset-password failed', err);

        if (err.response?.data?.errors?.length) {
          err.response.data.errors.forEach((errObj) => {
            newErrors[errObj.property] = errObj.message;
          });
          setErrors(newErrors);
          return;
        }

        setErrors({ generic: UNEXPECTED_ERROR_MESSAGE });
      });
  };

  const handleSubmitResetWithCode = (e) => {
    e.preventDefault();

    setErrors({});

    const { newPassword, confirmPassword } = formValues;

    if (newPassword === '' || confirmPassword === '') {
      setErrors({ generic: ALL_FIELDS_ARE_REQUIRED_MESSAGE });
      return;
    }

    const newErrors = {};

    if (newPassword.length < MIN_PASSWORD_LENGTH) {
      newErrors.newPassword = `Password must be at least ${MIN_PASSWORD_LENGTH} characters long.`;
    }

    if (newPassword !== confirmPassword) {
      newErrors.confirmPassword = PASSWORDS_DO_NOT_MATCH_MESSAGE;
    }

    for (const property in newErrors) {
      if (newErrors[property]) {
        setErrors(newErrors);
        return;
      }
    }

    TCGFishApi
      .post(
        `user/reset-password/${resetCode}`,
        {
          newPass1: newPassword,
          newPass2: confirmPassword
        }
      )
      .then((_res) => { setSuccessfullyReset(true); })
      .catch((err) => {
        console.error('POST user/reset-password/:resetCode failed', err);

        if (err.response?.data?.errors?.length) {
          err.response.data.errors.forEach((errObj) => {
            newErrors[errObj.property] = errObj.message;
          });
          setErrors(newErrors);
          return;
        }

        setErrors({ generic: UNEXPECTED_ERROR_MESSAGE });
      });
  };

  useEffect(() => {
    const timeoutID = setTimeout(() => {
      let confirmPasswordMsg = '';
      if (formValues.newPassword !== formValues.confirmPassword) {
        confirmPasswordMsg = PASSWORDS_DO_NOT_MATCH_MESSAGE;
      }

      setErrors((prevErrors) => {
        return {
          ...prevErrors,
          confirmPassword: confirmPasswordMsg
        };
      });
    }, 300);

    return () => { clearTimeout(timeoutID) };
  }, [formValues.newPassword, formValues.confirmPassword]);

  return (
    <div className="flex items-center fixed top-0 left-0 w-full h-screen overflow-x-hidden overflow-y-auto z-50 bg-modal-shadow bg-opacity-81 backdrop-blur"
         onClick={close}
    >
      <div className="flex flex-col flex-shrink-0 relative w-133 mx-auto rounded-lg border-min border-not-tonight-200 bg-purple-100 overflow-hidden"
           onClick={(e) => e.stopPropagation()}
      >
        <svg className="absolute top-0 left-0"
             width="302" height="542" viewBox="0 0 302 542" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M-63.8376 436.985L288.63 143.437L-63.8376 -150.111L-63.8376 436.985Z" stroke="#341956"
                  strokeWidth="25.4159"/>
            <path d="M-93.9055 436.985L258.562 143.437L-93.9055 -150.111L-93.9055 436.985Z" stroke="#221139"
                  strokeWidth="25.4159"/>
            <path
              d="M235.279 152.47C240.922 147.77 240.922 139.104 235.28 134.404L-119.63 -161.178C-127.287 -167.555 -138.908 -162.11 -138.908 -152.145L-138.908 439.019C-138.908 448.984 -127.287 454.429 -119.63 448.052L235.279 152.47Z"
              fill="#11081D"/>
          </g>
        </svg>
        <svg className="absolute top-0 right-0"
             width="532" height="749" viewBox="0 0 532 749" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M570.754 757.063L110.575 386.082L570.754 15.1003L570.754 757.063Z" stroke="#341956"
                  strokeWidth="32.0696"/>
            <path d="M611.029 757.063L150.849 386.082L611.029 15.1003L611.029 757.063Z" stroke="#221139"
                  strokeWidth="32.0696"/>
            <path
              d="M186.846 397.682C179.394 391.743 179.394 380.42 186.846 374.482L655.85 0.761292C665.566 -6.9809 679.925 -0.0623779 679.925 12.3612L679.925 759.802C679.925 772.226 665.566 779.144 655.85 771.402L186.846 397.682Z"
              fill="#0C0415"/>
          </g>
        </svg>
        <div className="self-end mt-3.5 mr-5.5 z-10">
          <IconButtonBig Icon={XMarkIcon}
                         onClick={close}
          />
        </div>
        <img className="w-20.75 h-12.5 flex-shrink-0 mx-auto -mt-5.5 z-10"
             src={logo}
             alt="TCGFish logo"
        />
        {successfullyReset &&
          <>
            <CheckCircleIcon className="w-10 h-10 mx-auto mt-8 text-welsh-onion-600 z-10" />
            <h2 className="text-not-tonight-1000 h6 mx-auto mb-4 text-center z-10 mt-2">
              Password was successfully changed!
            </h2>
            <p className="w-81.25 text-center p4 text-child-of-light-400 mx-auto mb-8 z-10">
              You can now log in with your email and new password
            </p>
            <PrimaryLargeButton className="w-105 mx-auto z-10 mb-8"
                                onClick={showLogInModal}
                                text="Log In"
            />
          </>
        }
        {!successfullyReset &&
          <>
            <h3 className="text-not-tonight-1000 h8 mx-auto mt-8 mb-7 z-10">
              Set new password
            </h3>
            <form className="flex flex-col w-105 mx-auto gap-5 z-10"
                  onSubmit={(evt) => {
                    if (resetCode) {
                      return handleSubmitResetWithCode(evt);
                    }

                    handleSubmitResetWithOldPassword(evt);
                  }}
            >
              {!resetCode &&
                <div className={`w-full flex flex-col flex-shrink-0 items-start ${errors.oldPassword ? 'h-17.25' : ''}`}>
                  <div className="w-full flex flex-shrink-0 items-center relative">
                    <input
                      className={`w-full flex flex-shrink-0 h-12 py-2.75 pl-4 pr-12 rounded-lg bg-not-tonight-200 outline-none text-white hover:border-min hover:border-not-tonight-300 focus:bg-purple-100 focus:placeholder:text-purple-100 ${errors.password ? 'border-dusk-orange-700 border-min' : ''}`}
                      type={oldPasswordType}
                      placeholder="Current Password"
                      name="oldPassword"
                      value={formValues.oldPassword}
                      onChange={(e) => setFormValues({ ...formValues, oldPassword: e.target.value })}
                    />
                    {oldPasswordType === 'password' ?
                      <EyeSlashIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                                    onClick={toggleOldPasswordType}
                      />
                      :
                      <EyeIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                               onClick={toggleOldPasswordType}
                      />
                    }
                  </div>
                  {errors.oldPassword &&
                    <p className="mt-1 text-dusk-orange-700 p7">
                      {errors.oldPassword}
                    </p>
                  }
                </div>
              }
              <div className={`w-full flex flex-col flex-shrink-0 items-start ${errors.newPassword ? 'h-17.25' : ''}`}>
                <div className="w-full flex flex-shrink-0 items-center relative">
                  <input className={`w-full flex flex-shrink-0 h-12 py-2.75 pl-4 pr-12 rounded-lg bg-not-tonight-200 outline-none text-white hover:border-min hover:border-not-tonight-300 focus:bg-purple-100 focus:placeholder:text-purple-100 ${errors.confirmPassword ? 'border-dusk-orange-700 border-min' : ''}`}
                         type={newPasswordType}
                         placeholder="New Password"
                         name="newPassword"
                         value={formValues.newPassword}
                         onChange={(e) => setFormValues({ ...formValues, newPassword: e.target.value })}
                  />
                  {newPasswordType === 'password' ?
                    <EyeSlashIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                                  onClick={toggleNewPasswordType}
                    />
                  :
                    <EyeIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                             onClick={toggleNewPasswordType}
                    />
                  }
                </div>
                {errors.newPassword &&
                  <p className="mt-1 text-dusk-orange-700 p7">
                    {errors.newPassword}
                  </p>
                }
              </div>
              <div className={`w-full flex flex-col flex-shrink-0 items-start ${errors.confirmPassword ? 'h-17.25' : ''}`}>
                <div className="w-full flex flex-shrink-0 items-center relative">
                  <input className={`w-full flex flex-shrink-0 h-12 py-2.75 pl-4 pr-12 rounded-lg bg-not-tonight-200 outline-none text-white hover:border-min hover:border-not-tonight-300 focus:bg-purple-100 focus:placeholder:text-purple-100 ${errors.confirmPassword ? 'border-dusk-orange-700 border-min' : ''}`}
                         type={confirmPasswordType}
                         placeholder="Confirm Password"
                         name="confirmPassword"
                         value={formValues.confirmPassword}
                         onChange={(e) => setFormValues({ ...formValues, confirmPassword: e.target.value })}
                  />
                  {confirmPasswordType === 'password' ?
                    <EyeSlashIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                                  onClick={toggleConfirmPasswordType}
                    />
                  :
                    <EyeIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                             onClick={toggleConfirmPasswordType}
                    />
                  }
                </div>
                {errors.confirmPassword &&
                  <p className="mt-1 text-dusk-orange-700 p7">
                    {errors.confirmPassword}
                  </p>
                }
              </div>
              {errors.generic &&
                <p className="p7 text-dusk-orange-700">
                  {errors.generic}
                </p>
              }
              <PrimaryLargeButton className="flex w-105 h-12 mb-11.5"
                                  text="Save password"
                                  onClick={(evt) => {
                                    if (resetCode) {
                                      return handleSubmitResetWithCode(evt);
                                    }

                                    handleSubmitResetWithOldPassword(evt);
                                  }}
              />
            </form>
          </>
        }
      </div>
    </div>
  );
};
