import React from 'react'
import { Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const CustomStyledTooltip = styled(({ className, ...props }) => (
  <Tooltip { ...props } classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background-color: #221139;
    color: #FCFDFD;
    padding: 0.75rem 1rem;
    box-shadow: 0 4px 20px 0 #000000;
    border-radius: 0.5rem;
  }
`;

export const CustomTooltip = ({ title, children }) => (
  <CustomStyledTooltip title={<span className="p7">{ title }</span>}
                       placement="top-start"
  >
    { children }
  </CustomStyledTooltip>
);
