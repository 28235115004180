import React from 'react';
import { Switch } from '@mui/material';

export const CustomSwitch = ({ checked, onChange }) => (
    <Switch checked={checked}
            onChange={onChange}
            color="default"
            sx={{
              width: 55,
              height: 32,
              padding: 0,
              marginRight: 0.5,
              '& .MuiSwitch-switchBase': {
                padding: 0,
                transform: 'translateX(2px)',
                top: 2,
                '&.Mui-checked': {
                  transform: 'translateX(25px)',
                  color: '#fff',
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#6D28D9',
                    opacity: 1,
                    border: 0,
                  },
                  '& .MuiSwitch-thumb': {
                    backgroundColor: '#fff',
                  },
                },
              },
              '& .MuiSwitch-thumb': {
                backgroundColor: '#615C66',
                width: 28,
                height: 28,
                '&:before': {
                  content: '""',
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  left: 0,
                  top: 0,
                },
              },
              '& .MuiSwitch-track': {
                borderRadius: 32 / 2,
                backgroundColor: '#393939',
                opacity: 1,
              },
            }}
    />
);
