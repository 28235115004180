const normalizeGrade = (str) => {
  if (!str) {
    return 'Not Available';
  }
  if (str === 'BGS 11') {
    return 'BGS Black Label';
  }

  return str;
};

const numFormatter = (num) => {
  if (num > 999 && num < 1000000) {
    return (num / 1000).toFixed(1) + 'K';
  } else if (num > 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num < 900) {
    return num;
  }
};

module.exports = {
  normalizeGrade: normalizeGrade,
  numFormatter: numFormatter
}
