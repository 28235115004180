import React, { useEffect, useState } from 'react';

const Table = ({ companyData }) => {
  if (!companyData?.length) {
    return (
      <div className="mt-6">
        <p className="text-white p2">
          No Data Available
        </p>
      </div>
    );
  }

  return (
    <div className="mt-6 max-h-108.25 overflow-y-scroll">
      <table className="pr-2 pl-2 min-w-full">
        <thead>
          <tr className="bg-not-tonight-100">
            <th className="w-26.5 text-child-of-light-400 pl-2 py-2 text-left p8">
              GRADE
            </th>
            <th className="w-26.5 text-child-of-light-400 py-2 text-left p8">
              VOLUME
            </th>
            <th className="w-33 text-child-of-light-400 py-2 text-left p8">
              LAST SOLD DATE
            </th>
            <th className="w-32 text-child-of-light-400 py-2 text-right p8">
              LAST SOLD
            </th>
            <th className="w-32 text-child-of-light-400 pr-2 py-2 text-right p8">
              AVG (PAST 3 SOLD)
            </th>
          </tr>
        </thead>
        <tbody>
          {companyData.map((company, index)=> {
            return (
              <tr className={`p6 text-child-of-light-1000 border-t border-solid border-not-tonight-300 ${index % 2 === 0 ? 'bg-not-tonight-100' : 'bg-not-tonight-200'}`}
                  key={`statistics-company-${index}`}
              >
                <td className="pl-2 py-3 text-left">
                  {company.grade}
                </td>
                <td className="py-3 text-left">
                  {company.volume}
                </td>
                <td className="py-3 text-left">
                  {company.date}
                </td>
                <td className="py-3 text-right">
                  {company.lastSold}
                </td>
                <td className="pr-2 py-3 text-right">
                  {company.avg}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
};

export const Statistics = ({ statistics }) => {
  const companies = ['PSA', 'BGS', 'CGC'];
  const [activeCompany, setActiveCompany ] = useState('PSA')
  const [companyData, setCompanyData ] = useState([]);

  const grades = [10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5, 4.5, 4, 3.5, 3, 2.5, 2, 1.5, 1];

  useEffect(() => {
    let data = [];
    grades.forEach((grade) => {
      if (!statistics || !Object.keys(statistics).length) {
        return;
      }

      const gradeSales = statistics[activeCompany.toLowerCase()][grade];
      if (gradeSales) {
        let dateStr = '- - - -';
        if (gradeSales.lastSaleDateMs) {
          const date = new Date();
          date.setTime(gradeSales.lastSaleDateMs);
          dateStr = date.toJSON().slice(0, 10).split('-').reverse().join('-');
        }
        data.push({
          grade: `${activeCompany} ${grade}`,
          volume: gradeSales.volume,
          date: dateStr,
          lastSold: `$${gradeSales.lastSold}`,
          avg: `$${gradeSales.last3Avg.toFixed(2)}`
        });
      }
    });
    setCompanyData(data);
  }, [activeCompany, statistics]);

  return (
    <div className="w-162 h-fit max-h-144.25 bg-not-tonight-100 border-solid border-min border-not-tonight-200 rounded pt-6 pr-6 pb-3 pl-6">
      <div className="flex mb-2">
        <p className="p1 text-child-of-light-1000 pr-2">
          Overall Sales Statistics
        </p>
        <span className="p3 text-child-of-light-1000">
          (2019-{new Date().getFullYear()})
        </span>
      </div>
      <div className="w-full">
        <div className="flex">
          {companies.map((company) =>
            <div className={`p-2 mr-8 p6 cursor-pointer ${company === activeCompany ? 'text-white border-b  border-solid border-white' : 'text-not-tonight-900'}`}
                 key={`${company}-statistics`}
                 onClick={() => setActiveCompany(company)}
            >
              {company}
            </div>
          )}
        </div>
        <Table companyData={companyData}/>
      </div>
    </div>
  );
};
