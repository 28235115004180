import React from 'react';

export const TextButton = ({ text, Icon, disabled, onClick }) => {
  const baseStyle = 'flex items-center justify-center pl-2 pr-3 py-3 rounded-lg text-link-purple';
  const enabledStyle = 'bg-transparent hover:bg-not-tonight-100 cursor-pointer';
  const disabledStyle = 'text-not-tonight-400 cursor-not-allowed';

  return (
    <button
      className={`${baseStyle} ${disabled ? disabledStyle : enabledStyle}`}
      disabled={disabled}
      onClick={onClick}
    >
      {Icon && <Icon className="h-5 w-5 text-link-purple mr-2" />}
      { text }
    </button>
  );
};
