import React from 'react';
import { HandThumbUpIcon } from '@heroicons/react/20/solid';

export const DealChip = ({ measure }) => {
  let dealText = '';
  let thumbsCount = 0;

  if (measure < 1 || measure > 5) {
    return <div>
             Measure should be between 1 and 5
           </div>;
  }

  if (measure === 1 || measure === 2) {
    dealText = 'GOOD DEAL';
    thumbsCount = measure;
  } else if (measure === 3 || measure === 4) {
    dealText = 'GREAT DEAL';
    thumbsCount = measure;
  } else if (measure === 5) {
    dealText = 'AMAZING DEAL';
    thumbsCount = measure;
  }

  return (
    <div className="p8 font-medium flex items-center justify-center bg-not-tonight-300 text-child-of-light-700 py-2 px-3 rounded-full">
      {Array.from({ length: thumbsCount }).map((_, index) =>
        <HandThumbUpIcon key={index}
                         className="h-4 w-4 mx-1"
        />
      )}
      <span className="ml-2 truncate">
        { dealText }
      </span>
    </div>
  );
};
