import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { CheckCircleIcon } from '@heroicons/react/20/solid';

import logo from '../assets/images/modal_logo.svg';
import { ModalsContext } from '../contexts/ModalsContext';
import TCGFishApi from '../lib/TCGFishApi';
import { IconButtonBig } from './IconButtonBig';
import { INVALID_EMAIL_VERIFICATION_CODE_ERROR_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../lib/constants';
import { LoadingSpinner } from './LoadingSpinner';

export const EmailVerificationModal = () => {
  const { setModals } = useContext(ModalsContext);
  const navigate = useNavigate();
  const search = useLocation().search;
  const verificationCode = new URLSearchParams(search).get('code');
  const [requestInProgress, setRequestInProgress] = useState(false);
  const [verificationSuccessText, setVerificationSuccessText] = useState('');
  const [errors, setErrors] = useState({ generic: '' });

  const closeAndNavigateHome = () => {
    setModals((prevModals) => {
      return {
        ...prevModals,
        showEmailVerificationModal: false,
        showLogInModal: true
      }
    });
    navigate('/');
  };

  useEffect(() => {
    if (!verificationCode) {
      setErrors({ generic: INVALID_EMAIL_VERIFICATION_CODE_ERROR_MESSAGE });
      return;
    }
    if (requestInProgress) {
      return;
    }

    setErrors({});
    setRequestInProgress(true);
    let timeout;
    TCGFishApi
      .get(`user/verify/${verificationCode}`)
      .then((res) => {
        setVerificationSuccessText(res.data.message);
        timeout = setTimeout(() => { closeAndNavigateHome(); }, 1500);
      })
      .catch((err) => {
        console.error(`Failed to get user/verify/: ${err}`);

        const newErrors = {};
        if (err.response?.data?.errors?.length) {
          err.response.data.errors.forEach((errObj) => {
            newErrors[errObj.property] = errObj.message;
          });

          setErrors(newErrors);
          return;
        }

        setErrors({ generic: UNEXPECTED_ERROR_MESSAGE });
      })
      .finally(() => {
        setRequestInProgress(false);
      });

    return () => { clearTimeout(timeout); }
  }, [verificationCode]);

  if (requestInProgress) {
    return (
      // TODO: custom classes to tailwind.
      <div className="app__preloader__whole">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="flex items-center fixed top-0 left-0 w-full h-screen overflow-x-hidden overflow-y-auto z-50 bg-modal-shadow bg-opacity-81 backdrop-blur"
         onClick={closeAndNavigateHome}
    >
      <div className="flex flex-col flex-shrink-0 relative w-133 mx-auto rounded-lg border-min border-not-tonight-200 bg-purple-100 overflow-hidden"
           onClick={(e) => e.stopPropagation()}
      >
        <svg className="absolute top-0 left-0"
             width="231" height="468" viewBox="0 0 231 468" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M-98.3383 374.099L218.169 110.5L-98.3383 -153.099L-98.3383 374.099Z" stroke="#341956" strokeWidth="22.8228"/>
            <path d="M-125.338 374.099L191.169 110.5L-125.338 -153.099L-125.338 374.099Z" stroke="#221139" strokeWidth="22.8228"/>
            <path d="M170.261 118.611C175.328 114.391 175.329 106.609 170.261 102.389L-148.439 -163.036C-155.315 -168.763 -165.75 -163.873 -165.75 -154.926L-165.75 375.925C-165.75 384.873 -155.315 389.763 -148.439 384.036L170.261 118.611Z"
                  fill="#11081D"/>
          </g>
        </svg>
        <svg className="absolute top-0 left-0"
             width="520" height="569" viewBox="0 0 520 569" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M510.167 613.101L98.8366 281.5L510.167 -50.1009L510.167 613.101Z" stroke="#341956" strokeWidth="28.6654"/>
            <path d="M546.167 613.101L134.837 281.5L546.167 -50.1009L546.167 613.101Z" stroke="#221139" strokeWidth="28.6654"/>
            <path d="M167.012 291.869C160.351 286.561 160.351 276.439 167.012 271.131L586.23 -62.9178C594.915 -69.8382 607.75 -63.6541 607.75 -52.5493L607.75 615.549C607.75 626.654 594.915 632.838 586.23 625.918L167.012 291.869Z"
                  fill="#0C0415"/>
          </g>
        </svg>

        <div className="self-end mt-3.5 mr-5.5 z-10">
          <IconButtonBig Icon={XMarkIcon}
                         onClick={closeAndNavigateHome}
          />
        </div>
        <img className="w-20.75 h-12.5 flex-shrink-0 mx-auto -mt-5.5 z-10"
             src={logo}
             alt="TCGFish logo"
        />
        {verificationSuccessText &&
          <>
            <CheckCircleIcon className="w-10 h-10 mx-auto mt-8 mb-2 text-welsh-onion-600 z-10" />
            <h2 className="mx-auto mb-28.5 z-10 h6 font-semibold text-not-tonight-1000">
              {verificationSuccessText}
            </h2>
          </>
        }
        {Object.values(errors).find(msg => !!msg) &&
          <p className="mx-auto mt-8 mb-28.5 z-10 p7 text-dusk-orange-700">
          {Object.values(errors).join(', ')}
          </p>
        }
      </div>
    </div>
  );
};
