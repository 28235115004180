import React, { useEffect, useState } from 'react';
import './RecordHigh.css';

import Listing from '../../../Listing/Listing';
import TCGFishApi from '../../../../lib/TCGFishApi';
import { normalizeGrade } from '../../../../lib/helpers';

export default function RecordHigh() {
  const [show, setShow] = useState(false);
  const [recordSalesHigh, setRecordSalesHigh] = useState([]);

  useEffect(() => {
    TCGFishApi
      .get('market/getms6high')
      .then((res) => {
        const temp = res.data.count;
        for (let j = 0; j < temp.length; j++) {
          temp[j].date = temp[j].date
            .slice(0, 10)
            .split('-')
            .reverse()
            .join('-');
          if (temp[j].grade) {
            temp[j].grade = temp[j].grade.replace('.0', '');
          }

          setRecordSalesHigh(temp);
        }
      })
      .catch((err) => {
        console.error('Failed to get market/getms6high', err);
      });
  }, []);

  return (
    <div className="record__high">
      <Listing
        data={recordSalesHigh}
        show={show}
        setShow={(b) => { setShow(b) }}
        order={[
          { name: 'name', displayName: 'Card Name' },
          { name: 'set_name', displayName: 'Set Name' },
          { name: 'date', displayName: 'Sold Date' },
          { name: 'grade', displayName: 'Grade' },
          { name: 'sold', displayName: 'Sold Price' },
          { name: 'Language', displayName: 'Language' }
        ]}
      />
      <div className="rehi__box">
        <div className="rehi__heading__area">
          <div className="rehi__heading">Record Sales / High</div>
          <span className="rehi__description">
            <p>Discover cards with rocket speed movement.</p>
            <p>Cards reaching 52W high (past 90 days) will be listed.</p>
          </span>
        </div>
        <div className="rehi__table__box">
          <div className="rehi__info__box">
            <div className="rehi__info__box__header">
              <p className="rehi__cn">Card Name</p>
              <p className="rehi__sn">Set Name</p>
              <p className="rehi__sd">Sold Date</p>
              <p className="rehi__g">Grade</p>
              <p className="rehi__sp">Sold Price</p>
              <p className="rehi__l">Language</p>
            </div>
            <div className="rehi__data">
              {recordSalesHigh.slice(0, 5).map((item) =>
                <div className="rehi__data__row"
                     key={item._id}
                >
                  <p className="rehi__data__cn">{item.name}</p>
                  <p className="rehi__data__sn">{item.set_name}</p>
                  <p className="rehi__data__sd">{item.date}</p>
                  <p className="rehi__data__g">{normalizeGrade(item.grade)}</p>
                  <p className="rehi__data__sp">{item.sold}</p>
                  <p className="rehi__data__l">{item.Language}</p>
                </div>
              )}
            </div>
          </div>
          <div className="rehi__fl">
            <div className="rehi__fl__link"
                 onClick={() => { setShow(true) }}
            >
              Full List >>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
