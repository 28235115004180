import React from 'react'
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/20/solid';

export const SidebarSearchBlock = ({searchQuery, handleSearch, handleClearSearch }) => {
  return (
    <div className="border-min border-not-tonight-200 px-5 pt-4 pb-8 rounded-lg mt-5">
      <h3 className="p2 text-white mb-4">
        Search
      </h3>
      <div className="relative">
        <input className="w-full py-3 pl-10 pr-4 rounded-lg border-none outline-none bg-not-tonight-200 p7 peer text-child-of-light-1000 placeholder:text-not-tonight-500 focus:placeholder-transparent focus:text-white"
               id="sidebar_search_input"
               type="search"
               placeholder="Search"
               name="search"
               value={searchQuery || ''}
               onChange={(e) => handleSearch(e)}
               autoComplete="off"
        />
        <MagnifyingGlassIcon className="absolute left-0 top-1/2 transform -translate-y-1/2 ml-3 h-5 w-5 text-not-tonight-500"
                             aria-hidden="true"
        />
        {searchQuery &&
          <XMarkIcon className="absolute right-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-not-tonight-500 cursor-pointer"
                     onClick={handleClearSearch}
                     aria-hidden="true"
          />
        }
      </div>
    </div>
  );
};
