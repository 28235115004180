import React from 'react';
import { Link } from 'react-router-dom';

export const TextLink = ({ text, Icon, to, disabled }) => {
  const baseStyle = 'flex items-center justify-center pl-2 pr-3 py-3 rounded-lg text-link-purple';
  const enabledStyle = 'bg-transparent hover:bg-not-tonight-100 cursor-pointer';
  const disabledStyle = 'text-not-tonight-400 cursor-not-allowed';

  return (
    <Link to={disabled ? '#' : to}
          className={`${baseStyle} ${disabled ? disabledStyle : enabledStyle}`}
          aria-disabled={disabled}
          tabIndex={disabled ? -1 : 0}
    >
      {Icon && <Icon className="h-5 w-5 text-link-purple mr-2" />}
      { text }
    </Link>
  );
};
