import React from 'react';

export const MainTabs = ({ tabs, selectedTab, onSelectTab, contentClassName }) => {
  return (
    <>
      <div className="flex space-x-4 my-12">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`p1 ${selectedTab === tab.id ? 'text-child-of-light-1000' : 'text-child-of-light-400'}`}
            onClick={() => onSelectTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className={`text-child-of-light-1000 ${contentClassName}`}>
        {tabs.find(tab => tab.id === selectedTab)?.content}
      </div>
    </>
  );
};
