import { createContext } from 'react';

export const ModalsContext = createContext({
  modals: {
    // TODO: add ExportDataModal from Card page
    showEmailVerificationModal: false,
    showLogInModal: false,
    showSignUpModal: false,
    showForgotPasswordModal: false,
    showChangePasswordModal: false
  },
  setModals: undefined, // ({}: modals) => void, like setState()
});
