import React from 'react';

import './Documents.css';
import { default as TOS } from '../TermsOfUse/TermsOfUse';
import { default as Policy } from '../PrivacyPolicy/PrivacyPolicy';
import MetadataTags from '../../components/MetadataTags/MetadataTags';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS
} from '../../lib/constants';

export default function Documents () {
  const pageTitle = `Documents - ${DEFAULT_PAGE_TITLE}`;
  const pageDescription = `Documents - ${DEFAULT_PAGE_DESCRIPTION}`;
  const pageKeywords = `Documents, ${DEFAULT_PAGE_KEYWORDS}`;

  return (
    <div className="documents__page_content">
      <MetadataTags title={ pageTitle }
                    description={ pageDescription }
                    keywords={ pageKeywords }
      />
      <TOS />
      <br />
      <br />
      <br />
      <Policy />
    </div>
  );
}
