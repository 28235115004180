import React, { useEffect, useState } from 'react';
import CanvasJSReact from '../assets/canvasjs.react';

import TCGFishApi from '../lib/TCGFishApi';
import { Export } from './Export/Export';
import { CheckIcon } from '@heroicons/react/20/solid';
import { SalesTable } from './SalesTable';
import { format } from 'date-fns';

let CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
// Keep in sync with tailwind config grade-n colors
const GradeColors = {
  1:   '#7855A5',
  1.5: '#FFC4B2',
  2:   '#3B74CA',
  2.5: '#83F471',
  3:   '#AE2FB0',
  3.5: '#968FA0',
  4:   '#D03C3C',
  4.5: '#332FFF',
  5:   '#2B9D96',
  5.5: '#DA3973',
  6:   '#C67327',
  6.5: '#A45858',
  7:   '#3B9D2B',
  7.5: '#BA971D',
  8:   '#513CD0',
  8.5: '#FF6FF1',
  9:   '#E2E62E',
  9.5: '#8C3B0D',
  10:  '#44AAE4'
};

const gradeNumberClasses = {
  1:   'bg-grade-0',
  1.5: 'bg-grade-1',
  2:   'bg-grade-2',
  2.5: 'bg-grade-3',
  3:   'bg-grade-4',
  3.5: 'bg-grade-5',
  4:   'bg-grade-6',
  4.5: 'bg-grade-7',
  5:   'bg-grade-8',
  5.5: 'bg-grade-9',
  6:   'bg-grade-10',
  6.5: 'bg-grade-11',
  7:   'bg-grade-12',
  7.5: 'bg-grade-13',
  8:   'bg-grade-14',
  8.5: 'bg-grade-15',
  9:   'bg-grade-16',
  9.5: 'bg-grade-17',
  10:  'bg-grade-18'
};

export const CardSalesChart = ({ cardID, company, setCompany, onChartGradesChange, salesGrades }) => {
  const [chardData, setChartData] = useState({
    data: {},
    grades: undefined,
    gradesAvailable: []
  });
  const [time, setTime] = useState('2Y');
  const [chartRendered, setChartRendered] = useState(false);
  const [chartOptions, setChartOptions] = useState({
        // colorSet: 'gradeColors',
    animationEnabled: false,
        // animationDuration: 1000,
    backgroundColor: '#150E1C',
    width: 889,
    height: 375,
    axisX: {
      tickLength: 16,
      scaleBreaks: {
        autoCalculate: true,
        spacing: 1
      },
      labelFontColor: '#F3F5F8',
      tickColor: '#150E1C',
      lineColor: '#150E1C',
      valueFormatString: 'YYYY MMM'
    },
    axisY: {
      tickLength: 12,
      scaleBreaks: {
        autoCalculate: true,
        spacing: 1
      },
      labelFontColor: '#F3F5F8',
      includesZero: true,
      valueFormatString: '$#,###,###.##',
      tickColor: '#150E1C',
      lineColor: '#150E1C'
    },
    toolTip: {
      shared: true,
      borderThickness: 0,
      backgroundColor: "#DDD4E9",
      fontColor: "#181819",
      cornerRadius: 8,
      contentFormatter: function(e) {
        const date = format(new Date(e.entries[0].dataPoint.x), 'yyyy MMM dd'); // Use date-fns for formatting
        let content = `<div class='p-1 p7' style='padding: 5px;'>
                        <p><strong>${date}</strong></p>`;
        for (let i = 0; i < e.entries.length; i++) {
          content += `<p class='mt-2'>${e.entries[i].dataSeries.name}: <strong>$${CanvasJS.formatNumber(e.entries[i].dataPoint.y, "#,###")}</strong></p>`;
        }
        content += `</div>`;
        return content;
      },
      custom: {
        style: 'box-shadow: 0px 4px 20px 0px #000'
      }
    },
    data: [] // Lines go here
  });

  const [isExportDataModalOpen, setIsExportDataModalOpen] = useState(false);

  const handleOpenExportDataModalClick = () => {
    setIsExportDataModalOpen(true);
    document.body.style.overflow = 'hidden';
  };

  const handleCloseExportDataModalClick = () => {
    setIsExportDataModalOpen(false);
    document.body.style.overflow = 'unset';
  };

  const grades = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];
  const timeRanges = [
    { id: 'ALL', title: 'All' },
    { id: '2Y',  title: '2 Years' },
    { id: '1Y',  title: '1 Year' },
    { id: '6M',  title: '6 Months' },
    { id: '3M',  title: '3 Months' }
  ];
  const companies = ['PSA', 'BGS', 'CGC'];

  const renderChart = () => {
    if (!cardID) {
      return;
    }

    const controller = new AbortController();
    const signal = controller.signal;
    TCGFishApi
      .get(
        'sales/cardSalesChartData',
        {
          params: {
            card_id: cardID,
            grades: chardData.grades,
            time: time,
            company: company
          },
          signal: signal
        }
      )
      .then((res) => {
        if (signal.aborted) {
          return;
        }

        setChartData({
          data: res.data.data,
          grades: Object.keys(res.data.data).map(g => Number(g)),
          gradesAvailable: res.data.availableGrades
        });
        setChartOptions((prevChartOptions) => {
          // 1 entry per chart grade
          const data = [];
          Object.keys(res.data.data).forEach((grade) => {
            data.push({
              indexLabelFontSize: 17,
              lineThickness: 2,
              type: 'spline',
              markerType: 'circle',
              markerSize: 0,
              showInLegend: false,
              // lineColor: GradeColors[grade],
              color: GradeColors[grade],
              name: `${company} ${grade}`,
              dataPoints: res.data.data[grade].map((dataPoint) => {
                return {
                  x: new Date(dataPoint.date),
                  y: dataPoint.sold
                };
              })
            });
          });

          return {
            ...prevChartOptions,
            data: data
          };
        });
        onChartGradesChange(Object.keys(res.data.data));
        setChartRendered(true);
      })
      .catch((err) => {
        if (signal.aborted) {
          return;
        }

        console.error('Failed to get sales/cardSalesChart', err);
      });

    return () => { controller.abort() };
  };

  useEffect(() => {
    if (chartRendered === true) {
      return;
    }

    renderChart();
  }, [chartRendered]);

  useEffect(() => {
    renderChart();
  }, [cardID]);

  const handleChartGradeToggleClick = (num) => {
    const gradesSet = new Set(chardData.grades);
    if (gradesSet.has(num)) {
      gradesSet.delete(num);
    } else {
      gradesSet.add(num);
    }

    setChartData((prevState) => { return { ...prevState, grades: Array.from(gradesSet) } });
    setChartRendered(false);
  };

  const GradeButton = (grade) => {
    if (!chardData.grades) {
      return;
    }

    if (chardData.gradesAvailable.indexOf(grade) !== -1) {
      const position = chardData.grades.indexOf(grade);
      const selected = position !== -1;

      return (
        <div className="flex items-center cursor-pointer gap-2"
             onClick={() => { handleChartGradeToggleClick(grade) }}
        >
          <div className={`flex items-center justify-center w-6 h-6 rounded text-not-tonight-1000 ${selected ? 'bg-purple-600' : 'border-min border-not-tonight-600'}`}>
            {selected &&
              // <CheckIcon />
              <CheckIcon className="w-5 h-5" />
            }
          </div>
          <div className="flex items-center">
            <div className={`w-3 h-3 mr-1.25 rounded-full ${selected ? gradeNumberClasses[grade] : 'bg-grade-unavailable'}`}>
            </div>
            <p className="p7 text-not-tonight-1000">
              Grade {grade}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="flex items-center gap-2 opacity-50">
        <div className="w-6 h-6 border-min border-not-tonight-600 rounded"></div>
        <div className="flex items-center">
          <div className="w-3 h-3 mr-1.25 border-min border-white rounded-full"></div>
          <p className="p7 text-not-tonight-1000">
            Grade {grade}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-not-tonight-100 border-min border-not-tonight-200 rounded w-full mb-10 pt-6 px-6">
      <div className="flex justify-between">
        <p className="p1 text-not-tonight-1000">
          Sales History (USD)
        </p>
        <button className="flex text-white items-center bg-not-tonight-200 py-3 px-6 rounded"
                onClick={handleOpenExportDataModalClick}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
            <path
              d="M10.75 2.75a.75.75 0 0 0-1.5 0v8.614L6.295 8.235a.75.75 0 1 0-1.09 1.03l4.25 4.5a.75.75 0 0 0 1.09 0l4.25-4.5a.75.75 0 0 0-1.09-1.03l-2.955 3.129V2.75Z"/>
            <path
              d="M3.5 12.75a.75.75 0 0 0-1.5 0v2.5A2.75 2.75 0 0 0 4.75 18h10.5A2.75 2.75 0 0 0 18 15.25v-2.5a.75.75 0 0 0-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5Z"/>
          </svg>
          <span className="p7 pl-2">
           Export data
         </span>
        </button>
      </div>
      <div className="border-b-min border-not-tonight-300">
        <div className="flex">
          {companies.map((cmp) =>
            <div className={`p-2 mr-8 p6 cursor-pointer ${company === cmp ? 'text-white border-b  border-white' : 'text-not-tonight-900'}`}
                 key={cmp}
                 onClick={() => {
                   if (cmp === company) {
                     return;
                   }

                   setCompany(cmp);
                   setChartRendered(false);
                 }}
            >
              {cmp}
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row w-full">
        <div className="flex w-235.6875">
          <div className="w-full">
            <div className="mb-10 mt-6">
              <div className="inline-block bg-not-tonight-200 rounded px-1 py-1">
                {timeRanges.map((range) =>
                  <button className={`${time !== range.id ? 'text-not-tonight-800' : 'text-white bg-not-tonight-300'} px-8 py-1 rounded`}
                          key={range.id}
                          onClick={() => {
                                  if (time === range.id) {
                                    return;
                                  }

                                  setTime(range.id);
                                  setChartRendered(false);
                                }}
                  >
                    {range.title}
                  </button>
                )}
              </div>
            </div>
            {!chartOptions.data.length ?
              <>
                {chardData.gradesAvailable?.length ?
                  <div className="">Please select Grade</div>
                  :
                  <div className="p2 text-white">No Sales Data</div>
                }
              </>
              :
              <div className="pr-6.6875">
                <CanvasJSChart options={chartOptions}/>
              </div>
            }
          </div>
        </div>
        <div className="flex flex-col w-94.3125 border-l-min border-not-tonight-300 pt-6 pl-10.3125">
          <p className="w-full p7 text-child-of-light-500 pb-5.75">
            Select sales grades you want to see
          </p>
          <div className="flex flex-col">
            {grades.map((grade) =>
              <div className="flex flex-row w-full mb-5.5"
                   key={grade}
              >
                <div className="w-26.5 mr-14">
                  {GradeButton(grade)}
                </div>
                {grade !== 1 &&
                  <div className="w-28">
                    {GradeButton(grade - 0.5)}
                  </div>
                }
              </div>
            )}
          </div>
        </div>
        {isExportDataModalOpen &&
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="w-190 bg-purple-100 p-8 rounded shadow-md">
              <Export allSales={[]}
                      onCloseExportDataModalClick={handleCloseExportDataModalClick}
              />
            </div>
          </div>
        }
      </div>
      <SalesTable cardID={cardID}
                    company={company}
                    grades={salesGrades}
        />
    </div>
  );
};
