import React from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class DoughnutChart extends React.Component {
  render() {
    const colors = CanvasJS.addColorSet("doughnutcolorSet", [
      "#7855A5",
      "#2287E4",
      "#23DDA5",
    ]);
    const options = {
      animationEnabled: true,
      animationDuration: 2000,
      backgroundColor: "transparent",
      colorSet: "doughnutcolorSet",
      theme: "dark1",
      height: 195,
      width: 195,
      axisY: {
        includesZero: true,
      },
      subtitles: [
        {
          verticalAlign: "center",
          fontSize: 20,
        },
      ],
      data: [
        {
          type: "doughnut",
          showInLegend: false,
          yValueFormatString: "(##.##'%)'",
          startAngle: -90,
          radius: "100%",
          innerRadius: "65%",
          dataPoints: [
            { name: "Graded by PSA: ", y: (this.props.psa ? this.props.psa : 0), exploded: true, color: '#7855A5' },
            { name: "Graded by BGS: ", y: (this.props.bgs ? this.props.bgs : 0), color: '#2287E4' },
            { name: "Graded by CGC: ", y: (this.props.cgc ? this.props.cgc : 0), exploded: true,color: '#23DDA5'},
          ],
        },
      ],
      toolTip: {
        contentFormatter: function (e) {
          return `<div style="display: flex; align-items: center;">
                      <div style="width: 12px; 
                                  height: 12px;
                                  border-radius: 50%;
                                  background: ${e.entries[0].dataPoint.color};
                                  margin-right: 3px"></div>
                      <p style="padding-right: 5px">${e.entries[0].dataPoint.name}</p>
                      <p><strong>${e.entries[0].dataPoint.y.toFixed(2)}%</strong><p/>
                    </div>`;
        },
        fontColor: '#181819',
        backgroundColor: '#DDD4E9',
        borderColor: "transparent",
        cornerRadius: 4,
      },
    };
    return (
      <div>
        <CanvasJSChart options={options} onRef={(ref) => (this.chart = ref)} />
      </div>
    );
  }
}
export default DoughnutChart;
