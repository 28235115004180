import React from 'react';

import { IconButtonBig } from './IconButtonBig';

export const IconButtonBigFilled = ({ onClick, Icon, className }) => {
  return (
    <IconButtonBig className={`bg-not-tonight-200 hover:bg-not-tonight-300 ${className ? className : ''}`}
                   onClick={onClick}
                   Icon={Icon}
    />
  );
};
