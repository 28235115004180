import React from 'react';
import './MarketSetValueTopCards.css';
import { Link } from 'react-router-dom';
import empty from '../../assets/images/empty.png';
import TCGFishApi from '../../lib/TCGFishApi';
import { LoadingSpinner } from '../LoadingSpinner';

export default function MarketSetValueTopCards({ setFilters }) {
  const [finalList, setFinalList] = React.useState(null);

  React.useEffect(() => {
    setFinalList(null);

    TCGFishApi.get(
        'msv/topCards',
        {
          params: { setNames: [setFilters] }
        }
      )
      .then((res) => {
          const temp = res.data.data;
          temp.sort(function (a, b) {
            return b.psa_10_value - a.psa_10_value;
          });
          setFinalList(temp);
      })
      .catch((err) => {
        setFinalList([]);
        console.error('Failed to get msv/topCards', err);
      });
  }, [setFilters]);

  function listItems() {
    if (finalList.length) {
      return finalList.map((item) => {
        const num = item.psa_10_value.toLocaleString('en-US');
        return (
          <div className="msv_top_card"
               key={item._id}
          >
            <div>
              <Link
                onClick={() => { sessionStorage.setItem("bread", "setvalue"); }}
                to={`/product?id=${item.cardObjectID}&cardNumber=${item.card_id}`}
              >
                <img
                  className="msv_top_card_img"
                  src={item.image}
                  onError={(e) => (e.target.src = empty)}
                  alt="IMG"
                />
              </Link>
              <div className="msv_top_card_cardInfo">
                <div className="msv_top_card_cardText">
                  <p className="msv_top_card_cardName">
                    {item.name}{" "}
                    {(item.card_number && item.card_number !== 'Missing') && (
                      <span>#{item.card_number}</span>
                    )}
                  </p>
                  <p className="msv_top_card_cardSet">{item.set_name}</p>
                  <p className="msv_top_card_cardSet">{item.releaseYear}</p>
                  <div className="msv_top_card_psa_10_value">PSA 10: ${num} </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
    } else
      return (
        <span style={{ color: "white", fontSize: "20px", margin: "20px" }}>
          No data
        </span>
      );
  }
  return (
    <div className="marketsetvaluetopcards">
      <div className="msv_top_cards_header">
        Top 20 Chase Cards in Selected Set Sorted by PSA 10 Value
      </div>
      <div className="msv_top_card_container">
        <div className="msv_top_card_row">
          { finalList ? (
            <>{listItems()}</>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </div>
    </div>
  );
}
