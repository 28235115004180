import React from 'react';
import { Box, Slider, TextField, styled } from '@mui/material';
import { formatNumber } from '../utils/formatNumber';

const StyledSlider = styled(Slider)({
  color: '#562A8F',
  padding: 0,
  '& .MuiSlider-thumb': {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:hover': {
      boxShadow: 'none',
    },
    '& .Mui-focusVisible, &.Mui-active': {
      boxShadow: 'none',
    },
  },
  '& .MuiSlider-track': {
    height: 4,
    borderRadius: 2,
  },
  '& .MuiSlider-rail': {
    color: '#8c8c8c',
    opacity: 1,
    height: 4,
    borderRadius: 2,
  },
  '& .MuiSlider-valueLabel': {
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#fff',
    },
  },
});

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& input': {
    textAlign: 'center',
    padding: '10px',
    color: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&:hover fieldset': {
      borderColor: 'rgba(0, 0, 0, 0)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0)',
    },
  },
  width: '100px',
  '.MuiInputBase-input': {
    color: 'white',
  },
  backgroundColor: '#1F1927',
  borderRadius: '8px',
  flex: 1,
}));

export const RangeSlider = ({ range, setRange, maxValue, minValue, isPriceRange }) => {
  const handleSliderChange = (event, newValue) => {
    setRange(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    let newRange = [...range];
    if (name === 'minPrice') {
      newRange = [Math.min(newRange[1], value), newRange[1]];
    } else {
      newRange = [newRange[0], Math.max(newRange[0], value)];
    }
    setRange(newRange);
  };

  return (
    <Box sx={{ width: '100%', mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="p7 text-not-tonight-800">
          {isPriceRange && '$'}{minValue}
        </span>
        <span className="p7 text-not-tonight-800">
          {isPriceRange && '$'}{formatNumber(maxValue)}
        </span>
      </Box>
      <Box sx={{ width: '100%', mt: 0.5, px: 1 }}>
        <StyledSlider getAriaLabel={() => 'range'}
                      value={range}
                      onChange={handleSliderChange}
                      valueLabelDisplay="off"
                      min={minValue}
                      max={maxValue}
                      disableSwap
        />
      </Box>

      <Box sx={{ display: 'flex', mt: 2, gap: 3 }}>
        <StyledTextField name="minPrice"
                         value={range[0]}
                         onChange={handleInputChange}
                         onBlur={() =>
                           setRange([
                             Math.min(range[0], range[1]),
                             range[1],
                           ])
                         }
                         inputProps={{
                           step: 1,
                           min: 0,
                           max: range[1],
                           type: 'number',
                           'aria-labelledby': 'input-slider',
                         }}
        />
        <StyledTextField name="maxPrice"
                         value={range[1]}
                         onChange={handleInputChange}
                         onBlur={() =>
                           setRange([
                             range[0],
                             Math.max(range[0], range[1]),
                           ])
                         }
                         inputProps={{
                           step: 1,
                           min: range[0],
                           max: range[1],
                           type: 'number',
                           'aria-labelledby': 'input-slider',
                         }}
        />
      </Box>
    </Box>
  );
}
