import React, { useEffect, useState } from 'react';
import './PopularCharacters.css';

import CanvasJSReact from '../../../../assets/canvasjs.react';
import Listing from '../../../Listing/Listing';
import TCGFishApi from '../../../../lib/TCGFishApi';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function PopularCharacters({ windowWidth }) {
  const [show, setShow] = useState(false);

  const [popCharacter, setPopCharacter] = useState([]);

  useEffect(() => {
    TCGFishApi
      .get('market/getms7')
      .then((res) => {
        setPopCharacter(res.data.count);
      })
      .catch((err) => {
        console.error('Failed to get market/getms7', err);
      });
  }, []);

  const dataPoints = () => {
    if (popCharacter.length > 0) {
      const dp = [];
      let sum = 0;
      for (let i = 0; i < 8; i++) {
        if (i <= 4) {
          dp.push({
            name: popCharacter[i].character,
            y: popCharacter[i].percentage,
            exploded: true
          });
        }
        else {
          dp.push({
            name: popCharacter[i].character,
            y: popCharacter[i].percentage,
          });
        }
        sum += popCharacter[i].percentage;
      }
      dp.push({ name: 'Others', y: 100 - sum });
      return dp;
    }

    return [];
  };

  CanvasJS.addColorSet('doughnutcolorSet', [
    '#17A35A',
    '#2278C1',
    '#DC9F29',
    '#8C3671',
    '#029DC9',
    '#242B75',
    '#BB7765',
    '#5D073F',
    '#CCC408',
  ]);

  const options = {
    animationEnabled: true,
    animationDuration: 2000,
    backgroundColor: '#0F0F0F',
    colorSet: 'doughnutcolorSet',
    theme: 'dark1',
    axisY: {
      includesZero: true,
    },
    subtitles: [
      {
        fontSize: 10,
      },
    ],
    data: [
      {
        type: 'doughnut',
        showInLegend: false,
        indexLabel: '{name}: {y}',
        indexLabelFontSize: 12,
        yValueFormatString: "(##.##'%)'",
        horizontalAlign: 'center',
        startAngle: -90,
        radius: '100%',
        innerRadius: '60%',
        dataPoints: dataPoints(),
      },
    ],
  };

  const mobOptions = {
    animationEnabled: true,
    animationDuration: 2000,
    backgroundColor: '#0F0F0F',
    colorSet: 'doughnutcolorSet',
    theme: 'dark1',
    height: 200,
    width: 250,
    axisY: {
      includesZero: true,
    },
    subtitles: [
      {
        fontSize: 7,
      },
    ],
    data: [
      {
        type: 'doughnut',
        showInLegend: false,
        indexLabel: '{name}: {y}',
        indexLabelFontSize: 8,
        yValueFormatString: "(##.##'%)'",
        horizontalAlign: 'center',
        startAngle: -90,
        radius: '100%',
        innerRadius: '60%',
        dataPoints: dataPoints(),
      },
    ],
  };

  return (
    <div className="popular__characters">
      <Listing
        show={show}
        setShow={(b) => { setShow(b) }}
        data={popCharacter}
        order={[
          { name: 'character', displayName: 'Character Name' },
          { name: 'sales_vol', displayName: '90 Days Sales Volume' },
          { name: 'rank', displayName: 'Rank' },
          { name: 'percentage', displayName: '90 Day Percentage', type: 'percentage' }
        ]}
      />
      <div className="pch__box">
        <div className="pch__heading__area">
          <div className="pch__heading">Popular Characters</div>
          <span className="pch__description">
            <p>Detect the most popular character/Pokemon.</p>
          </span>
        </div>
        <div className="pch__table__box">
          <div className="pch__table__top">
            <div className="pch__overall__set">
              <div className="pch__overall__set__name">
                <div className="pch__overall__set__box">Overall Market</div>
              </div>
              <div className="pch__overall__set__header">
                <p className="pch__overall__cn">Character Name</p>
                <p className="pch__overall__dsv">90 Days Sales Volume</p>
                <p className="pch__overall__r">Rank</p>
                <p className="pch__overall__dp">90 Day Percentage</p>
              </div>
              <div className="pch__data">
                {popCharacter.slice(0, windowWidth > 550 ? 8 : 5).map((item) =>
                  <div className="pch__data__row"
                       key={item._id}
                  >
                    <p className="pch__data__cn">{item.character}</p>
                    <p className="pch__data__dsv">{item.sales_vol}</p>
                    <p className="pch__data__r">{item.rank}</p>
                    <p className="pch__data__dp">
                      {item.percentage?.toFixed(2)}%
                    </p>
                  </div>
                )}
              </div>
              <div className="pch__fl">
                <div className="pch__fl__link"
                     onClick={() => { setShow(true) }}
                >
                  Full List >>
                </div>
              </div>
            </div>
            <div className="pch__graph">
              <div className="pch__doughnut">
                <CanvasJSChart options={windowWidth > 550 ? options : mobOptions} />
                <div className="pch__legend__section">
                  <div className="pch__legends__row">
                    <div className="pch__legend">
                      <div className="pch__legend__r1"></div>
                      <div className="pch__legend__r1__char">
                        {popCharacter.length > 0
                          ? popCharacter[0].character
                          : null}
                      </div>
                    </div>
                    <div className="pch__legend">
                      <div className="pch__legend__r2"></div>
                      <div className="pch__legend__r2__char">
                        {popCharacter.length > 0
                          ? popCharacter[1].character
                          : null}
                      </div>
                    </div>
                    <div className="pch__legend">
                      <div className="pch__legend__r3"></div>
                      <div className="pch__legend__r3__char">
                        {popCharacter.length > 0
                          ? popCharacter[2].character
                          : null}
                      </div>
                    </div>
                    <div className="pch__legend">
                      <div className="pch__legend__r4"></div>
                      <div className="pch__legend__r4__char">
                        {popCharacter.length > 0
                          ? popCharacter[3].character
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="pch__legends__row">
                    <div className="pch__legend">
                      <div className="pch__legend__r5"></div>
                      <div className="pch__legend__r5__char">
                        {popCharacter.length > 0
                          ? popCharacter[4].character
                          : null}
                      </div>
                    </div>
                    <div className="pch__legend">
                      <div className="pch__legend__r6"></div>
                      <div className="pch__legend__r6__char">
                        {popCharacter.length > 0
                          ? popCharacter[5].character
                          : null}
                      </div>
                    </div>
                    <div className="pch__legend">
                      <div className="pch__legend__r7"></div>
                      <div className="pch__legend__r7__char">
                        {popCharacter.length > 0
                          ? popCharacter[6].character
                          : null}
                      </div>
                    </div>
                    <div className="pch__legend">
                      <div className="pch__legend__r8">   </div>
                      <div className="pch__legend__r8__char">
                        {popCharacter.length > 0
                          ? popCharacter[7].character
                          : null}
                      </div>
                    </div>
                  </div>
                  <div className="pch__legends__row">
                    <div className="pch__legend">
                      <div className="pch__legend__r9"></div>
                      <div className="pch__legend__r9__char">
                        {popCharacter.length > 0
                          ? 'Others'
                          : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
