import React, { useState } from 'react';

export const Checkbox = ({ onClick, defaultChecked }) => {
  const [checked, setChecked] = useState(defaultChecked);

  const handleClick = (evt) => {
    setChecked(!checked);
    onClick(evt);
  };

  return(
    <span className="inline flex-shrink-0 cursor-pointer"
          role="checkbox"
          onClick={handleClick}
    >
      {checked ?
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <mask id="mask0_797_4396" style={{maskType: 'alpha', maskUnits: 'userSpaceOnUse', x: 0, y: 0, width: 24, height: 24}}>
            <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_797_4396)">
            <path d="M10.6 13.7462L8.27688 11.4231C8.13842 11.2846 7.96439 11.2138 7.75478 11.2106C7.54518 11.2074 7.36794 11.2782 7.22308 11.4231C7.07819 11.568 7.00575 11.7436 7.00575 11.95C7.00575 12.1564 7.07819 12.332 7.22308 12.4769L9.9673 15.2211C10.1481 15.4019 10.359 15.4923 10.6 15.4923C10.841 15.4923 11.0519 15.4019 11.2327 15.2211L16.7961 9.65767C16.9346 9.51922 17.0054 9.34519 17.0086 9.13557C17.0118 8.92596 16.941 8.74872 16.7961 8.60385C16.6513 8.45898 16.4756 8.38655 16.2692 8.38655C16.0628 8.38655 15.8872 8.45898 15.7423 8.60385L10.6 13.7462ZM5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.30772C3.5 4.80259 3.675 4.37502 4.025 4.02502C4.375 3.67502 4.80257 3.50002 5.3077 3.50002H18.6923C19.1974 3.50002 19.625 3.67502 19.975 4.02502C20.325 4.37502 20.5 4.80259 20.5 5.30772V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077Z" fill="#562A8F"/>
            <path d="M10.6 13.7462L8.27688 11.4231C8.13842 11.2846 7.96439 11.2138 7.75478 11.2106C7.54518 11.2074 7.36794 11.2782 7.22308 11.4231C7.07819 11.568 7.00575 11.7436 7.00575 11.95C7.00575 12.1564 7.07819 12.332 7.22308 12.4769L9.9673 15.2211C10.1481 15.4019 10.359 15.4923 10.6 15.4923C10.841 15.4923 11.0519 15.4019 11.2327 15.2211L16.7961 9.65767C16.9346 9.51922 17.0054 9.34519 17.0086 9.13557C17.0118 8.92596 16.941 8.74872 16.7961 8.60385C16.6513 8.45898 16.4756 8.38655 16.2692 8.38655C16.0628 8.38655 15.8872 8.45898 15.7423 8.60385L10.6 13.7462Z" fill="white"/>
          </g>
        </svg>
        :
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <mask id="mask0_797_4376" style={{maskType: 'alpha', maskUnits: 'userSpaceOnUse', x: 0, y: 0, width: 24, height: 24}}>
            <rect width="24" height="24" fill="#D9D9D9"/>
          </mask>
          <g mask="url(#mask0_797_4376)">
            <path d="M5.61538 20C5.15513 20 4.77083 19.8458 4.4625 19.5375C4.15417 19.2292 4 18.8449 4 18.3846V5.61537C4 5.15512 4.15417 4.77083 4.4625 4.4625C4.77083 4.15417 5.15513 4 5.61538 4H18.3846C18.8449 4 19.2292 4.15417 19.5375 4.4625C19.8458 4.77083 20 5.15512 20 5.61537V18.3846C20 18.8449 19.8458 19.2292 19.5375 19.5375C19.2292 19.8458 18.8449 20 18.3846 20H5.61538ZM5.61538 19H18.3846C18.5385 19 18.6795 18.9359 18.8077 18.8077C18.9359 18.6795 19 18.5385 19 18.3846V5.61537C19 5.46154 18.9359 5.32052 18.8077 5.1923C18.6795 5.0641 18.5385 5 18.3846 5H5.61538C5.46154 5 5.32052 5.0641 5.1923 5.1923C5.0641 5.32052 5 5.46154 5 5.61537V18.3846C5 18.5385 5.0641 18.6795 5.1923 18.8077C5.32052 18.9359 5.46154 19 5.61538 19Z" fill="#79757E"/>
          </g>
        </svg>
      }
    </span>
  );
};
