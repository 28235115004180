import React from 'react';

export const IconButtonBig = ({ onClick, Icon, className }) => {
  return (
    <div className={`flex flex-shrink-0 w-10 h-10 py-3 px-5 items-center justify-center gap-2 rounded-md text-white hover:bg-not-tonight-200 ${className ? className : ''}`}
         role="button"
         onClick={onClick}
    >
      <Icon className="w-6 h-6 flex-shrink-0" />
    </div>
  );
};
