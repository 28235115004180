import React from 'react';
import CanvasJSReact from '../../../assets/canvasjs.react';
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function PieChart({data}) {
    CanvasJS.addColorSet("piecolorSet", [
        "#4473C5",
        "#f4c313",
        "#EFEFEF",
        "#8df13a",
        "#ECF500",
        "#E9068B",
        "#FFFFFF"
    ]);
    const options = {
        animationEnabled: true,
        animationDuration: 2000,
        backgroundColor: "transparent",
        colorSet: "piecolorSet",
        theme: "dark1",
        height: 230,
        // width: 260,
        axisY: {
            includesZero: true,
        },
        data: [
            {
                type: "doughnut",
                showInLegend: false,
                yValueFormatString: "##.##",
                startAngle: -90,
                radius: "100%",
                innerRadius: "60%",
                dataPoints: [
                    { name: "Value $1-$99: ", y: data.Value_1_99, exploded: true },
                    { name: "Value $99-$199: ", y: data.Value_99_199 },
                    { name: "Value $199-$499: ", y: data.Value_199_499 },
                    { name: "Value $499-$1499: ", y: data.Value_499_1499, exploded: true },
                    { name: "Value $1499-$2499: ", y: data.Value_1499_2499 },
                    { name: "Value $2499-$4999: ", y: data.Value_2499_4999, exploded: true },
                    { name: "Value $5000+: ", y: data.Value_5000, exploded: true },
                ],
            },
        ],
    };
    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    );
}
