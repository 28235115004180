import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';

import TCGFishApi from '../lib/TCGFishApi';
import empty from '../assets/images/empty.png';
import { LoadingSmallSpinner } from './LoadingSmallSpinner';
import { useDebounce } from '../hooks/useDebounce';

export const HeaderSearch = () => {
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  const [query, setQuery] = useState('');
  const [results, setResults] = useState({ cards: [], sets: [] });
  const [activeTab, setActiveTab] = useState('Cards'); // 'Cards' or 'Sets'
  const [isSetsLoading, setIsSetsLoading] = useState(false); 
  const debouncedQuery = useDebounce(query, 300);

  const handleSearchInputChange = (evt) => {
    setQuery(evt.target.value);
  };

  const handleSearchInputKeyDown = (evt) => {
    if (evt.key === 'Enter') {
      navigate(`/search?query=${query}`);
      setQuery('');
      searchInputRef.current.blur();
    }
  };

  const handleClearSearchClick = () => {
    setQuery('');
  };

  const handleSearchBarClick = () => {
    if (!searchInputRef.current) return;
    searchInputRef.current.focus();
  };

  const handleSearchResultClick = (result, type) => {
    if (type === 'set') {
      navigate(`/setvalue?setname=${encodeURIComponent(result.name)}`);
    } else {
      navigate(`/product?id=${result._id}&cardNumber=${result.sub_cat[0].card_id}`);
    }
    setTimeout(() => {
      if (!searchInputRef.current) {
        return;
      }
      searchInputRef.current.blur();
    }, 50);
  };

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  const handleClickOutside = (evt) => {
    evt.stopPropagation();
  };

  useEffect(() => {
    if (!debouncedQuery) {
      setResults({ cards: [], sets: [] });
      return;
    }

    const controller = new AbortController();
    setIsSetsLoading(true);
    // Search for cards
    TCGFishApi.get('cards/search', {
      signal: controller.signal,
      params: { query: debouncedQuery }
    })
      .then((res) => {
        setResults((prevResults) => ({ ...prevResults, cards: res.data.cards.slice(0, 4) }));
      })
      .catch(() => setResults((prevResults) => ({ ...prevResults, cards: [] })));

    // Search for sets
    TCGFishApi.get('sets/search', {
      signal: controller.signal,
      params: { query: debouncedQuery }
    })
      .then((res) => {
        setResults((prevResults) => ({ ...prevResults, sets: res.data.sets.slice(0, 4) }));
      })
      .catch(() => setResults((prevResults) => ({ ...prevResults, sets: [] })))
      .finally(() => setIsSetsLoading(false));

    return () => controller.abort();
  }, [debouncedQuery]);

  return (
    <div className="group" onClick={handleSearchBarClick}>
      <div
        className="backdrop-blur-sm z-30 w-screen h-screen fixed top-0 left-0 hidden group-focus-within:block group-active:block"
        onClick={handleClickOutside}
      />
      <div className="flex flex-shrink-0 relative ml-6 w-105 h-12 pl-3 pr-4 py-2.5 items-center justify-center gap-2 rounded-lg bg-not-tonight-200 box-border border-min border-transparent text-not-tonight-500 z-40 hover:border-solid hover:border-min hover:border-not-tonight-300 focus-within:border-solid focus-within:border-min focus-within:border-not-tonight-300 focus-within:bg-purple-100 focus-within:text-child-of-light-1000 group-active:border-solid group-active:border-min group-active:border-not-tonight-300 group-active:bg-purple-100 group-active:text-child-of-light-1000">
        <svg className="group-focus-within:text-white" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M9.5 16C7.68333 16 6.14583 15.3708 4.8875 14.1125C3.62917 12.8542 3 11.3167 3 9.5C3 7.68333 3.62917 6.14583 4.8875 4.8875C6.14583 3.62917 7.68333 3 9.5 3C11.3167 3 12.8542 3.62917 14.1125 4.8875C15.3708 6.14583 16 7.68333 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L20.3 18.9C20.4833 19.0833 20.575 19.3167 20.575 19.6C20.575 19.8833 20.4833 20.1167 20.3 20.3C20.1167 20.4833 19.8833 20.575 19.6 20.575C19.3167 20.575 19.0833 20.4833 18.9 20.3L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16ZM9.5 14C10.75 14 11.8125 13.5625 12.6875 12.6875C13.5625 11.8125 14 10.75 14 9.5C14 8.25 13.5625 7.1875 12.6875 6.3125C11.8125 5.4375 10.75 5 9.5 5C8.25 5 7.1875 5.4375 6.3125 6.3125C5.4375 7.1875 5 8.25 5 9.5C5 10.75 5.4375 11.8125 6.3125 12.6875C7.1875 13.5625 8.25 14 9.5 14Z" fill="currentColor" />
        </svg>
        <input
          className="w-full box-border border-none outline-none bg-transparent p7 peer text-child-of-light-1000 placeholder:text-not-tonight-500 focus:placeholder-transparent focus:text-white"
          id="header_search_input"
          type="search"
          placeholder="Search cards, collections or other"
          name="search"
          value={query}
          onChange={handleSearchInputChange}
          onKeyDown={handleSearchInputKeyDown}
          ref={searchInputRef}
          autoComplete="off"
        />
        <svg
          className="text-not-tonight-800 invisible hover:outline-none group-focus-within:visible peer-placeholder-shown:invisible hover:visible focus:visible group-active:visible"
          role="button"
          tabIndex="0"
          name="clear_search"
          onClick={handleClearSearchClick}
          onKeyDown={handleClearSearchClick}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.46967 5.46967C5.76256 5.17678 6.23744 5.17678 6.53033 5.46967L12 10.9393L17.4697 5.46967C17.7626 5.17678 18.2374 5.17678 18.5303 5.46967C18.8232 5.76256 18.8232 6.23744 18.5303 6.53033L13.0607 12L18.5303 17.4697C18.8232 17.7626 18.8232 18.2374 18.5303 18.5303C18.2374 18.8232 17.7626 18.8232 17.4697 18.5303L12 13.0607L6.53033 18.5303C6.23744 18.8232 5.76256 18.8232 5.46967 18.5303C5.17678 18.2374 5.17678 17.7626 5.46967 17.4697L10.9393 12L5.46967 6.53033C5.17678 6.23744 5.17678 5.76256 5.46967 5.46967Z"
            fill="currentColor"
          />
        </svg>

        {results.cards.length || results.sets.length ? (
          <div className="absolute left-0 top-14 flex flex-col w-full max-h-133 px-3 pt-3.1 items-center rounded-lg border-min border-solid border-not-tonight-300 bg-purple-100 dropdown-box-shadow invisible group-focus-within:visible group-active:visible">
            <div className="inline-flex self-start items-baseline gap-5 mt-1 ml-2">
              <div
                className={`flex h-4.25 justify-end items-center text-${activeTab === 'Cards' ? 'text-white border-b-min border-white p6' : 'not-tonight-900'} p7 cursor-pointer px-2 pb-1.75 z-40`}
                role="button"
                onClick={() => handleTabSwitch('Cards')}
              >
                Cards
              </div>
              <div
                className={`flex h-4.25 justify-end items-center ${activeTab === 'Sets' ? 'text-white border-b-min border-white p6' : 'not-tonight-900'} p7 cursor-pointer px-2 pb-1.75 z-40`}
                role="button"
                onClick={() => handleTabSwitch('Sets')}
              >
                Sets
              </div>
            </div>
            <div className="flex flex-col items-start flex-shrink-0 w-full max-h-118.5 gap-1 border-t border-not-tonight-200">
              <div className='mt-3 w-full'>
              {activeTab === 'Cards'
                ? results.cards.length ? results.cards.map((result) => (
                    <Link
                      className="flex p-3 justify-end items-start gap-3 h-27.5 rounded-lg hover:bg-not-tonight-200 group/search-result"
                      key={result._id}
                      to={`/product?id=${result._id}&cardNumber=${result.sub_cat[0].card_id}`}
                      onClick={() => handleSearchResultClick(result, 'card')}
                    >
                      <img className="w-15.75 h-21.5 bg-lightgrey-200 relative hover:flex-shrink-0 hover:w-22.25 hover:h-30.25 hover:-left-6.75 hover:-top-4.5 z hover:z-60"
                        src={result.sub_cat[0].image}
                        onError={(e) => {
                          e.target.src = empty;
                        }}
                        alt={result.name}
                      />
                      <div className="flex items-start justify-between w-74.25">
                        <div className="flex flex-col items-start gap-1">
                          <span className="text-child-of-light-1000 p6">
                            {result.name}{result.card_number && result.card_number !== 'Missing' ? ` #${result.card_number}` : ''},&nbsp;
                          </span>
                          <span className="text-child-of-light-1000 p7">
                            {result.set_name}, {result.releaseYear ? result.releaseYear : ''}
                          </span>
                        </div>
                        <span className="text-not-tonight-700 p7">
                          ({result.language || ''})
                        </span>
                      </div>
                    </Link>
                  )) : <div className='p7 text-child-of-light-1000 py-2'>No card results found</div>
                : isSetsLoading ? (
                    <div className="w-100 pb-3 flex items-center justify-center">
                      <LoadingSmallSpinner />
                    </div>
                  ) : results.sets.length ? results.sets.map((set) => (
                    <Link
                      className="flex w-full p-3 items-start gap-3 rounded-lg hover:bg-not-tonight-200 group/search-result"
                      key={set.id}
                      to={`/setvalue?setname=${encodeURIComponent(set.name)}`}
                      onClick={() => handleSearchResultClick(set, 'set')}
                    >
                      <p className='p6 text-child-of-light-1000 '>{set.name}</p>
                    </Link>
                  )) : <div className='p7 text-child-of-light-1000 py-2'>No set results found</div>}
              </div>
            </div>

            {activeTab === 'Cards' && results.cards.length > 3 && (
              <div className={`w-104 h-14 z-50 flex flex-shrink-0 items-center justify-center py-2.5 -mx-3 rounded-b-lg border-t-min border-t-not-tonight-300 bg-purple-100 header_search-results-footer`}>
                <Link className="flex h-9 py-3 pr-3 pl-2 justify-center items-center gap-1 rounded-lg box-border text-link-purple p6"
                      to={`/search?query=${debouncedQuery}`}
                      onClick={handleSearchResultClick}
                >
                  <ArrowTopRightOnSquareIcon className="w-5 h-5" />
                  View all results
                </Link>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};
