import React from 'react';
// import styled from "styled-components";
import CanvasJSReact from '../../../assets/canvasjs.react';
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function BarChart2({ data }) {
    CanvasJS.addColorSet("BarChart2Set", ["#4473C5"]);
    const options = {
        zoomEnabled: true,
        colorSet: "BarChart2Set",
        backgroundColor: "#0E1628",
        theme: "none",
        animationEnabled: true,
        animationDuration: 2000,
        height: 280,
        // width: 650,
        axisX: {
            margin: 10,
            padding: {
                top: 50,
            },

            interval: 0.5,
            labelFontColor: "#fff",
            // tickLength: 20,
            tickColor: "#08131F",
            lineColor: "#4473C5",
            includesZero: true,
            lineThickness: 1,
            // valueFormatString: "hh:mm",
        },
        axisY: {
            // margin: 20,
            labelFontColor: "#fff",
            includesZero: true,
            valueFormatString: "#,###,###",
            // tickLength: 20,
            // minimum: 1,
            tickColor: "#08131F",
            lineThickness: 0,
            lineColor: "#08131F",
        },
        toolTip: {
          content:
            "Grade {x}: <strong>{y}</strong>",
        },
        // legend: {
        //   cursor: "pointer",
        //   fontColor: "#fff",
        //   maxWidth: 600,
        //   itemWidth: 80,
        //   markerMargin: 10,
        //   itemclick: function (e) {
        //     if (
        //       typeof e.dataSeries.visible === "undefined" ||
        //       e.dataSeries.visible
        //     ) {
        //       e.dataSeries.visible = false;
        //     } else {
        //       e.dataSeries.visible = true;
        //     }
        //     e.chart.render();
        //   },
        // },
        data: [{
            // yValueFormatString: "#,### Units",
            // xValueFormatString: "YYYY",
            markerType: "circle",
            markerSize: 0,
            showInLegend: false,
            indexLabel: "{y}",
            indexLabelPlacement: "outside",
            indexLabelOrientation: "horizontal",
            indexLabelFontColor: "#fff",
            type: "column",
            dataPoints: data.map((item) => {
                return {
                    x: item.grade,
                    y: item.count,
                };
            }),
        }]
    };

    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    );
}
