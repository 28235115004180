import React, { useEffect, useRef } from 'react';
import { XMarkIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const MarketIndexModal = ({ title, content, onClose }) => {
  const contentRef = useRef();

  useEffect(() => {
    document.body.classList.add('overflow-hidden');

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const handleDownloadPDF = () => {
    const textContent = contentRef.current.innerText;

    const docDefinition = {
      content: [
        { text: title, style: 'header' },
        { text: textContent, style: 'content' },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        content: {
          fontSize: 12,
        },
      },
    };

    pdfMake.createPdf(docDefinition).download(`${title.toLowerCase().replace(/\s+/g, '_')}.pdf`);
  }

  return (
    <div className="fixed inset-0 bg-purple-100 bg-opacity-25 flex items-center justify-center z-40" onClick={onClose}>
      <div className="bg-purple-100 text-child-of-light-1000 pt-6 pb-18 px-6 rounded-lg w-4/5 h-3/4 max-w-8xl shadow-md" onClick={(e) => e.stopPropagation()}>
        <div className="sticky flex justify-between items-center mb-4 border-b border-not-tonight-300 pb-4">
          <h2 className="text-2xl font-bold">{title}</h2>
          <div className='flex items-center gap-6'>
            <button className="flex items-center gap-2 bg-not-tonight-200 text-white px-4 py-2 rounded" onClick={handleDownloadPDF}>
              <ArrowDownTrayIcon  className="w-6 h-6"/>
              Download PDF
            </button>
            <XMarkIcon className="w-6 h-6 cursor-pointer" onClick={onClose} />
          </div>
        </div>
        <div className="modal-scroll overflow-auto h-full pb-4 bg-purple-100 p7 leading-6" id="pdf-content" ref={contentRef}>
          {content}
        </div>
      </div>
    </div>
  );
};