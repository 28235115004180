import React from 'react';
import { Radio, FormControlLabel, styled } from '@mui/material';

const CustomRadio = styled(Radio)(({ theme }) => ({
  '& .MuiSvgIcon-root': {
    fontSize: 24,
  },
  '&.MuiRadio-root': {
    color: '#79757E',
    padding: 8,
  },
  '&.Mui-checked': {
    color: '#562A8F',
  }
}));

export const CustomRadioButton = ({ label, value, checked, onChange }) => {
  return (
    <FormControlLabel value={value}
                      control={<CustomRadio checked={checked} onChange={onChange} />}
                      label={<span className='text-child-of-light-1000'>{label}</span>}
    />
  );
};
