import React, { useEffect, useState } from 'react';
import { SteppedLineTo } from 'react-lineto';
import './SalesVolumeBreakdown.css';
import TCGFishApi from '../../../../lib/TCGFishApi';
import { numFormatter } from '../../../../lib/helpers';

export default function SalesVolumeBreakdown() {
  const [salesVolume, setSalesVolume] = useState({});

  useEffect(() => {
    TCGFishApi
      .get('market/getms21')
      .then((res) => {
        setSalesVolume(res.data?.count[0] || {});
      })
      .catch((err) => {
        console.error('Failed to get market/getms21', err);
      });
  }, []);

  const ninetyDays = salesVolume['90days'];
  return (
    <div className="sales__volume__breakdown">
      <div className="svb__box">
        <div className="svb__heading__area">
          <div className="svb__heading">Sales Value Breakdown by Language</div>
        </div>
        <div className="svb__graph__area">
          <div className="svb__left">
            <div className="svb__graph__header">
              <div className="svb__graph__heading">Past 90 Days</div>
            </div>
            <div className="svb__pd__graph">
              <div className="svb__pd__data">
                <div className="svb__pd__jap to6">
                  <p className="svb__language">
                    Japanese Cards:{' '}
                    <span className="svb__pd__jap__value">
                      {ninetyDays ? ninetyDays[1]?.toFixed(2) : null}%
                    </span>
                  </p>
                  <p className="svb__total">
                    Total: {ninetyDays ? numFormatter(ninetyDays[0]?.toFixed(2)) : null}
                  </p>
                </div>
                <div className="svb__pd__eng to5">
                  <p className="svb__language">
                    English Cards:{' '}
                    <span className="svb__pd__eng__value">
                      {ninetyDays ? ninetyDays[3]?.toFixed(2) : null}%
                    </span>
                  </p>
                  <p className="svb__total">
                    Total: {ninetyDays ? numFormatter(ninetyDays[2]?.toFixed(2)) : null}
                  </p>
                </div>
              </div>
              <div className="svb__pd__bar__container">
                {ninetyDays &&
                  <>
                    <div className="svb__pd__bar from5">
                      <div
                        className="svb__pd__bar__japan from6"
                        id="from6"
                        style={{
                          minHeight: `${(ninetyDays[1]?.toFixed(2) / 100) * 400
                            }px`,
                          maxHeight: `${(ninetyDays[1]?.toFixed(2) / 100) * 400
                            }px`,
                        }}
                      ></div>
                    </div>
                    <SteppedLineTo
                      from="from5" to="to5" borderColor="#783EA3" borderWidth={2} delay={1000} toAnchor={'bottom'} fromAnchor={'bottom'}
                    />
                    <SteppedLineTo
                      from="from6" to="to6" borderColor="#F9C013" borderWidth={2} delay={1000} toAnchor={'top'} fromAnchor={'top'}
                    />
                  </>
                }
              </div>
            </div>
          </div>
          <div className="svb__right">
            <div className="svb__graph__header">
              <div className="svb__graph__heading">(2019 - 2023) Full History Statistics</div>
            </div>
            <div className="svb__fhs__graph">
              <div className="svb__fhs__bar__container">
                {ninetyDays &&
                  <>
                    <div className="svb__fhs__bar from8">
                      <div
                        className="svb__fhs__bar__japan from7"
                        style={{
                          minHeight: `${(salesVolume.fullhistory[1]?.toFixed(2) / 100) * 400
                            }px`,
                          maxHeight: `${(salesVolume.fullhistory[1]?.toFixed(2) / 100) * 400
                            }px`,
                        }}
                      ></div>
                    </div>
                    <SteppedLineTo
                      from="from7" to="to7" borderColor="#78AF53" borderWidth={2} delay={1000} toAnchor={'top'} fromAnchor={'top'}
                    />
                    <SteppedLineTo
                      from="from8" to="to8" borderColor="#507AC4" borderWidth={2} delay={1000} toAnchor={'bottom'} fromAnchor={'bottom'}
                    />
                  </>
                }
              </div>
              <div className="svb__fhs__data">
                <div className="svb__fhs__jap to7">
                  <p className="svb__language">
                    Japanese Cards:{' '}
                    <span className="svb__fhs__jap__value">
                      {ninetyDays
                        ? salesVolume.fullhistory[1]?.toFixed(2)
                        : null}
                      %
                    </span>
                  </p>
                  <p className="svb__total">
                    Total:{' '}
                    {ninetyDays ? numFormatter(salesVolume.fullhistory[0]?.toFixed(2)) : null}
                  </p>
                </div>
                <div className="svb__fhs__eng to8">
                  <p className="svb__language">
                    English Cards:{' '}
                    <span className="svb__fhs__eng__value">
                      {ninetyDays
                        ? salesVolume.fullhistory[3]?.toFixed(2)
                        : null}
                      %
                    </span>
                  </p>
                  <p className="svb__total">
                    Total:{' '}
                    {ninetyDays ? numFormatter(salesVolume.fullhistory[2]?.toFixed(2)) : null}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
