import React from 'react';
// import styled from "styled-components";
import CanvasJSReact from "../../../assets/canvasjs.react";
// import { formatDate } from "../../../assets/canvasjs.min";
const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function BarChart({ data }) {
    CanvasJS.addColorSet('BarChartSet', ["#4473C5"]);
    const options = {
        zoomEnabled: true,
        colorSet: "BarChartSet",
        backgroundColor: "#0E1628",
        theme: "none",
        animationEnabled: true,
        animationDuration: 2000,
        height: 300,
        // width: 650,
        axisX: {
            margin: 10,
            padding: {
                top: 50,
            },
            intervalType: "minute",
            interval: 30,
            labelFontColor: "#fff",
            // tickLength: 20,
            labelFontSize: 11,
            tickColor: "#08131F",
            lineColor: "#4473C5",
            includesZero: true,
            lineThickness: 1,
            valueFormatString: "H:mm",
        },
        axisY: {

            // margin: 20,
            labelFontColor: "#fff",
            includesZero: true,
            valueFormatString: "#,###,###",
            // tickLength: 20,
            // minimum: 1,
            tickColor: "#08131F",
            lineThickness: 0,
            lineColor: "#08131F",
        },
        // toolTip: {
        //   content:
        //     "Grade {name}:<br/>Date: <strong>{x}</strong><br/>Average Price: <strong>${y}</strong>",
        // },
        // legend: {
        //   cursor: "pointer",
        //   fontColor: "#fff",
        //   maxWidth: 600,
        //   itemWidth: 80,
        //   markerMargin: 10,
        //   itemclick: function (e) {
        //     if (
        //       typeof e.dataSeries.visible === "undefined" ||
        //       e.dataSeries.visible
        //     ) {
        //       e.dataSeries.visible = false;
        //     } else {
        //       e.dataSeries.visible = true;
        //     }
        //     e.chart.render();
        //   },
        // },
        data: [{
            // yValueFormatString: "#,### Units",
            xValueFormatString: "YYYY-MMM-DD H:mm",
            markerType: "circle",
            markerSize: 0,
            showInLegend: false,
            indexLabel: "{y}",
            indexLabelPlacement: "outside",
            indexLabelOrientation: "horizontal",
            indexLabelFontColor: "#fff",
            type: "column",
            dataPoints: data.map((item) => {
                return {
                    x: new Date(new Date(item.acutal_time).getFullYear(), new Date(item.acutal_time).getMonth(), new Date(item.acutal_time).getDate(), new Date(item.acutal_time).getHours(), new Date(item.acutal_time).getMinutes()),
                    y: item.count,
                    // indexLabel: item.count
                };
            }),
        }]
    };

    return (
        <div>
            <CanvasJSChart options={options} />
        </div>
    );
}

