import React, { useContext, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid';
import ReactGTM from 'react-gtm-module';

import logo from '../assets/images/modal_logo.svg';
import TCGFishApi from '../lib/TCGFishApi';
import { IconButtonBig } from './IconButtonBig';
import { PrimaryLargeButton } from './PrimaryLargeButton';
import { AuthContext } from '../contexts/AuthContext';
import { ALL_FIELDS_ARE_REQUIRED_MESSAGE, EMAIL_NOT_VERIFIED_MESSAGE, UNEXPECTED_ERROR_MESSAGE } from '../lib/constants';

const initialValues = { email: '', password: '' };

export const LogInModal = ({ close, showSignUpModal, showForgotPasswordModal }) => {
  const { setUser } = useContext(AuthContext);
  const [formValues, setFormValues] = useState(initialValues);
  const [passwordType, setPasswordType] = useState('password');
  const [showResendEmail, setShowResendEmail] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
    password: '',
    generic: ''
  });

  const togglePasswordType = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
    } else {
      setPasswordType('password');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    if (formValues.email === '' || formValues.password === '') {
      setErrors({ generic: ALL_FIELDS_ARE_REQUIRED_MESSAGE });
      return;
    }

    const newErrors = {};

    if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = 'Please enter valid email.';
    }

    let validationError = false;
    for (const property in newErrors) {
      validationError ||= !!newErrors[property];
    }
    if (validationError) {
      setErrors(newErrors);
      return;
    }

    TCGFishApi
      .post('session',
        {
          email: formValues.email,
          password: formValues.password
        }
      )
      .then((res) => {
        setUser({
          ...res.data,
          char: res.data.fullName.charAt(0).toUpperCase()
        });

        ReactGTM.dataLayer({ dataLayer: { userId: res.data.id || null } });
      })
      .catch((err) => {
        console.error('Login request failed:', err);

        if (err.response?.data?.errors?.length) {
          err.response.data.errors.forEach((errObj) => {
            newErrors[errObj.property] = errObj.message;
          });
          const email_not_verified = err.response.data.errors.find(obj => obj.message.includes(EMAIL_NOT_VERIFIED_MESSAGE));
          if (email_not_verified) {
            setShowResendEmail(!!email_not_verified);
            return;
          }

          setErrors(newErrors);
          return;
        }

        setErrors({ generic: UNEXPECTED_ERROR_MESSAGE });
      });
  };

  const requestResendEmail = () => {
    setErrors({});

    TCGFishApi
      .post(
        'user/resend-confirm-email',
        {
          email: formValues.email,
          password: formValues.password
        }
      )
      .then((res) => {
        if (res.data.message === 'Already confirmed, please log in.') {
          setShowResendEmail(false);
        }
        // TODO: success message
      })
      .catch((err) => {
        console.error('Failed to request resend-confirm-email:', err);

        const newErrors = {};

        if (err.response?.data?.errors?.length) {
          err.response.data.errors.forEach((errObj) => {
            newErrors[errObj.property] = errObj.message;
          });
          setErrors(newErrors);
          return;
        }

        setErrors({ generic: UNEXPECTED_ERROR_MESSAGE });
      });
  };

  return (
    <div className="flex items-center fixed top-0 left-0 w-full h-screen overflow-x-hidden overflow-y-auto z-50 bg-modal-shadow bg-opacity-81 backdrop-blur"
         onClick={close}
    >
      <div className="flex flex-col flex-shrink-0 relative w-133 mx-auto rounded-lg border-min border-not-tonight-200 bg-purple-100 overflow-hidden"
           onClick={(e) => e.stopPropagation()}
      >
        <svg className="absolute top-0 left-0"
             width="231" height="468" viewBox="0 0 231 468" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M-98.3383 374.099L218.169 110.5L-98.3383 -153.099L-98.3383 374.099Z" stroke="#341956" strokeWidth="22.8228"/>
            <path d="M-125.338 374.099L191.169 110.5L-125.338 -153.099L-125.338 374.099Z" stroke="#221139" strokeWidth="22.8228"/>
            <path d="M170.261 118.611C175.328 114.391 175.329 106.609 170.261 102.389L-148.439 -163.036C-155.315 -168.763 -165.75 -163.873 -165.75 -154.926L-165.75 375.925C-165.75 384.873 -155.315 389.763 -148.439 384.036L170.261 118.611Z"
                  fill="#11081D"/>
          </g>
        </svg>
        <svg className="absolute top-0 left-0"
             width="520" height="569" viewBox="0 0 520 569" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.5">
            <path d="M510.167 613.101L98.8366 281.5L510.167 -50.1009L510.167 613.101Z" stroke="#341956" strokeWidth="28.6654"/>
            <path d="M546.167 613.101L134.837 281.5L546.167 -50.1009L546.167 613.101Z" stroke="#221139" strokeWidth="28.6654"/>
            <path d="M167.012 291.869C160.351 286.561 160.351 276.439 167.012 271.131L586.23 -62.9178C594.915 -69.8382 607.75 -63.6541 607.75 -52.5493L607.75 615.549C607.75 626.654 594.915 632.838 586.23 625.918L167.012 291.869Z"
                  fill="#0C0415"/>
          </g>
        </svg>


        <div className="self-end mt-3.5 mr-5.5 z-10">
          <IconButtonBig Icon={XMarkIcon}
                         onClick={close}
          />
        </div>
        <img className="w-20.75 h-12.5 flex-shrink-0 mx-auto -mt-5.5 z-10"
             src={logo}
             alt="TCGFish logo"
        />
        <h3 className="text-not-tonight-1000 h8 mx-auto mt-8 mb-7 z-10">
          Login
        </h3>
        <form className="flex flex-col w-105 mx-auto gap-5 z-10"
              onSubmit={handleSubmit}
        >
          <div className={`w-full flex flex-col flex-shrink-0 items-start ${errors.email ? 'h-17.25' : ''}`}>
            <input className={`w-full flex flex-shrink-0 items-center h-12 py-2.75 px-4 rounded-lg bg-not-tonight-200 outline-none text-white hover:border-min hover:border-not-tonight-300 focus:bg-purple-100 focus:placeholder:text-purple-100 ${errors.email ? 'border-dusk-orange-700 border-min' : ''}`}
                   type="text"
                   placeholder="Email"
                   name="email"
                   value={formValues.email}
                   onChange={(e) => setFormValues({...formValues, email: e.target.value})}
            />
            {errors.email &&
              <p className="mt-1 text-dusk-orange-700 p7">
                {errors.email}
              </p>
            }
          </div>
          <div className="w-full flex flex-shrink-0 items-center relative">
            <input className="w-full flex flex-shrink-0 h-12 py-2.75 pl-4 pr-12 rounded-lg bg-not-tonight-200 outline-none text-white hover:border-min hover:border-not-tonight-300 focus:bg-purple-100 focus:placeholder:text-purple-100"
                   type={passwordType}
                   placeholder="Password"
                   name="password"
                   value={formValues.password}
                   onChange={(e) => setFormValues({...formValues, password: e.target.value})}
            />
            {passwordType === 'password' ?
              <EyeSlashIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                            onClick={togglePasswordType}
              />
              :
              <EyeIcon className="w-5 h-5 absolute right-4 cursor-pointer text-child-of-light-500"
                       onClick={togglePasswordType}
              />
            }
          </div>
          <div className="flex justify-end text-link-purple cursor-pointer p6 -mt-3"
               role="link"
               onClick={showForgotPasswordModal}
          >
            Forgot your password?
          </div>
          {errors.generic &&
            <p className="p7 text-dusk-orange-700">
              {errors.generic}
            </p>
          }
          {showResendEmail &&
            <>
              <p className="p7 text-dusk-orange-700">
                It looks like your email is not verified. Please check your email for verification link.
              </p>
              <div className="flex gap-2">
                <p className="p7 text-child-of-light-500">
                  Didn’t receive a link?
                </p>
                <a className="p6 text-link-purple"
                   href="#"
                   rel="nofollow"
                   onClick={requestResendEmail}
                >
                  Resend
                </a>
              </div>
            </>
          }
          <PrimaryLargeButton className="flex w-105 h-12"
                              text="Login"
                              onClick={handleSubmit}
          />
          <div className="inline-flex justify-center items-start gap-2 my-4">
              <span className="text-white p4">
                Don’t have an account?
              </span>
            <div className="text-link-purple p3 cursor-pointer"
                 onClick={showSignUpModal}
            >
              Sign Up!
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

