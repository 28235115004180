import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetadataTags = ({ title, description, keywords, image }) => {
  return (
    <Helmet prioritizeSeoTags>
      { /* Standard metadata tags */ }
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />

      { /* Facebook tags */ }
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      {image &&
        <meta property="og:image" content={image} />
      }

      { /* Twitter tags */ }
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:url" content={window.location.href} />
      {image &&
        <meta property="twitter:image" content={image} />
      }
    </Helmet>
  );
};

export default MetadataTags;
