import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon, EnvelopeIcon } from '@heroicons/react/20/solid';

import logo from '../assets/images/tcglogo.png';
import { SecondaryMediumButton } from './SecondaryMediumButton';

export const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    showResearchDropdown: false
  });

  const researchToolRef = useRef(null);
  const researchDropdownRef = useRef(null);

  const routeLinkClass = (currentRoute) => {
    if (currentRoute === 'research tool' && ['/market', '/setvalue', '/ranking'].includes(location.pathname)) {
      return 'text-white';
    }

    return currentRoute === location.pathname ? 'text-white' : 'text-not-tonight-900';
  };

  const handleResearchToolClick = (evt) => {
    if (evt?.key === 'Tab') {
      return;
    }

    setState((prevState) => {
      return {
        ...prevState,
        showResearchDropdown: !prevState.showResearchDropdown
      };
    });
  };

  const handleResearchDropdownLinkClick = () => {
    handleResearchToolClick();
  };

  useEffect(() => {
    function handleClickOutsideResearchDropdown(evt) {
      if (!researchDropdownRef.current ||
        !researchToolRef.current ||
        researchDropdownRef.current.contains(evt.target) ||
        researchToolRef.current.contains(evt.target)
      ) {
        return;
      }

      setState((prevState) => {
        return {
          ...prevState,
          showResearchDropdown: false
        };
      });
    }

    document.addEventListener('mousedown', handleClickOutsideResearchDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideResearchDropdown);
    };
  }, [researchToolRef, researchDropdownRef]);

  return (
    <footer className="w-full h-[17.4375rem] m-auto relative">
      <div className="w-full h-11.1 mt-[6.1875rem] px-15 box-border">
        <nav>
          <Link className="inline-block"
                to="/"
          >
            <img className="w-18 h-11.1"
                 src={logo}
                 alt="TCGFish logo"
            />
          </Link>
          <Link className="ml-9.1 inline-block"
                to="/search"
          >
            <span className={`p7 ${routeLinkClass('/search')}`}>
              Catalog
            </span>
          </Link>
          <Link className="ml-6 inline-block"
                to="/auction-monitor"
          >
            <span className={`p7 ${routeLinkClass('/auction-monitor')}`}>
              Auction Monitor
            </span>
          </Link>
          <Link className="ml-6 inline-block"
                to="/undervalue-bot"
          >
            <span className={`p7 ${routeLinkClass('/undervalue-bot')}`}>
              Undervalue Bot
            </span>
          </Link>
          <Link className="ml-6 inline-block"
                to="/market-index"
          >
            <span className={`p7 ${routeLinkClass('/market-index')}`}>
              Market Index
            </span>
          </Link>
          <div className={`inline-block ml-6 p7 cursor-pointer relative ${routeLinkClass('research tool')} ${state.showResearchDropdown && 'text-white'}`}
               onClick={handleResearchToolClick}
               onKeyDown={handleResearchToolClick}
               ref={researchToolRef}
               tabIndex="0"
          >
            Research Tool
            {state.showResearchDropdown ?
              <ChevronRightIcon className="inline-block w-5 h-5 -mt-1 text-white" />
            :
              <ChevronDownIcon className="inline-block w-5 h-5 -mt-1 text-white" />
            }
            {state.showResearchDropdown &&
              <div className="flex flex-col flex-shrink-0 justify-center items-start w-50 h-36 absolute -right-50 top-0 z-40 gap-1.5 rounded-lg bg-purple-100 p7 text-not-tonight-1000 header_research-dropdown"
                   ref={researchDropdownRef}
              >
                <Link className="flex flex-shrink-0 items-center w-50 h-9 px-4 gap-2"
                      onClick={handleResearchDropdownLinkClick}
                      onKeyDown={(evt) => { evt.key === 'Enter' && navigate('/market') }}
                      to="/market"
                >
                  Market Research Tool
                </Link>
                <Link className="flex flex-shrink-0 items-center h-9 px-4 gap-2"
                      onClick={handleResearchDropdownLinkClick}
                      onKeyDown={(evt) => { evt.key === 'Enter' && navigate('/setvalue') }}
                      to="/setvalue"
                >
                  Master Set Value
                </Link>
                <Link className="flex flex-shrink-0 items-center h-9 px-4 gap-2"
                      onClick={handleResearchDropdownLinkClick}
                      onKeyDown={(evt) => { evt.key === 'Enter' && navigate('/ranking') }}
                      to="/ranking"
                >
                  Market Cap Ranking
                </Link>
              </div>
            }
          </div>
        </nav>
        <div className="flex items-end gap-6 absolute top-28 right-0 p-inherit pt-0">
          <Link to={process.env.REACT_APP_DISCORD_URL}>
            <div className="flex flex-shrink-0 w-50 h-10 pl-5 pr-6 py-3 justify-center items-center gap-2 p7 text-white box-border bg-discord-main border-discord-main rounded-lg border-min">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.375rem" height="1.5rem" viewBox="0 0 1.375rem 1.5rem" fill="none">
                <g clipPath="url(#clip0_446_736)">
                  <path d="M19.8944 0H3.07337C1.65488 0 0.5 1.10239 0.5 2.46838V18.6687C0.5 20.0347 1.65488 21.137 3.07337 21.137H17.3085L16.6432 18.9203L18.25 20.3462L19.7689 21.6882L22.4678 23.9649V2.46838C22.4678 1.10239 21.3129 0 19.8944 0ZM15.049 15.6491C15.049 15.6491 14.5971 15.1338 14.2205 14.6785C15.8649 14.2351 16.4926 13.2526 16.4926 13.2526C15.9779 13.5761 15.4883 13.8038 15.049 13.9596C14.4213 14.2112 13.8188 14.3789 13.2288 14.4748C12.0237 14.6905 10.919 14.6306 9.97754 14.4628C9.26202 14.331 8.64692 14.1393 8.13225 13.9476C7.84353 13.8397 7.5297 13.7079 7.21588 13.5402C7.17822 13.5162 7.14056 13.5042 7.1029 13.4803C7.07779 13.4683 7.06524 13.4563 7.05269 13.4443C6.82673 13.3245 6.7012 13.2406 6.7012 13.2406C6.7012 13.2406 7.30375 14.1992 8.89798 14.6545C8.52139 15.1099 8.05693 15.6491 8.05693 15.6491C5.28271 15.5652 4.22825 13.8277 4.22825 13.8277C4.22825 9.9694 6.03589 6.84198 6.03589 6.84198C7.84353 5.54787 9.5633 5.58382 9.5633 5.58382L9.68883 5.72761C7.42928 6.3507 6.38738 7.29731 6.38738 7.29731C6.38738 7.29731 6.66354 7.15352 7.12801 6.94982C8.47118 6.38664 9.53819 6.23087 9.97754 6.19493C10.0529 6.18294 10.1156 6.17096 10.1909 6.17096C10.9567 6.0751 11.8228 6.05114 12.7267 6.147C13.9192 6.2788 15.1996 6.61431 16.5051 7.29731C16.5051 7.29731 15.5134 6.39863 13.3794 5.77554L13.5552 5.58382C13.5552 5.58382 15.2749 5.54787 17.0826 6.84198C17.0826 6.84198 18.8902 9.9694 18.8902 13.8277C18.8902 13.8277 17.8232 15.5652 15.049 15.6491ZM9.21181 10.0533C8.49629 10.0533 7.9314 10.6524 7.9314 11.3833C7.9314 12.1143 8.50884 12.7134 9.21181 12.7134C9.92733 12.7134 10.4922 12.1143 10.4922 11.3833C10.5048 10.6524 9.92733 10.0533 9.21181 10.0533ZM13.7937 10.0533C13.0781 10.0533 12.5133 10.6524 12.5133 11.3833C12.5133 12.1143 13.0907 12.7134 13.7937 12.7134C14.5092 12.7134 15.0741 12.1143 15.0741 11.3833C15.0741 10.6524 14.5092 10.0533 13.7937 10.0533Z" fill="white"/>
                </g>
                <defs>
                  <clipPath id="clip0_446_736">
                    <rect width="22" height="24" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              Join our Discord
            </div>
          </Link>
          <Link to={`mailto:${process.env.REACT_APP_CONTACT_EMAIL}`}>
            <SecondaryMediumButton text="Connect via email"
                                   Icon={EnvelopeIcon}
            />
          </Link>
        </div>
      </div>
      <hr className="w-full mt-8 border-not-tonight-200 border-min" />
      <p className="w-full mt-5.1 px-15 box-border text-child-of-light-400 p8">
        All information used in the website are public information. This website does not contain any financial advice. Pokémon © 2002-2023 Pokémon. © 1995-2023 Nintendo/Creatures Inc./GAME FREAK inc. TM, ® and Pokémon character names are trademarks of Nintendo. This website is not produced, endorsed, supported, or affiliated with Nintendo/Creatures Inc./GAME FREAK inc.
      </p>
      <div className="w-full mt-4 px-15 box-border relative">
        <Link className="text-child-of-light-1000 p8 underline"
              to="/terms-of-use"
        >
          Terms of Use
        </Link>
        <Link className="ml-8 text-child-of-light-1000 p8 underline"
              to="/privacy-policy"
        >
          Privacy Policy
        </Link>
        <span className="p-inherit text-not-tonight-1000 p8 absolute top-1 right-0">
          Copyright TCG Fish © {new Date().getFullYear()}
        </span>
      </div>
    </footer>
  );
};
