import React from 'react';
import { useNavigate } from 'react-router-dom';

import './TermsOfUse.css';

export default function TermsOfUse() {
  const navigate = useNavigate();

  return (
    <div className="terms_of_use">
      <h2 className="page__title">TCG FISH TERMS OF USE</h2>
      <section>
        <div className="last_updated"> Last updated: 20 September, 2023 </div>
        <div>
          <p>
            These TCG Fish Terms of Use (<span className="bold">“Terms”</span>) are entered into by\and between you (<span className="bold">“you”</span> and <span className="bold">“your”</span>) and <span className="bold">Collectible Data Insights, LLC</span> (together <span className="bold">“TCG Fish,” “we,” “our,”</span> or <span className="bold">“us”</span>) and govern your use of any website, mobile application and any related services offered by us that directly links to these Terms (herein referred to as the <span className="bold">“Service”</span>), whether as a guest or a registered user.
          </p>
          <p>
            READ THESE TERMS CAREFULLY AS THEY CONTAIN TERMS AND CONDITIONS THAT IMPACT YOUR RIGHTS,
            OBLIGATIONS AND REMEDIES IN CONNECTION WITH YOUR USE OF THE SERVICE,
            INCLUDING A CLASS ACTION WAIVER UNDER SECTION 13. BELOW AND AN AGREEMENT TO ARBITRATE FOR
            ANY DISPUTE RESOLUTION UNDER SECTION 12. BELOW.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          1. Acceptance
        </h3>
        <div>
          <p>
            BY CLICKING THE “I ACCEPT” BUTTON OR ANY SIMILAR ATTESTATION WHEN SUCH OPTION IS MADE AVAILABLE
            TO YOU OR BY OTHERWISE USING THE SERVICE, YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS EFFECTIVE
            AS OF THE DATE OF SUCH ACTION. YOU EXPRESSLY ACKNOWLEDGE AND REPRESENT THAT YOU HAVE CAREFULLY
            REVIEWED THESE TERMS AND FULLY UNDERSTAND THE RISKS, COSTS AND BENEFITS RELATED TO TRANSACTIONS
            MADE USING THE SERVICE. IF YOU DO NOT AGREE WITH THESE TERMS, THEN YOU ARE EXPRESSLY PROHIBITED
            FROM USING THE SERVICE.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          2. Changes to these Terms
        </h3>
        <div>
          <p>
            We reserve the right, in our sole discretion, to make changes or modifications
            to these Terms
            at any time and for any reason. All changes are effective immediately when we post them.
            No changes will apply retroactively as to any claims existing prior to the “last updated” date.
            It is your responsibility to regularly check these Terms to stay informed of updates, as they are binding.
            We will indicate that these Terms have been updated by updating the “last updated” date
            at the top of these Terms. Your continued use of the Service following the posting of revised Terms
            means that you accept and agree to the changes.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          3. Collectibles
        </h3>
        <div>
          <p>
            The Service includes proprietary technology to help compare and assess collectibles,
            including but not limited to playing cards
            (<span className="bold">“Collectibles”</span>)
            currently offered for sale through various third-party sources, and providing users
            of the Service a link to such third-party sources. Collectibles are offered for sale through
            the Service, and information provided with respect to Collectibles is for research purposes only
            (as further provided in Section 9(b) below).
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          4. Access to the Service and Account Security
        </h3>
        <div>
          <p>
            We reserve the right to withdraw or amend this Service, and any service or materials
            we provide in connection to the Service, in our sole discretion without notice.
            We will not be liable if for any reason all or any part of the Service is unavailable
            at any time or for any period. From time to time, we may restrict access to some parts
            of the Service, or the entire Service, to users, including registered users.
          </p>
        </div>
        <div>
          <p>You are responsible for both:</p>
          <ul>
            <li>Making all arrangements necessary for you to have access to the Service.</li>
            <li>
              Ensuring that all persons who access the Service through your internet connection
              are aware of these Terms and comply with them.
            </li>
          </ul>
        </div>
        <div>
          <p>
            To use certain features of the Service, you may be asked to provide certain registration
            details or other information to create an account as applicable (<span className="bold">“Account”</span>).
            It is a condition of your use of the Service that all the Account information you
            provide to us through the Service is correct, current, and complete.
            It is your responsibility to update any Account information when it becomes out-of-date.
            Any personal data processed by us with respect to your Account will be done so in accordance
            with our Privacy Policy (as defined below). You also acknowledge that your Account is personal
            to you and agree not to provide any other person with access the Service or portions
            of it using your user name, password or other security information. You agree to notify
            us immediately of any unauthorized access to or use of your user name or password or any other
            breach of security.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          5. Prohibited Uses
        </h3>
        <div>
          <p>You may use the Service only for lawful purposes and in accordance with these Terms.
            You agree not to use the Service:</p>
          <ul>
            <li>
              In any way that violates any applicable federal, state, local or international law
              or regulation (including, without limitation, any laws regarding the export of data
              or software to and from the US or other countries).
            </li>
            <li>
              For the purpose of exploiting, harming or attempting to exploit or harm minors in
              any way by exposing them to inappropriate content, asking for personally identifiable
              information or otherwise.
            </li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any material which does
              not comply with the these Terms.
            </li>
            <li>
              To transmit, or procure the sending of, any advertising or promotional material without
              our prior written consent, including any “junk mail,” “chain letter,” “spam,” or
              any other similar solicitation.
            </li>
            <li>
              To impersonate or attempt to impersonate the TCG Fish, a TCG Fish employee, another user
              or any other person or entity (including, without limitation, by using e-mail addresses associated
              with any of the foregoing).
            </li>
            <li>
              To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment
              of the Service, or which, as determined by us, may harm the TCG Fish or users of the Service,
              or expose them to liability.
            </li>
          </ul>
        </div>
        <div>
          <p>Additionally, you agree not to:</p>
          <ul>
            <li>
              Use the Service in any manner that could disable, overburden, damage, or impair the site
              or interfere with any other party’s use of the Service, including their ability to engage
              in real time activities through the Service.
            </li>
            <li>
              Use any robot, spider or other automatic device, process or means to access the Service
              for any purpose, including monitoring, scraping or otherwise copying any of the material
              on the Service.
            </li>
            <li>
              Use any manual process to monitor or copy any of the material on the Service, including with
              respect to information in connection with any Collectibles, or for any other purpose not expressly
              authorized in these Terms, without our prior written consent.
            </li>
            <li>
              Use any device, software or routine that interferes with the proper working of the Service
              or in any manner that adversely affects TCG Fish.
            </li>
            <li>
              Introduce any viruses, trojan horses, worms, logic bombs or other material which is malicious
              or technologically harmful.
            </li>
            <li>
              Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts
              of the Service, the server on which the Service is stored, or any server, computer
              or database connected to the Service.
            </li>
            <li>
              Attack the Service via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
          </ul>
        </div>
        <div>
          <p>
            We may, in our sole discretion, take any necessary or desirable actions and/or exercise
            any of our rights and remedies any time and for any reason without notice in the event
            you breach these Terms. Any violation of any of the above, including, without limitation,
            use of the Service in breach of these Terms, may subject you to civil liability,
            criminal prosecution, and termination of your rights to use the Service.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          6. Privacy Policy
        </h3>
        <div>
          <p>
            Our privacy policy found at (
            <span className="bold link_to"
                  role="button"
                  onClick={() => { navigate('/privacy-policy') }}
            >
              “Privacy Policy”
            </span>
            ) describes the ways we collect, use, store, and share your personal information
            collected through the use of the Service.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          7. Third Party Services and Links
        </h3>
        <div>
          <p>
            Certain functionality of the Service may incorporate, use, or otherwise depend
            on services offered by third parties for certain functionalities, as well as provide
            links to or otherwise incorporate websites, content, resources, or goods/services
            offerings provided by third parties (collectively,
            <span className="bold">“Third-Party Services”</span>).
            If any event were to disrupt any functionality dependent on a Third-Party Service,
            the Service may similarly experience a disruption, and we shall not be responsible
            or liable for any such disruption. Links to Third-Party Services are provided
            for your convenience only. We have no control over the contents
            of Third-Party Services, and we accept no responsibility for them or for any loss
            or damage that may arise from your use of them. If you decide to access or use any
            of the Third-Party Services you do so subject to the terms and conditions
            of the applicable Third-Party Services provider.
            TCG Fish SHALL NOT HAVE AND HEREBY DISCLAIMS ALL RESPONSIBILITY AND LIABILITY
            IN CONNECTION WITH (AND YOU ACKNOWLEDGE AND ASSUME SOLE RESPONSIBILITY FOR AND
            ALL RISK ARISING FROM), YOUR ENGAGEMENT WITH, DECISION TO PURCHASE,
            OR USE OF ANY THIRD-PARTY SERVICES, OR THE GOODS, CONTENT, PRODUCTS, ITEMS,
            SERVICES, OFFERINGS OR ASSETS AVAILABLE ON OR THROUGH SUCH THIRD-PARTY SERVICES.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          8. Third Party Services and Links
        </h3>
        <h4 className="section__subtitle">
          a. <span className="underline">Ownership of the Service and Service Materials</span>
        </h4>
        <div>
          <p>
            You acknowledge and agree that we (or, as applicable, our licensors) own all right,
            title, and interest in and to all elements of the Service, including,
            without limitation, all Service Data as defined in Section 8(d) below,
            and all graphics, design, systems, methods, information, computer code, software,
            services, “look and feel”, organization, compilation of the content, code, data,
            and all other elements of the Service (collectively, the “Service Materials”).
            The Service and Service Materials are protected by copyright, trade dress,
            trademark, patent laws, international conventions, other relevant intellectual
            property and proprietary rights, and applicable laws. Your use of the Service
            or any purchase or your purchase from the Service, does not grant you ownership
            of any other rights with respect to the Service Materials or the Service,
            whether expressly, by implication, estoppel, reliance or otherwise,
            all of which are specifically excluded and disclaimed.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          b. <span className="underline">Trademarks</span>
        </h4>
        <div>
          <p>
            The Service may include names, logos, product and service names,
            designs and slogans that are trademarks owned by TCG Fish,
            its licensors or other third parties. Unless otherwise permitted under applicable law,
            you must not use such marks without the prior written permission of the owner
            of such trademarks.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          c. <span className="underline">User Contributions</span>
        </h4>
        <div>
          <p>
            As a user of the Service, you may be able to submit certain content to
            the Service (“Submitted Content”). You agree that you have and will maintain,
            for yourself and on behalf of your licensors, all necessary rights, consents,
            and permissions to provide Submitted Content to the Service and that
            the Submitted Content does not and will not violate any applicable laws
            or regulations, including by infringing upon or violating intellectual property rights,
            publicity rights, privacy rights or any other rights of anyone else, or violate
            any Service content standards that we may establish from time to time. Except with respect
            to any Service Data and the limited rights granted to TCG Fish herein, you or your
            licensors shall at all times remain the owner of all right title and interest in and
            to your Submitted Content. Notwithstanding anything to the contrary, you hereby grant
            to us and our affiliates a perpetual non-exclusive license to use, reproduce,
            distribute, display, and modify the Submitted Content as necessary to provide the Service,
            including to maintain records of Collectibles (such as pictures of such Collectibles)
            provided in connection with the Platform.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          d. <span className="underline">Service Data</span>
        </h4>
        <div>
          <p>
            TCG Fish may derive or otherwise collect Service Data in connection with your
            use of the Service to improve and further develop the Service and other products
            and features. “Service Data” means data and information related to your use
            of the Service and/or interest in Collectibles. To the extent any Service Data
            includes personal information, such personal information shall be processed
            in accordance with our Privacy Policy.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          9. Disclaimers of Warranties
        </h3>
        <h4 className="section__subtitle">
          a. <span className="underline">“AS-IS” DISCLAIMER</span>
        </h4>
        <div>
          <p>
            YOUR ACCESS TO AND USE OF THE SERVICE, SUBMITTED CONTENT, AND ANY SERVICE
            OR PRODUCT PURCHASED THROUGH OUR SERVICE OR A THIRD-PARTY SERVICE IS AT YOUR
            OWN RISK. THE SERVICE, SUBMITTED CONTENT, AND ANY SERVICE OR PRODUCT PURCHASED
            THROUGH OUR SERVICE OR A THIRD-PARTY SERVICE ARE PROVIDED “AS IS” AND WITHOUT
            ANY REPRESENTATION OR WARRANTY OR CONDITION OF ANY KIND. TO THE EXTENT NOT
            PROHIBITED BY APPLICABLE LAW, TCG FISH, ITS OFFICERS, EMPLOYEES, DIRECTORS,
            SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, ITS COMMUNITY HOSTS,
            AND ITS LICENSORS (“TCG FISH PARTIES”) DISCLAIM ALL WARRANTIES, CONDITIONS,
            AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE,
            INCLUDING WITHOUT LIMITATION (i) THOSE RELATED TO MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, SATISFACTORY QUALITY, AND NON-INFRINGEMENT,
            (ii) THOSE ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE,
            (iii) THAT THE ACCESS TO OR USE OF THE SERVICE WILL BE SECURE, TIMELY,
            UNINTERRUPTED OR ERROR-FREE OR WILL BE COMPATIBLE OR OPERATE IN COMBINATION
            WITH ANY OTHER HARDWARE, SOFTWARE, SYSTEM OR DATA,
            (iv) THAT THE SERVICE WILL BE FREE OF HARMFUL COMPONENTS; AND (v) IN RELATION
            TO THE CONTENT OF ANY THIRD-PARTY SERVICES LINKED TO OR INTEGRATED WITH THE SERVICE.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          b. <span className="underline">Collectible Due Diligence is Your Responsibility</span>
        </h4>
        <div>
          <p>
            Users are responsible for conducting their own due diligence on all Collectibles
            and potential purchases concerning the same and take sole responsibility for
            all decisions with respect to Collectibles. The Services are provided as
            an information tool only, and are not by any means intended to be relied upon as
            the sole factor for making any determination of whether or not to purchase
            a Collectible. TCG Fish does not provide any valuations, appraisals,
            or other information future forecasts of valuations for any Collectible.
            Any information that is provided is based on information gathered from third-party
            sources and is intended to be a resource for identifying Collectibles for sale
            and assessing the current asking price for such Collectible. TCG Fish shall not be
            expected or obligated to attempt to verify the validity, accuracy or reliability
            of any such third party information and TCG Fish shall in no way be held liable
            for any such information. Any reliance on information provided through the Service
            is at your own risk.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          10. Limitation of Liability
        </h3>
        <div>
          <p>TO THE FULLEST EXTENT NOT PROHIBITED BY APPLICABLE LAW, YOU AGREE AS FOLLOWS:</p>
          <ol>
            <li>
              IN NO EVENT WILL TCG FISH PARTIES BE LIABLE FOR (i) ANY INCIDENTAL,
              INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR DAMAGES OR LIABILITIES
              WHATSOEVER, OR (ii) ANY DAMAGES FOR LOSS OF ANY DIGITAL ASSETS, DATA, INFORMATION,
              REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL BENEFITS, IN ANY CASE WHETHER
              UNDER CONTRACT, TORT, NEGLIGENCE, STATUTE, STRICT LIABILITY OR OTHER THEORY EVEN
              IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, ARISING OUT OF
              OR CONNECTED WITH ACCESS OR USE OF THE SERVICE, INCLUDING, WITHOUT LIMITATION,
              THE FOLLOWING: (I) ANY UNAUTHORIZED USE OF THE SERVICE; (II) ANY BUGS, VIRUSES,
              TROJAN HORSES, OR THE LIKE THAT ARE FOUND IN THE SERVICE (REGARDLESS OF THE SOURCE
              OF ORIGINATION); (iii) ANY INJURY OR DAMAGE TO COMPUTER EQUIPMENT;
              OR (iv) THEFT, TAMPERING, OR DESTRUCTION OF, OR UNAUTHORIZED ACCESS TO,
              DIGITAL ASSETS, OR DATA OR CONTENT OF ANY KIND; AND
            </li>
            <li>
              IN NO EVENT SHALL OUR LIABILITY TO YOU OR ANY OTHER PERSON OR ENTITY FOR
              ALL DIRECT DAMAGES OR LIABILITIES EXCEED IN THE AGGREGATE THE AMOUNT
              OF TWENTY-FIVE U.S. DOLLARS (USD $25.00).
            </li>
          </ol>
        </div>
        <div>
          <p>
            NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS, SOME JURISDICTIONS
            DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
            OR INCIDENTAL DAMAGES OR OTHER DAMAGES, SO THE ABOVE LIMITATIONS MAY NOT APPLY
            TO YOU IF YOU ARE LOCATED IN SUCH A JURISDICTION.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          11. Indemnity
        </h3>
        <div>
          <p>
            You agree to defend, indemnify, and hold harmless the TCG Fish Parties
            from and against any and all claims, costs, proceedings, demands, losses,
            damages, and expenses (including, without limitation, reasonable attorney’s fees
            and legal costs) of any kind or nature relating to third party claims arising
            out of (i) any actual or alleged breach of these Terms by you or anyone using
            an Account of yours, and (ii) Submitted Content. If we assume the defense of such
            a matter, you shall reasonably cooperate with us in such defense.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          12. Governing Law and Dispute Resolution
        </h3>
        <div>
          <p>
            The Terms shall be governed by and interpreted in accordance with the
            laws of Delaware without regard to conflict of law principles.
            All disputes, claims and actions, at law or in equity, in connection with
            the Terms or the breach, termination, enforcement, interpretation or
            validity thereof, use of the Service (collectively
            <span className="bold">“Disputes”</span>) shall be settled by binding arbitration
            administered by the American Arbitration Association under its
            Commercial Arbitration Rules (“AAA Rules”), and judgment on the award rendered
            by the arbitrator(s) may be entered in any court having jurisdiction thereof.
            The arbitration shall take place in Wilmington, DE before a single neutral
            arbitrator appointed in accordance with the AAA Rules and shall be conducted
            in the English language. All arbitrations shall be conducted and resolved
            on an individual basis and not a class-wide, multiple plaintiff or similar basis.
            No arbitration shall be consolidated with any other arbitration proceeding
            involving any other person or entity.
          </p>
          <p>
            You have the right to opt-out and not be bound by these arbitration and waiver
            of class action provisions by sending written notice of your decision to opt-out
            to <a className="text-white" href="mailto:support@tcgfish.com">support@tcgfish.com</a>
            within thirty (30) days of your earliest use or access of the Service.
          </p>
          <p>
            Subject to the above arbitration provisions, you and TCG Fish agree that
            all Disputes shall be settled in the federal or state courts located in Wilmington
            Delaware, and each of us agrees that such courts shall have exclusive jurisdiction
            and venue for any such actions. Notwithstanding anything to the contrary,
            TCG Fish also may seek injunctive or other equitable relief for breach of
            these Terms in any court of competent jurisdiction wherever located. You consent
            to the jurisdiction of and venue in such courts and waive any objection
            as to inconvenient forum. The prevailing party in any suit, action or proceeding,
            including any arbitration proceeding, will be entitled to recover its reasonable
            legal fees and costs and expenses from the other party.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          13. Class Action Waiver
        </h3>
        <div>
          <p>
            EXCEPT WHERE PROHIBITED UNDER APPLICABLE LAWS, ALL CLAIMS AND DISPUTES WITHIN
            THE SCOPE OF THE ARBITRATION AGREEMENT IN SECTION 12. ABOVE MUST BE ARBITRATED
            ON AN INDIVIDUAL BASIS AND NOT ON A COLLECTIVE CLASS BASIS. ONLY INDIVIDUAL RELIEF
            IS AVAILABLE.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          14. Notice
        </h3>
        <div>
          <p>
            Notices from one party to any other party must be in the English language
            and must be reasonably detailed. Notices shall be effective on the date
            on which the notice is delivered to the notified party’s authorized email address
            or as published by TCG Fish as described herein. Until you receive notice of
            a different email address from us, TCG Fish’s authorized email address for all
            purposes of these Terms shall be the following:
            <a className="text-white" href="mailto:support@tcgfish.com">support@tcgfish.com</a>.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          8. Miscellaneous
        </h3>
        <h4 className="section__subtitle">
          a. <span className="underline">No Waiver of Rights</span>
        </h4>
        <div>
          <p>
            The failure by us to enforce any right or provision of these Terms shall
            not prevent any party from enforcing such right or provision in the future.
            No waiver by a party of any of the provisions of these Terms is effective
            unless explicitly set forth in writing and signed by such party. No failure
            to exercise, or delay in exercising, any right, remedy, power, or privilege
            arising from these Terms operates, or may be construed, as a waiver thereof.
            No single or partial exercise of any right, remedy, power, or privilege hereunder
            precludes any other or further exercise thereof or the exercise of any other right,
            remedy, power, or privilege.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          b. <span className="underline">Severability</span>
        </h4>
        <div>
          <p>
            If any provision of these Terms is found to be unlawful or unenforceable,
            then that provision shall be deemed severable from these Terms and shall
            not affect the enforceability of any other provisions.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          c. <span className="underline">Assignment</span>
        </h4>
        <div>
          <p>
            We may assign our rights and obligations under these Terms, including in connection
            with a merger, acquisition, sale of assets or equity, or by operation of law.
            You shall not assign any of your rights or delegate any of your obligations under
            these Terms without our prior written consent. Any purported assignment or
            delegation in violation of this Section is null and void. No assignment or
            delegation relieves either party of any of its obligations under these Terms.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          d. <span className="underline">Entire Agreement</span>
        </h4>
        <div>
          <p>
            These Terms constitute the sole and entire agreement of the parties with
            respect to the subject matter contained herein, and supersedes all prior and
            contemporaneous understandings and agreements, both written and oral, with
            respect to such subject matter.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          e. <span className="underline">Interpretation</span>
        </h4>
        <div>
          <p>
            The headings of the sections and subsections contained in these Terms are
            included for reference purposes only, solely for the convenience of the parties,
            and shall not in any way be deemed to affect the meaning, interpretation
            or applicability of these Terms or provision hereof. When the context requires,
            the plural shall include the singular and the singular the plural;
            and any gender shall include any other gender. All references to “including” or
            “includes” or any variation thereof shall be deemed to include the terms
            “without limitation”. The words “hereunder,” “hereof,” “hereto” and words of
            similar import shall be deemed references to these Terms as a whole.
            To the extent not prohibited by applicable law, these Terms shall not be
            construed against the party who drafted these Terms.
          </p>
        </div>
      </section>
    </div>
  );
}
