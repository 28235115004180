import React from 'react';

export const PrimaryLargeButton = ({ text, onClick, Icon, className }) => {
  return(
    <div className={`inline-flex flex-shrink-0 h-12 ${Icon ? 'pl-5' : 'pl-6'} pr-6 py-3 justify-center items-center ${Icon ? 'gap-2' : 'gap-2.5'} p4 text-white box-border bg-purple-600 rounded-lg ease-out duration-100 hover:bg-purple-700 ${className ? className : ''}`}
         role="button"
         onClick={onClick}
    >
      {Icon &&
        <Icon className="w-5 h-5"/>
      }
      {text}
    </div>
  );
};
