import React from 'react';
import './Listing.css';

export default function Listing({data, order, show, setShow}) {
  const normalizeValue = (val) => {
    let result = val ? val : 'Not Available'
    if (result === 'BGS 11') {
      result = 'BGS Black Label';
    }
    return result;
  };

  function Header() {
    return (
      <>
        {order.map((item) =>
            <div className="oneHeading"
                 key={item.displayName}
                 style={{
                   minWidth: `${90 / order.length}%`,
                   maxWidth: `${90 / order.length}%`,
                   padding: `5px ${2 / order.length}%`
                 }}
            >
              {item.displayName}
            </div>
        )}
      </>
    );
  }

  function List({ data }) {
    return data.map((item) =>
        <div className="oneRow">
          {order.map((index) => {
            if (index.type) {
              if (index.type === 'currency') {
                return (
                  <div className="oneBox"
                       style={{
                         minWidth: `${90 / order.length}%`,
                         maxWidth: `${90 / order.length}%`,
                         padding: `5px ${2 / order.length}%`
                       }}
                  >
                    {!item[index.name] || item[index.name] === 'Not Available' ? 'Not Available' : `$${item[index.name]}`}
                  </div>
                );
              }
              if (index.type === 'percentage') {
                return (
                  <div className="oneBox"
                       style={{
                         minWidth: `${90 / order.length}%`,
                         maxWidth: `${90 / order.length}%`,
                         padding: `5px ${2 / order.length}%`
                       }}
                  >
                    {item[index.name] ? `${item[index.name].toFixed(2)}%` : 'Not Available'}
                  </div>
                );
              }
              return (
                <div className="oneBox"
                     style={{
                       minWidth: `${90 / order.length}%`,
                       maxWidth: `${90 / order.length}%`,
                       padding: `5px ${2 / order.length}%`
                     }}
                >
                  {item[index.name] ? item[index.name] : 'Not Available'}
                </div>
              );
            }

            return (
              <div className="oneBox"
                   style={{
                     minWidth: `${90 / order.length}%`,
                     maxWidth: `${90 / order.length}%`,
                     padding: `5px ${2 / order.length}%`
                   }}
              >
                {normalizeValue(item[index.name])}
              </div>
            );
          })}
      </div>
    );
  }

  if (show) {
    return (
      <div id="myModal" className="modal">
        <div className="modal-content">
          <div className="listing__cross">
            <i className="fa fa-times"
               onClick={() => {
                 setShow(false);
               }}
            ></i>
          </div>
          <div className="listing">
            <div className="listing__header">
              <Header />
            </div>
            <div className="listing__data">
              <List data={data} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;
}
