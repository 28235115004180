import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';
import { ModalsContext } from '../contexts/ModalsContext';
import DoughnutChart from './DoughnutChart/DoughnutChart';
import { RadarChart } from './RadarChart/RadarChart';
import TCGFishApi from '../lib/TCGFishApi';

const RANKS_PER_PAGE = 25;

export const CardAnalysis = ({ cardID, distribution, population }) => {
  const { user } = useContext(AuthContext);
  const { setModals } = useContext(ModalsContext);
  const [rankData, setRankData] = useState({});
  const [percentage, setPercentage] = useState(0);
  const [ratio, setRatio] = useState('0');

  const gradeDifficulty = useMemo(() => {
    if (percentage < 15) return 'Very Hard';
    else if (percentage >= 15 && percentage <= 30) return 'Hard';
    else if (percentage >= 30 && percentage <= 50) return 'Moderate';
    else if (percentage >= 50 && percentage <= 70) return 'Easy';
    else return 'Very Easy';
    }, [percentage]);

  const dollarUSLocale = Intl.NumberFormat('en-US');

  const calculateRatio = () => {
    let percentage = 0;
    let populationPSA9 = 0;
    let populationPSA10 = 0;
    let ratio = '0.0';
    if (population) {
      populationPSA10 = population.psa_10;
      populationPSA9 = population.psa_9;
      if (populationPSA9 > 0 && populationPSA10 > 0) {
        ratio = `${(populationPSA9 / populationPSA10).toFixed(2)} : 1`;
        percentage = (populationPSA10 / (populationPSA10 + populationPSA9)) * 100;
        percentage = percentage.toFixed(1);
      } else if (populationPSA10 === 0 && populationPSA9 > 0) {
        ratio = 'NO PSA 10';
        percentage = 0.0;
      } else if (populationPSA10 > 0 && populationPSA9 === 0) {
        ratio = `${populationPSA9} : ${populationPSA10}`;
        percentage = 100;
      }
      else {
        ratio = 'NO PSA 10';
        percentage = 0.0;
      }
    }
    setPercentage(percentage);
    setRatio(ratio);
  };

  const handleRankingLinkClick = (evt) => {
    if (user) {
      return;
    }

    evt.preventDefault();
    setModals((prevModals) => {
      return {
        ...prevModals,
        showLogInModal: true
      };
    });
  };

  useEffect(() => {
    calculateRatio();
  }, [population]);

  useEffect(() => {
    TCGFishApi
      .get('ranks/cardRank', { params: { card_id: cardID } })
      .then((res) => {
          setRankData(res.data.data);
      })
      .catch((err) => {
        console.error('Failed to get ranks/cardRank', err);
      });
  }, [cardID]);

  return (
    <div className="flex flex-col items-start gap-6 text-child-of-light-1000">
      <div className="flex items-start gap-6">
        <div className="w-190 h-54.5 py-5 pl-6 pr-7.75 rounded-lg border-min border-not-tonight-200 bg-not-tonight-100">
          <h3 className="p1">
            PSA Population Info
          </h3>
          <div className="inline-flex items-start mt-6 gap-20.25">
            <div className="flex flex-col items-start gap-2">
              <p className="text-not-tonight-700 p7">
                PSA Total Population
              </p>
              <p className="p2">
                {population?.psa_total || '0'}
              </p>
            </div>
            <div className="flex flex-col items-start gap-2">
              <p className="text-not-tonight-700 p7">
                PSA 10 Chance
              </p>
              <p className="text-welsh-onion-700 p2">
                {percentage}%
              </p>
            </div>
            <div className="flex flex-col items-start gap-2">
              <p className="text-not-tonight-700 p7">
                PSA 9/10 Ratio
              </p>
              <p className="text-welsh-onion-700 p2">
                {ratio}
              </p>
            </div>
            <div className="flex flex-col items-start gap-2">
              <p className="text-not-tonight-700 p7">
                PSA Grade Difficulty
              </p>
              <p className="text-dusk-orange-700 p2">
                {gradeDifficulty}
              </p>
            </div>
          </div>
          <hr className="w-full mt-5 mb-6 border-min border-not-tonight-300" />
          <div className="inline-flex items-start gap-8.5">
            {[...Array(10)].map((_v, i) => (
              <div className="flex flex-col items-start gap-2"
                   key={i}
              >
                <p className="text-not-tonight-700 p7">
                  PSA {10 - i}
                </p>
                <p className="text-child-of-light-1000 p6">
                  {population?.[`psa_${10 - i}`] || '0'}
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-col w-50 h-54.5 pt-5 pr-9 pb-9.1 pl-6 rounded-lg border-min border-not-tonight-200 bg-not-tonight-100">
          <h3 className="p1 pb-6">
            PSA Market Cap
          </h3>
          <div className="pb-6">
            <p className="text-not-tonight-700 p7 pb-2">
              Market Cap Ranking
            </p>
            {Object.keys(rankData).length && rankData.rankSort !== 'Unranked' ?
              // TODO: do not send page here, calculate on receiving end instead
              <Link to={`/ranking?rank=${rankData.rankSort}&pag=${Math.floor(rankData.rankSort/RANKS_PER_PAGE) || 1}`}
                    onClick={handleRankingLinkClick}
              >
                <p className="p2">
                  {`#${rankData.rankSort} / ${rankData.rankTotal}`}
                </p>
              </Link>
              :
              <p className="p2">
                Not Available
              </p>
            }
          </div>
          <div>
            <p className="text-not-tonight-700 p7 pb-2">
              Total PSA Market Cap
            </p>
            <p className="p2">
              {
                (Object.keys(rankData).length && rankData?.mktCap !== 0)
                    ?
                    `$${dollarUSLocale.format(rankData.mktCap)}`
                    :
                    'Not Available'
              }
            </p>
          </div>
        </div>
      </div>
      <div className="flex items-start gap-6">
        <div className="w-134 h-75.5 pt-5 pr-8 pb-5 pl-6 rounded-lg border-min border-not-tonight-200 bg-not-tonight-100">
          <div className="pb-6">
            <h3 className="p1 pb-2">
              Sales Volume Distribution by Grading Company
            </h3>
            <p className="p3">
              (2019-{new Date().getFullYear()})
            </p>
          </div>
          <div className="flex">
            <div className="w-47.5">
              <DoughnutChart
                  psa={distribution.psa.percent}
                  bgs={distribution.bgs.percent}
                  cgc={distribution.cgc.percent}
              />
            </div>
            <div className="grid grid-cols-12 w-72 pl-11 pt-13">
              <div className="pt-6 col-span-5">
                <div className="pb-3 flex items-center">
                  <div className="w-3 h-3 rounded-full bg-purple-700"></div>
                  <div>
                    <span className="p6 pl-2">
                      PSA Cards
                    </span>
                  </div>
                </div>
                <div className="pb-3 flex items-center">
                  <div className="w-3 h-3 rounded-full bg-blue"></div>
                  <div>
                    <span className="p6 pl-2">
                      BGS Cards
                    </span>
                  </div>
                </div>
                <div className="pb-3 flex items-center">
                  <div className="w-3 h-3 rounded-full bg-light-green"></div>
                  <div>
                    <span className="p6 pl-2">
                      CGS Cards
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-right col-span-4">
                <p className="p8 text-not-tonight-600 pb-3">
                    Percentage
                </p>
                <div className="flex flex-col">
                  <span className="p6 pb-5">
                    {`${distribution.psa.percent.toFixed(2)}%`}
                  </span>
                  <span className="p6 pb-5">
                    {`${distribution.bgs.percent ? distribution.bgs.percent.toFixed(2) : 0}%`}
                  </span>
                  <span className="p6 pb-5">
                    {`${distribution.cgc.percent.toFixed(2)}%`}
                  </span>
                </div>
              </div>
              <div className="text-right col-span-3">
                <p className="p8 text-not-tonight-600 pb-3">
                  Total
                </p>
                <div className="flex flex-col">
                  <span className="p6 pb-5">
                    {distribution.psa.value}
                  </span>
                  <span className="p6 pb-5">
                    {distribution.bgs.value}
                  </span>
                  <span className="p6 pb-5">
                    {distribution.cgc.value}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-106 h-75.5 pt-5 pr-7 pb-5 pl-6 rounded-lg border-min border-not-tonight-200 bg-not-tonight-100">
          <RadarChart id={cardID} />
        </div>
      </div>
    </div>
  );
};
