import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './Ranking.css';
import empty from '../../assets/images/empty.png';
import Pagination from '../../components/Pagination/Pagination';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import MetadataTags from '../../components/MetadataTags/MetadataTags';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import TCGFishApi from '../../lib/TCGFishApi';
import {
    DEFAULT_PAGE_TITLE,
    DEFAULT_PAGE_DESCRIPTION,
    DEFAULT_PAGE_KEYWORDS
} from '../../lib/constants';

export default function Ranking() {
    const navigate = useNavigate();
    const location = useLocation();
    const search = location.search;

    const parameterRank = new URLSearchParams(search).get('rank');
    const parameterQuery = new URLSearchParams(search).get('q') || '';
    const parameterPag = new URLSearchParams(search).get('pag');

    const [error, setError] = useState(false);
    const [searchField, setSearchField] = useState(parameterQuery);
    const [tempSearchField, setTempSearchField] = useState(parameterQuery);
    const [CardIdLoader, setCardIdLoader] = useState(false);
    const [finalList, setFinalList] = useState(null);
    const [currentPage, setCurrentPage] = useState(parseInt(parameterPag) || 1);
    const [totalCards, setTotalCards] = useState(null);
    const [first, setFirst] = useState(true);
    const [totalMarketCap, setTotalMarketCap] = useState(null);
    const [lastUpdated, setLastUpdated] = useState();
    const [jumpToRank, setJumpToRank] = useState(isNaN(parseInt(parameterRank)) ? '1' : parameterRank);
    const dollarUSLocale = Intl.NumberFormat('en-US');

    const cardsPerPage = 25;

    const pageTitle = `PSA Market Cap Ranking - ${DEFAULT_PAGE_TITLE}`;
    const pageDescription = `PSA Market Cap Ranking - ${DEFAULT_PAGE_DESCRIPTION}`;
    const pageKeywords = `PSA Market Cap Ranking, ${DEFAULT_PAGE_KEYWORDS}`;

    useEffect(() => {
        sessionStorage.setItem("searchLink", (window.location.pathname + window.location.search));
    }, [parameterQuery, parameterPag]);

    useEffect(() => {
        setSearchField(parameterQuery ? parameterQuery : '');
    }, [parameterQuery]);

    useEffect(() => {
        setCurrentPage(isNaN(parseInt(parameterPag)) ? 1 : parseInt(parameterPag));
    }, [parameterPag]);

    useEffect(() => {
        setJumpToRank(isNaN(parseInt(parameterRank)) ? '1' : parameterRank);
    }, [parameterRank]);

    useEffect(() => {
        if (window.document.getElementById(jumpToRank) !== null) {
            const timeJumpToRank = setTimeout(() => {
                window
                  .document
                  .getElementById(jumpToRank)
                  .scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
                }, 800);
            return () => clearTimeout(timeJumpToRank);
        }
    }, [finalList, jumpToRank]);

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (tempSearchField !== null && !first) {
                navigate(`/ranking?q=${tempSearchField}&pag=1`);
            }
        }, 700);
        return () => clearTimeout(timeOutId);
    }, [tempSearchField]);

    useEffect(() => {
        TCGFishApi
            .get('ranks/getTotalMarketCap')
            .then((res) => {
                setTotalMarketCap(res.data?.data);
            })
            .catch((err) => {
                console.error('Failed to get ranks/getTotalMarketCap', err);
            });
    }, []);

    useEffect(() => {
        TCGFishApi
            .get('ranks/getLastUpdated')
            .then((res) => {
                setLastUpdated(res.data?.data?.lastUpdated);
            })
            .catch((err) => {
                console.error('Failed to get ranks/getLastUpdated', err);
            });
    }, []);

    useEffect(() => {
        setError(false);
        setFinalList(null);

        TCGFishApi
            .get(
              'ranks/all',
              {
                  params: {
                      limit: cardsPerPage,
                      skip: currentPage - 1,
                      query: searchField || ''
                  }
              }
            )
            .then((res) => {
                setTotalCards(res.data.count);
                setFinalList(res.data.data);
                setFirst(false);
                setError(false);
            })
            .catch((err) => {
                console.error('Failed to get ranks/all', err);
                setError(true)
            });
    }, [searchField, currentPage]);

    function CardIDAPI(object) {
        setCardIdLoader(true);
        TCGFishApi
          .get(
            'cards/findFirst',
            {
                params: {
                    name: object.name,
                    setName: object.set_name,
                    releaseYear: object.releaseYear,
                    language: object.language,
                    cardNumber: object.card_number,
                }
            }
          )
          .then((res) => {
              setCardIdLoader(false);

              const result = res.data.data;

              if (!result) {
                  return alert('Card version not found.');
              }

              navigate(`/product?id=${result._id}&cardNumber=${object.card_id}`);
          }
        )
        .catch((err) => {
            console.error('Failed to get cards/findFirst', err);
            setCardIdLoader(false);
            setError(true);
        });
    }

    const handlePrevNav = () => {
        if (currentPage > 1) {
            navigate(`/ranking?q=${searchField}&pag=${currentPage - 1}`);
        }
    }
    const handleNextNav = () => {
        if (currentPage < Math.ceil(totalCards / cardsPerPage)) {
            navigate(`/ranking?q=${searchField}&pag=${currentPage + 1}`);
        }
    }

    function listItems() {
        if (finalList.length > 0) {
            return finalList.map((item) =>
                <div onClick={() => { sessionStorage.setItem('bread', 'ranking'); CardIDAPI(item); }}
                     key={item._id}
                >
                    <div id={item.rankSort} className="rank__card__row">
                        <div className="rank__card">
                            <div key={item.id}>
                                <div className="rank__card__data">
                                    <div className="rank__header__image">
                                            <img
                                                className="rank__card_img"
                                                src={item.image}
                                                onError={(e) => (e.target.src = empty)}
                                                alt="IMG"
                                            />
                                        </div>
                                    <div className="rank__header__rank">
                                            <div className="rank__card__ranking">
                                                {item.rankSort !== 'Unranked' ? `#${item.rankSort}` : 'Unranked'}
                                            </div>
                                        </div>
                                    <div className="rank__header__name">
                                            <p className="rank__card__name">
                                                {item.name}
                                            </p>
                                            <p className="rank__card__info">
                                                {((item.card_number) && (item.card_number !== "Missing")) && (
                                                    <>
                                                        <span>{item.releaseYear}</span>
                                                        <span>{item.set_name}</span>
                                                        <span>#{item.card_number}</span>
                                                        <span>({item.language})</span>
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    <div className="rank__header__version">
                                            <div className="rank__card__version">
                                                {item.sub_category}
                                            </div>
                                        </div>
                                    <div className="rank__header__pop">
                                        <div className="rank__card__pop">
                                            {item.psaPop}
                                        </div>
                                    </div>
                                    <div className="rank__header__mkt_cap">
                                        <div className="rank__card__mkt_cap">
                                            ${dollarUSLocale.format(item.mktCap)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else
            return (
                <span style={{ color: "white", fontSize: "20px", margin: "20px" }}>
                    No cards found
                </span>
            );
    }

    const paginate = (pageNumber) => {
        navigate(`/ranking?q=${searchField}&pag=${pageNumber}`);
        window.scrollTo(0, 0);
    };

    function Formatter(num) {
        if (Math.abs(num) > 999999999) {
            return Math.sign(num) * ((Math.abs(num) / 1000000000).toFixed(1)) + 'B';
        } else if (Math.abs(num) > 999999) {
            return Math.sign(num) * ((Math.abs(num) / 1000000).toFixed(1)) + 'M';
        } else if (Math.abs(num) > 999) {
            return Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'K';
        } else
            return Math.sign(num) * Math.abs(num);
    }

    return (
        <div className="ranking">
            <MetadataTags title={ pageTitle }
                          description={ pageDescription }
                          keywords={ pageKeywords }
            />

           <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={CardIdLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* <Maintenance /> */}
            <div className="rank__page">
                <div className="rank__breadcrumb">
                    <BreadCrumb />
                </div>
                <div className="rank__search-main">
                    <div className="rank__page__heading">PSA Market Cap Ranking</div>
                    <div className="rank__search__container">
                        <input
                            id="rank_search_input"
                            type="search"
                            className="rank__search__bar"
                            placeholder="Filter by card name, number, set name, release year, language..."
                            value={tempSearchField}
                            onChange={(e) => {
                                setTempSearchField(e.target.value);
                            }}
                        />
                        <div className="rank__update__info">
                            <span>
                                <div className="rank__last__sold">
                                    Latest Update:
                                    {lastUpdated ?
                                       new Date(lastUpdated).toLocaleDateString("en-us", {
                                         year: "numeric",
                                         month: "short",
                                         day: "numeric",
                                       })
                                    :
                                      ' ---'
                                    }
                                </div>
                                Total Market Cap: {totalMarketCap ? Formatter(totalMarketCap) : ' ---'}
                            </span>

                        </div>

                    </div>
                    <div className="page__navigation">
                        {currentPage > 1 ? <div className="nav__button" onClick={() => handlePrevNav()}>{`< Prev`}</div> : <div className="nav__button__disabled">{`< Prev`}</div>}
                        {currentPage < Math.ceil(totalCards / cardsPerPage) ? <div className="nav__button" onClick={() => handleNextNav()}>{`Next >`}</div> : <div className="nav__button__disabled">{`Next >`}</div>}
                    </div>
                </div>
                <div className="rank__Main">
                    {(error) ?
                        <>
                            <div className="rank__preloader__whole">
                                <div className="rank__error__message">
                                    Failed to load ranking data<br /><br />
                                    Please refresh the page and try again
                                </div>
                            </div>
                        </>
                        : <>
                            <div className="rank__container" style={(!finalList) ? { border: "0px" } : {}}>
                                {finalList
                                    ? <>
                                        <div className="rank__header__row">
                                            <div className="rank__header__image">Card</div>
                                            <div className="rank__header__rank">Ranking</div>
                                            <div className="rank__header__name">Card Name</div>
                                            <div className="rank__header__version">Card Version</div>
                                            <div className="rank__header__pop">Total PSA Population</div>
                                            <div className="rank__header__mkt_cap">Market Cap (USD)</div>
                                        </div>
                                        <div className="rank__listing">
                                            {listItems()}
                                        </div>
                                    </>
                                    : <>
                                        <div className="rank__preloader__whole">
                                            <div className="rank__preloader__container">
                                                <div className="rank__preloader">
                                                    <div className="rank__preloader">
                                                        <div className="rank__preloader">
                                                            <div className="rank__preloader">
                                                                <div className="rank__preloader">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div>
                                {totalCards && (
                                    <div className="rank__page__container">
                                        <Pagination
                                            cardsPerPage={cardsPerPage}
                                            totalcards={totalCards}
                                            paginate={paginate}
                                            currentPage={currentPage}
                                        />
                                    </div>
                                )}
                            </div>
                        </>
                    }
                </div>
            </div>
        </div >
    );
}
