const DEFAULT_PAGE_TITLE = 'TCG Fish';

const DEFAULT_PAGE_DESCRIPTION = 'TCG Fish website with public information about collectible Pokémon cards';

const DEFAULT_PAGE_KEYWORDS = 'TCG Fish, Collectible cards, Pokémon cards, Public information';

// const COOKIES_CONSENT_COOKIE_NAME = 'CookieConsent';
// const COOKIES_CONSENT_ACCEPT_ALL = 'accept_all';
// const COOKIES_CONSENT_ACCEPT_REQUIRED = 'accept_required';

const EMAIL_NOT_VERIFIED_MESSAGE = 'Email not verified. Please check your email for verification link';
const VERIFICATION_EMAIL_COULD_NOT_BE_SENT_MESSAGE = 'But verification email could not be sent';
const INVALID_EMAIL_VERIFICATION_CODE_ERROR_MESSAGE = 'Invalid Verification Code.';

const MIN_PASSWORD_LENGTH = 6;
const PASSWORDS_DO_NOT_MATCH_MESSAGE = 'Passwords don’t match';
const TOS_AND_POLICY_NOT_ACCEPTED_MESSAGE = 'Accept Terms of Use and Privacy Policy.';
const ALL_FIELDS_ARE_REQUIRED_MESSAGE = 'All fields are required.';

const UNEXPECTED_ERROR_MESSAGE = 'Unexpected error occurred. Please try again.';

const IOS_APP_URL = 'https://apps.apple.com/us/app/tcgfish/id1663269891?itsct=apps_box_badge&amp;itscg=30200';
const ANDROID_APP_URL = 'https://play.google.com/store/apps/details?id=com.tcgfish&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';

export {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS,
  // COOKIES_CONSENT_COOKIE_NAME,
  // COOKIES_CONSENT_ACCEPT_ALL,
  // COOKIES_CONSENT_ACCEPT_REQUIRED,
  MIN_PASSWORD_LENGTH,
  PASSWORDS_DO_NOT_MATCH_MESSAGE,
  TOS_AND_POLICY_NOT_ACCEPTED_MESSAGE,
  ALL_FIELDS_ARE_REQUIRED_MESSAGE,
  EMAIL_NOT_VERIFIED_MESSAGE,
  VERIFICATION_EMAIL_COULD_NOT_BE_SENT_MESSAGE,
  INVALID_EMAIL_VERIFICATION_CODE_ERROR_MESSAGE,
  IOS_APP_URL,
  ANDROID_APP_URL,
  UNEXPECTED_ERROR_MESSAGE
};
