import React, { useEffect, useMemo, useState } from 'react';

import './MarketCheckBox.css';
import MetadataTags from '../../components/MetadataTags/MetadataTags';
import {
  DEFAULT_PAGE_TITLE,
  DEFAULT_PAGE_DESCRIPTION,
  DEFAULT_PAGE_KEYWORDS
} from '../../lib/constants';

export default function MarketCheckBox({ windowWidth }) {
  const categories = [
    {
      super: 'Market Recap',
      sub: ['Average Sales Price', 'Sales Value Breakdown', 'Sales Volume Breakdown'],
    },
    {
      super: 'Market Movers',
      sub: ['Popular Cards', 'Popular Sets', 'Popular Characters'],
    },
    {
      super: 'Record Sales',
      sub: ['Record High', 'Record Low', 'Premium Sales'],
    },
    {
      super: 'Grading Companies',
      sub: ['Sales Volume by Grading Companies'],
    },
  ];
  const [superChecked, setSuperChecked] = useState(0);
  const [checked, setChecked] = useState(0);

  const pageTitle = useMemo(() => {
    return  `Market Research Tool - ${categories[superChecked].super} - ${DEFAULT_PAGE_TITLE}`;
  }, [superChecked]);

  const pageDescription = useMemo(() => {
    return  `Market Research Tool - ${categories[superChecked].super} - ${DEFAULT_PAGE_DESCRIPTION}`;
  }, [superChecked]);

  const pageKeywords = useMemo(() => {
    return  `Market Research Tool, ${categories[superChecked].super}, ${DEFAULT_PAGE_KEYWORDS}`;
  }, [superChecked]);

  const select = (sup, sub) => {
    setChecked(sub);
    setSuperChecked(sup);
  };

  const delayedExec = (delay, fn) => {
    let timeout;
    return function () {
      timeout && clearTimeout(timeout);
      timeout = setTimeout(fn, delay);
    };
  };

  const onScrollProgress = () => {
    if (document.documentElement.scrollTop === 0) {
      select(0, 0);
    } else {
      for (let i = 0; i < categories.length; i++) {
        for (let j = 0; j < categories[i].sub.length; j++) {
          const elID = `${i}${j}`;
          if (document.getElementById(elID)) {
            if (
              document.getElementById(elID).getBoundingClientRect().y < 300 &&
              document.getElementById(elID).getBoundingClientRect().y > -100
            ) {
              select(i, j);
            }
          }
        }
      }
    }
  };

  const scrollStopper = delayedExec(40, () => { onScrollProgress(); });

  useEffect(() => {
    window.addEventListener('scroll', scrollStopper);
    window.scrollTo(0, 0);

    return () => { window.removeEventListener('scroll', scrollStopper); }
  }, [window.location.pathname]);

  useEffect(() => {
    const el = document.getElementById(`${superChecked}${checked}`);
    if (!el) {
      return;
    }

    if (windowWidth > 960) {
      el.scrollIntoView({ block: 'center' });
      return;
    }

    el.scrollIntoView({ block: 'start' });
    setTimeout(() => window.scrollBy(0, -125), 500);
  }, [checked, superChecked]);

  return (
    <>
      <MetadataTags title={ pageTitle }
                    description={ pageDescription }
                    keywords={ pageKeywords }
      />

      <div className="MarketCheckBox__title">
        Market Research Tool
      </div>
      <div className="MarketCheckBox__OneBigBox">
        {categories.map((value, supIndex) =>
          <div className="MarketCheckBox__OneCat"
               key={supIndex}
          >
            <div className={`MarketCheckBox__OneBox ${supIndex === superChecked && 'MarketCheckBox__OneBox__selected'}`}
                 onClick={() => {select(supIndex, 0); }}
            >
              <div className="MarketCheckBox__Box">
                <h2 className="MarketCheckBox__heads">
                  {value.super}
                </h2>
              </div>
            </div>
            <div>
              {value.sub.map((subValue, subIndex) =>
                <div className="OneCatBox"
                     key={subIndex}
                >
                  <div className={`MarketCheckBox__OneBox MarketCheckBox__OneBox__sub ${supIndex === superChecked && subIndex === checked && 'MarketCheckBox__OneBox__sub_selected'}`}
                       onClick={() => { select(supIndex, subIndex) }}
                  >
                    <div className="MarketCheckBox__Box">
                      <p>
                        {subValue}
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
}
