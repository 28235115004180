import React, { useEffect, useState } from 'react';
import './PopularSets.css';

import Listing from '../../../Listing/Listing';
import TCGFishApi from '../../../../lib/TCGFishApi';

export default function PopularSets() {
  const [engShow, setEngShow] = useState(false);
  const [japShow, setJapShow] = useState(false);
  const [popSetEng, setPopSetEng] = useState([]);
  const [popSetJap, setPopSetJap] = useState([]);

  useEffect(() => {
    TCGFishApi
      .get('market/getmsj4')
      .then((res) => {
        setPopSetJap(res.data.count);
      })
      .catch((err) => {
        console.error('Failed to get market/getmsj4', err);
      });

    TCGFishApi
      .get('market/getmse4')
      .then((res) => {
        setPopSetEng(res.data.count);
      })
      .catch((err) => {
        console.error('Failed to get market/getmse4', err);
      });
  }, []);

  return (
    <div className="popular__sets">
      <Listing show={engShow} setShow={(b) => { setEngShow(b) }}
               data={popSetEng}
               order={[
                 { name: 'set_name', displayName: 'Set Name' },
                 { name: 'sales_vol', displayName: '90 Days Sales Volume' },
                 { name: 'percentage', displayName: 'Percentage%', type: 'percentage' }
               ]}
      />
      <Listing show={japShow} setShow={(b) => { setJapShow(b) }}
               data={popSetJap}
               order={[
                 { name: 'set_name', displayName: 'Set Name' },
                 { name: 'sales_vol', displayName: '90 Days Sales Volume' },
                 { name: 'percentage', displayName: 'Percentage%', type: 'percentage' }
               ]}
      />
      <div className="ps__box">
        <div className="ps__heading__area">
          <div className="ps__heading">Popular Sets</div>
          <span className="ps__description">
            <p>Find out the most liquidate/popular sets up to date.</p>
            <p>(Seperated into Japanese and English two categories.)</p>
          </span>
        </div>
        <div className="ps__table__box">
          <div className="ps__jap__set">
            <div className="ps__jap__set__name">
              <div className="ps__jap__set__box">Japanese Set</div>
            </div>
            <div className="ps__jap__set__header">
              <p className="ps__jap__sn">Set Name</p>
              <p className="ps__jap__dsv">90 Days Sales Volume</p>
              <p className="ps__jap__p">Percentage %</p>
            </div>
            <div className="ps__data">
              {popSetJap.slice(0, 5).map((item) =>
                <div className="ps__data__row"
                     key={item._id}
                >
                  <p className="ps__data__sn">{item.set_name}</p>
                  <p className="ps__data__dsv">{item.sales_vol}</p>
                  <p className="ps__data__p">{item.percentage?.toFixed(2)}%</p>
                </div>
              )}
            </div>
            <div className="ps__fl">
              <div className="ps__fl__link"
                   onClick={() => { setJapShow(true) }}>
                Full List >>
              </div>
            </div>
          </div>
          <div className="ps__eng__set">
            <div className="ps__eng__set__name">
              <div className="ps__eng__set__box">English Set</div>
            </div>
            <div className="ps__eng__set__header">
              <p className="ps__eng__sn">Set Name</p>
              <p className="ps__eng__dsv">90 Days Sales Volume</p>
              <p className="ps__eng__p">Percentage %</p>
            </div>
            <div className="ps__data">
              {popSetEng.slice(0, 5).map((item) =>
                <div className="ps__data__row"
                     key={item._id}
                >
                  <p className="ps__data__sn">{item.set_name}</p>
                  <p className="ps__data__dsv">{item.sales_vol}</p>
                  <p className="ps__data__p">{item.percentage?.toFixed(2)}%</p>
                </div>
              )}
            </div>
            <div className="ps__fl">
              <div className="ps__fl__link"
                   onClick={() => { setEngShow(true) }}
              >
                Full List >>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
