import React from 'react';

export const Pagination = ({ currentPage, totalPages, onChange }) => {
  const MAX_PAGES_TO_SHOW = 5;
  let startPage = Math.max(2, currentPage - 2);
  let endPage = Math.min(totalPages, currentPage + 2);

  if (currentPage <= 3) {
    endPage = Math.min(totalPages, MAX_PAGES_TO_SHOW);
  }
  if (currentPage > totalPages - 3) {
    startPage = Math.max(2, totalPages - 4);
  }

  const pages = Array.from({ length: (endPage - startPage) + 1 }, (_, idx) => startPage + idx);

  return (
    <div className="flex justify-center items-center space-x-2 text-white">
      <button className={`p-2 ${currentPage === 1 ? 'cursor-not-allowed opacity-50' : ''}`}
              onClick={() => currentPage > 1 && onChange(currentPage - 1)}
              disabled={currentPage === 1}
      >
        Previous
      </button>
      <button className={`px-4 py-2 rounded-md ${currentPage === 1 ? 'text-white bg-purple-300 border-min border-purple-400' : 'hover:bg-purple-600 hover:text-white'}`}
              onClick={() => onChange(1)}
      >
        1
      </button>
      {startPage > 2 && <span className="text-not-tonight-800">...</span>}
      {pages.map(page =>
        <button className={`px-4 py-2 rounded-md ${page === currentPage ? 'text-white bg-purple-300 border-min border-purple-400' : 'hover:bg-purple-600 hover:text-white'}`}
                key={page}
                onClick={() => onChange(page)}
        >
          {page}
        </button>
      )}
      {endPage < totalPages - 1 && <span className="text-not-tonight-800">...</span>}
      {endPage < totalPages &&
        <button className="px-4 py-2 rounded-md hover:bg-purple-600 hover:text-white"
                onClick={() => onChange(totalPages)}
        >
          {totalPages}
        </button>
      }
      <button className={`p-2 ${currentPage === totalPages ? 'cursor-not-allowed opacity-50' : ''}`}
              onClick={() => currentPage < totalPages && onChange(currentPage + 1)}
              disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};
