export const getInternalLink = (url) => {
  try {
    const parsedUrl = new URL(url);

    return `${parsedUrl.pathname}${parsedUrl.search}${parsedUrl.hash}`;
  } catch (e) {
    console.error('getInternalLink', e);
    return url;
  }
};
