import React from 'react';
import './PrivacyPolicy.css';

export default function PrivacyPolicy() {
  return (
    <div className="privacy_policy">
      <h2 className="page__title">TCG FISH PRIVACY POLICY</h2>
      <section>
        <div className="last_updated">Last updated: 20 September, 2023</div>
        <div>
          <p>
            This TCG Fish Privacy Policy (<span className="bold">“Privacy Policy”</span>) is effective from and after the date shown above, and describes how Collectible Data Insights, LLC (<span className="bold">“TCG Fish,” “we,” “our,”</span> or <span className="bold">“us,”</span>) collects, uses, shares and otherwise processes information collected about you from website or other location offered by us that directly links to this Privacy Policy (herein referred to as the <span className="bold">“Service”</span>).
          </p>
          <p>
            If you have any questions or wish to exercise your rights and choices,
            please contact us as set out in the “Contact Us” section.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          1. BACKGROUND
        </h3>
        <div>
          <p>
            The TCG Service is a resource that helps user identify (i) collectables for sale on the open market
            (<span className="bold">“Collectables”</span>), including through third-party services provided by
            third parties that TCG Fish has a business relationship with
            (<span className="bold">“Business Partners”</span>), and (ii) relevant information use full to assess
            the value of certain Collectables offered for sale by a third party.
            The TCG Service may include links to third-party sources where the respective Collectable is available
            to purchase, but TCG Fish is not a party to any such sale. For more information about Business Partners
            and other Third Party Services, please see
            <a className="text-white" href="#section-business-partners">“Your Rights and Choices: Business and Third-Party Services”</a> below.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          2. THE INFORMATION WE COLLECT ABOUT YOU
        </h3>
        <div>
          <p>
            TCG Fish collects personally identifiable information from you when you use the Service.
            This information is collected and stored electronically by us. This information may be provided
            to us voluntarily by you or collected automatically by us from you.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          A. <span className="underline">Information You Voluntarily Provide</span>
        </h4>
        <div>
          <p>
            We collect information about you when you use the Service, including information you provide
            when you register an account, update your profile, access our content, make a purchase,
            participate in a promotion or incentive program, or contact customer support. The categories
            of information that you may voluntarily provide include:
          </p>
          <ul>
            <li>
              <span className="bold">Contact Information</span>, including name or email address when
              you reach out to us for support or other communications;
            </li>
            <li>
              <span className="bold">Account Credentials</span>, including account password;
            </li>
            <li>
              <span className="bold">Demographic Data</span>, including your age, gender, and country;
            </li>
            <li>
              <span className="bold">Profile Information</span>, including information and achievements
              you display as part of your account profile; and
            </li>
            <li>
              <span className="bold">Content</span>, including any content in communications with us that you provide.
            </li>
          </ul>
          <p>
            You may choose to voluntarily provide other information to us that we do not request;
            in such situations we have no control over what categories of personal information such
            disclosure may include. Any additional information provided by you to us is provided at your own risk.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          B. <span className="underline">Information Collected Automatically</span>
        </h4>
        <div>
          <p>
            When you use the Service, we will automatically process limited information. The information we
            may have collected automatically include the following:
          </p>
          <ul>
            <li>
              <span className="bold">Service Data</span>, including data about features you use, pages you visit,
              emails and advertisements you view, products and services you view and purchase,
              the time of day you browse, and your referring and exiting pages;
            </li>
            <li>
              <span className="bold">Device Data</span>, including data about the type of device or browser you use,
              your device’s operating software, your internet service provider, your device’s regional and
              language settings, and device identifiers such as IP address;
            </li>
            <li>
              <span className="bold">Log Files</span>, which are files that record events that occur in connection
              with your use of the Service;
            </li>
            <li>
              <span className="bold">Location Data</span>, including imprecise location data (such as location
              derived from an IP address or data that indicates a city or postal code level); and
            </li>
            <li>
              <span className="bold">Cookies</span>, which are small data files stored on your device that act
              as a unique tag to identify your browser. Please see the section entitled “Cookies” below
              for more information about the cookies we use and why we use them.
            </li>
            <li>
              <span className="bold">Pixels (also known as web beacons)</span>, which are types of code embedded
              in a website, video, email, or advertisement that sends information about your use to a server.
              There are various types of pixels, including image pixels (which are small graphic images)
              and JavaScript pixels (which contains JavaScript code). When you access a website, video, email,
              or advertisement that contains a pixel, the pixel may permit us or a separate entity to drop
              or read cookies on your browser. Pixels are used in combination with cookies to track activity
              by a particular browser on a particular device. We may incorporate pixels from separate entities
              that allow us to track our communications, bring you advertising both on and off the Service,
              and provide you with additional functionality, such as the ability to connect our Service with
              your social media account.
            </li>
          </ul>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          3. HOW WE USE THE INFORMATION WE COLLECT ABOUT YOU
        </h3>
        <div>
          <p>
            We use the information we collect from and about you for the following purposes:
          </p>
          <ol>
            <li>
              To provide, maintain, improve, and enhance our Service;
            </li>
            <li>
              To understand and analyze how our Service is used and trends related to use of the
              Service and Collectibles, and develop new products, services, features, and functionality;
            </li>
            <li>
              To conduct research, including focus groups and surveys;
            </li>
            <li>
              To communicate with you, provide you with updates and other information relating to
              our Service, provide information and/or perform services that you request, respond to
              comments and questions, and otherwise provide customer support;
            </li>
            <li>
              To find and prevent fraud, and respond to trust and safety issues that may arise;
            </li>
            <li>
              Developing and sending you direct marketing, including advertisements and communications
              about our and other entities’ products, offers, promotions, rewards, events, and services.
            </li>
            <li>
              For compliance purposes, including enforcing our legal rights, or as may be required
              by applicable laws and regulations or requested by any judicial process or governmental agency; and
            </li>
            <li>
              To fulfill any other business or commercial purposes at your direction or with your notice
              and/or consent.
            </li>
          </ol>
          <p>
            Notwithstanding the above, we may use information that does not identify you (including information
            relates specifically to collectables and purchases without associating your personal information)
            for any purpose except as prohibited by applicable law.
          </p>
          <p>
            For information on your rights and choices regarding how we use personal data about you,
            please see the section entitled
            <a className="text-white" href="#section-your-rights-and-choices">“Your Rights and Choices”</a> below.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          4. SHARING OF INFORMATION
        </h3>
        <div>
          <p>
            We share information we collect in accordance with the practices described in this Privacy Policy.
            The types of entities we may have shared information with, or may share information with in
            the future, include the following:
          </p>
          <ul>
            <li>
              <span className="bold">Third Parties Providing Services On Our Behalf.</span> In order to make
              various features, services and materials available to you through the Service, host the Service,
              process payments, and respond to your requests and inquiries, we may share your information
              you provide with third parties that perform functions on our behalf.
            </li>
            <li>
              <span className="bold">Business Partners.</span> We share information with Business Partners that
              provide the third-party service with the point of sale for Collectibles which may be displayed
              by our Service.
            </li>
            <li>
              <span className="bold">Business Transfers.</span> We share information in connection with,
              or during negotiations of, any proposed or actual merger, purchase, sale or any other type
              of acquisition, business combination of all or any portion of our business or assets,
              change of control, or a transfer of all or a portion of our business or assets to another
              third party (including in the case of any bankruptcy proceeding).
            </li>
            <li>
              <span className="bold">Legal Disclosure.</span> Under certain circumstances, we may be required
              to cooperate with legal investigations and/or we may be subject to legal requirements to disclose
              information collected through the Service, such as by court or a governmental agency. We may
              also disclose personal data to investigate any violation or potential violation of the law,
              this Privacy Policy, our Terms of Use or any other terms of use related to your use of the Service,
              or to protect or defend the rights and property of TCG Fish.
            </li>
            <li>
              <span className="bold">Facilitating Requests.</span> We share information at your request or direction.
            </li>
            <li>
              <span className="bold">Consent.</span> We share information with notice to you and your consent.
            </li>
          </ul>
          <p>
            Notwithstanding the above, we may share information that does not identify you (including
            information that has been aggregated and/or de-identified) except as prohibited by applicable law.
            For information on your rights and choices regarding how we share information about you,
            please see the section entitled
            <a className="text-white" href="#section-your-rights-and-choices">“Your Rights and Choices”</a> below.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          5. COOKIES
        </h3>
        <div>
          <p>
            <span className="bold">What are cookies?</span> A cookie is a small file containing a string
            of characters that is sent to your computer when you visit a website or use an online service.
            When you visit the website or use the service again, the cookie allows that website or online
            service to recognize your browser or device. Cookies may store unique identifiers, user preferences
            and other information.
          </p>
          <p>
            <span className="bold">What is the duration of cookies?</span> We may use “session cookies” or
            “persistent cookies.” Session cookies are temporary and expire once you close your browser or
            once your session ends. Persistent cookies remain on your device for much longer or until you or
            your browser erase them. Persistent cookies have varying durations that are dependent on their
            expiration date.
          </p>
          <p>
            <span className="bold">What types of cookies do we use?</span> TCG Fish uses the following
            cookies with its Service:
          </p>
          <ul>
            <li>
              <span className="underline">Necessary Cookies.</span> These cookies are necessary for you
              to browse the Service and use its features, such as accessing secure areas of the Service.
              Without these cookies, certain aspects of the Service may not be available to you.
            </li>
            <li>
              <span className="underline">Preferences Cookies.</span> These cookies collect information
              about how you have used the Service in the past and allow the Service to remember the
              choices you have made. These cookies allow us to improve how the Service works for you and
              tailor the Service to your preferences.
            </li>
            <li>
              <span className="underline">Statistics Cookies.</span> These cookies collect information about
              how you use the Service, such as which pages you most often visit on the Service, the time you
              spend on the Service, which links you click on, and any issues encountered. These cookies help
              us understand how visitors interact with the Service. All information collected is aggregated
              and do not identify you.
            </li>
            <li>
              <span className="underline">Marketing Cookies.</span> These cookies track your online activity
              to help deliver advertisements that are relevant to you and your interests and measure the
              effectiveness of the advertising campaigns. They also limit how many times you see a certain
              advertisement. We may share the information collected through these cookies with others, such as
              advertisers.
            </li>
          </ul>
          <p>
            <span className="bold">Why do we use cookies?</span> Cookies help us improve the Service by
            providing us with information about which parts of the Service are most popular, enabling us
            to analyze technical and navigational information about the Service, and helping us to detect
            and prevent fraud. We also use cookies and other data collection tools (such as web beacons
            and server logs) to help improve your experience with the Service. For example, we use analytic
            service providers to help analyze how users use the Service. These tools use cookies to collect
            standard Internet log information and visitor behavior information in an anonymous form
            The information generated by the cookie about your use of the Service (including IP address)
            is transmitted to our data collection tool service providers. This information is then used by
            us to evaluate visitors’ use of the Service and to compile statistical reports on website
            activity for TCG Fish.
          </p>
          <p>
            For more information with respect to your rights and choices related to cookies, please see
            the section entitled
            <a className="text-white" href="#cookies-and-other-tracking-technologies">
              “Your Rights and Choices: Cookies and other Tracking Technologies”
            </a> below.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          6. ANALYTICS AND ADVERTISING
        </h3>
        <div>
          <p>
            We use analytics services, such as Google Analytics, to help us understand how users access and
            use the Service, as well as help us understand market trends and popularity of our products and
            services. As part of this process, we may incorporate tracking technologies into our own Service
            (including our website and emails).
          </p>
          <p>
            As part of this process, we may incorporate tracking technologies into our own Service
            (including our website and emails) as well as into our ads displayed on other websites and services.
            Some of these tracking technologies may track your activities across time and services for purposes
            of associating the different devices you use, and delivering relevant ads and/or other content to
            you (<span className="bold">“Interest-based Advertising”</span>).
          </p>
          <p>
            We also use audience matching services to reach people (or people similar to people) who have
            visited our Service or are identified in one or more of our databases
            (<span className="bold">“Matched Ads”</span>). This is done by us uploading a customer list to
            a technology service or incorporating a pixel from a technology service into our own Service,
            and the technology service matching common factors between our data and their data. For instance,
            we may incorporate the Facebook pixel on our Service and may share your email address with Facebook
            as part of our use of Facebook Custom Audiences. Some technology services, such as LiveRamp,
            may provide us with their own data, which is then uploaded into another technology service
            for matching common factors between those datasets. We encourage you to familiarize yourself with and
            consult their privacy policies and terms of use.
          </p>
          <p>
            For further information on the types of tracking technologies we use on the Service and your
            rights and choices regarding analytics, please see the “Information Collected Automatically”
            and “Your Rights and Choices” sections.
          </p>
        </div>
      </section>
      <section id="section-your-rights-and-choices">
        <h3 className="section__title">
          7. YOUR RIGHTS AND CHOICES
        </h3>
        <h4 className="section__subtitle">
          A. <span className="underline">Accounts</span>
        </h4>
        <div>
          <p>
            You may access, update, or remove certain information that you have provided to us through
            your account by visiting such account’s settings or sending an email to the email address
            set out in the <a className="text-white" href="#section-contact-us">“Contact Us”</a> section below.
            We may require additional information from you to allow us to confirm your identity.
            Please note that we will retain and use information about you as necessary to comply with
            our legal obligations, resolve disputes, and enforce our agreements.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          B. <span className="underline">Communications</span>
        </h4>
        <div>
          <p>
            You can opt-out of receiving promotional emails from us at any time by following the removal
            instructions in the specific communication that you receive, or emailing us at the email address
            provided the section entitled <a className="text-white" href="#section-contact-us">“Contact Us”</a> below with the word
            UNSUBSCRIBE in the subject field of the email. Note that your opt-out is limited
            to the email address used.
          </p>
        </div>
      </section>
      <section id="cookies-and-other-tracking-technologies">
        <h4 className="section__subtitle">
          C. <span className="underline">Cookies and other Tracking Technologies</span>
        </h4>
        <div>
          <p>
            Consider the following with respect to cookies and other tracking technologies:
          </p>
          <ul>
            <li>
              <span className="bold">Cookies.</span> Most browsers accept cookies by default.
              By changing your browser settings, you can instruct your browser to decline or
              delete cookies. If you use multiple browsers on your device, you will need to instruct
              each browser separately. Your ability to limit cookies is subject to your browser
              settings and limitations.
            </li>
            <li>
              <span className="bold">Do Not Track.</span> Your browser settings may allow you to automatically
              transmit a “Do Not Track” signal to online services you visit. Note, however, there is no
              industry consensus as to what site and app operators should do with regard to these signals.
              Accordingly, unless and until the law is interpreted to require us to do so, we do not monitor
              or take action with respect to “Do Not Track” signals. For more information on
              “Do Not Track,” visit <a className="text-white" href="https://www.allaboutdnt.com">https://www.allaboutdnt.com.</a>
            </li>
          </ul>
          <p>
            Please be aware that if you disable or remove tracking technologies, some parts
            of the Service may not function correctly.
          </p>
        </div>
      </section>
      <section>
        <h4 className="section__subtitle">
          D. <span className="underline">Analytics and Advertising</span>
        </h4>
        <div>
          <p>
            Google provides tools to allow you to opt out of the use of certain information collected
            by Google Analytics at https://tools.google.com/dlpage/gaoptout and by Google Analytics
            for Display Advertising or the Google Display Network at
            <a className="text-white" href="https://www.google.com/settings/ads/onweb/">https://www.google.com/settings/ads/onweb/</a>.
          </p>
          <p>
            The companies we work with to provide you with targeted ads are required by us to give you
            the choice to opt out of receiving targeted ads. Most of these companies are participants
            of the Digital Advertising Alliance (“DAA”) and/or the Network Advertising Initiative (“NAI”).
            To learn more about the targeted ads provided by these companies, and how to opt out of
            receiving certain targeted ads from them, please visit: (i) for website targeted ads from DAA
            participants, https://www.aboutads.info/choices; (ii) for app targeted ads from DAA participants,
            <a className="text-white" href="https://www.aboutads.info/appchoices">https://www.aboutads.info/appchoices;</a>
            and (iii) for targeted ads from NAI participants,
            <a className="text-white" href="https://www.networkadvertising.org/choices/">https://www.networkadvertising.org/choices/.</a>
            Opting out only means that the selected participants should no longer deliver certain targeted
            ads to you, but does not mean you will no longer receive any targeted content and/or ads
            (e.g., in connection with the participants’ other customers or from other technology services).
          </p>
          <p>
            To opt out of us using your data for Matched Ads, please contact us as set forth in the “Contact Us”
            section below and specify that you wish to opt out of matched ads. We will request that
            the applicable technology service not serve you Matched Ads based on information we provide to it.
            Alternatively, you may directly contact the applicable technology service to opt out.
          </p>
          <p>
            Please note that if you opt out using any of these methods, the opt out will only apply to
            the specific browser or device from which you opt out. We are not responsible for
            the effectiveness of, or compliance with, any opt out options or programs, or the accuracy
            of any other entities’ statements regarding their opt out options or programs.
          </p>
        </div>
      </section>
      <section>
        <h4 id="section-business-partners" className="section__subtitle">
          E. <span className="underline">Business Partners and Third-Party Services</span>
        </h4>
        <div>
          <p>
            Google provides tools to allow you to opt out of the use of certain information collected
            by Google Analytics at https://tools.google.com/dlpage/gaoptout and by Google Analytics
            for Display Advertising or the Google Display Network at
            <a className="text-white" href="https://www.google.com/settings/ads/onweb/">https://www.google.com/settings/ads/onweb/.</a>

          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          8. DATA SECURITY
        </h3>
        <div>
          <p>
            TCG Fish uses reasonable security measures designed to prevent unauthorized intrusion to
            the Service and the alteration, acquisition or misuse of personal data. Nevertheless,
            transmission via the Internet is not completely secure and we cannot guarantee the security
            of information about you, collected from you, or submitted by you.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          9. CHILDREN’S PRIVACY
        </h3>
        <div>
          <p>
            The Service is not directed towards, nor was it designed to attract the attention of any children,
            and we do not knowingly collect or maintain personal data from any person under the age of thirteen.
          </p>
        </div>
      </section>
      <section>
        <h3 className="section__title">
          10. CHANGES TO THIS PRIVACY POLICY
        </h3>
        <div>
          <p>
            We reserve the right to revise and reissue this Privacy Policy at any time.
            Any changes will be effective immediately upon posting of the revised Privacy Policy.
            Your continued use of our Service indicates your consent to the Privacy Policy then posted.
            If the changes are material, we may provide you additional notice to your email address,
            by informing you about such changes through our website and/or via other channels we may use
            to communicate with you.
          </p>
        </div>
      </section>
      <section id="section-contact-us">
        <h3 className="section__title">
          11. CONTACT US
        </h3>
        <div>
          <p>
            Please contact us at<a className="text-white" href="mailto:support@tcgfish.com">support@tcgfish.com</a>
            if you have any questions or concerns about this Privacy Policy.
          </p>
        </div>
      </section>
    </div>
  );
}
