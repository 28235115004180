import React from 'react';

export const SmallTextButton = ({ text, onClick, Icon, className }) => {
  return (
    <div className={`inline-flex flex-shrink-0 h-9 py-3 pl-2 pr-3 justify-center items-center gap-1 rounded-lg text-link-purple hover:bg-not-tonight-100 ${className ? className : ''}`}
         role="button"
         onClick={onClick}
    >
      {Icon &&
        <Icon className="w-5 h-5 text-link-purple" />
      }
      { text }
    </div>
  );
};
