import React from 'react';

import { FeatureCard } from './FeatureCard';

export const HomeFeaturesSection = () => {
  return (
    <div className="flex flex-col items-center w-full h-106 flex-shrink-0 mb-26">
      <h2 className="w-186 text-not-tonight-1000 text-center h3 mt-0 mb-10 mx-auto">
        With us you can&nbsp;
        <span className="home_features_section-header-effortless text-purple-650 bg-clip-text text-transparent">
          effortless
        </span>
        &nbsp;dive deep into the cards world
      </h2>
      <div className="flex flex-shrink-0 gap-6">
        <FeatureCard title="Explore huge collection of card"
                     text="Discover the most comprehensive sales database for graded Pokémon cards."
                     link="/search"
        />
        <FeatureCard title="Research the market insights and discover new trends"
                     text="Benefit from unique professional analysis to gain invaluable market insights."
                     link="/market"
        />
        <FeatureCard title="Track the best offers with Undervalue Bot"
                     text="Utilize powerful AI tools to pinpoint the best deals in real-time."
                     link="/undervalue-bot"
        />
        <FeatureCard title="Monitor Pokemon TCG market auction"
                     text="Stay informed with real-time market analysis to grasp evolving market trends for collectors."
                     link="/auction-monitor"
        />
      </div>
    </div>
  );
};
